import React from 'react';
import { FooterItem } from 'components/MainFooter/components/types';
import { Box, Collapse, Group, Heading, useBreakpoint, Text } from '@landr/maestro';
import { PrismicButtonLink } from 'components/PrismicButtonLink';

type MainFooterItemListProps = Pick<MainFooterItemsProps, 'items'>;

const MainFooterItemList: React.FC<MainFooterItemListProps> = ({ items }) => {
    return (
        <Group direction="vertical">
            {items &&
                items.map(
                    (item: FooterItem, index) =>
                        item.link && (
                            <Group.Item key={index}>
                                <PrismicButtonLink
                                    label={item.label}
                                    link={item.link}
                                    kind="link"
                                    variant="ghost"
                                    size="sm"
                                />
                            </Group.Item>
                        ),
                )}
        </Group>
    );
};

export type MainFooterItemsProps = {
    title?: string;
    items?: FooterItem[];
};

export const MainFooterItems: React.FC<MainFooterItemsProps> = ({ title, items, children }) => {
    const isTablet = useBreakpoint('lg');

    if (isTablet) {
        return (
            <Collapse>
                <Collapse.Title>
                    <Text fontWeight="bold">{title}</Text>
                </Collapse.Title>
                <Collapse.Content>
                    <MainFooterItemList items={items} />
                    {children}
                </Collapse.Content>
            </Collapse>
        );
    }

    return (
        <Box>
            <Heading as="h3">{title}</Heading>
            <MainFooterItemList items={items} />
            {children}
        </Box>
    );
};
