import React, { useEffect } from 'react';
import { useAuthentication } from 'components/Authentication';
import { sendAnalyticsIdentify } from '../../analytics/analytics.helpers';

export const AnalyticsIdentityController: React.FC = ({ children }) => {
    const { isAuthenticated, authService } = useAuthentication();

    useEffect(() => {
        if (isAuthenticated && authService) {
            sendAnalyticsIdentify(authService);
        }
    }, [isAuthenticated, authService]);

    return <>{children}</>;
};
