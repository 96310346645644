import React, { FormEvent, useState } from 'react';
import styled from 'styled-components';
import { Input, Button, FormControl, CheckIcon, IconButton, useIsDestroyed } from '@landr/maestro';
import { fontWeight } from '@landr/maestro-legacy';
import { prismicText } from 'helpers';
import { PrismicRichTextFieldType } from '../../../../../Slices/types';
import { useCustomerIoForm } from './useCustomerIoForm';

export interface NewsletterFormProps {
    description: PrismicRichTextFieldType | null;
    placeholder: string;
    cta: string;
}

const StyledLabel = styled(FormControl.Label)`
    font-weight: ${fontWeight('regular')};
`;

export const NewsletterForm: React.FC<NewsletterFormProps> = ({ placeholder, cta, description }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const isDestroyed = useIsDestroyed();

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        // fake a delay so it looks like we call the server
        setTimeout(() => {
            if (!isDestroyed.current) {
                setIsSubmitted(true);
            }
        }, 600);
    };
    useCustomerIoForm();

    return (
        <form onSubmit={handleSubmit}>
            <FormControl>
                {description && (
                    <StyledLabel htmlFor="footerNewsletterSubscribe">{prismicText(description)}</StyledLabel>
                )}
                <Input
                    id="footerNewsletterSubscribe"
                    name="email"
                    type="email"
                    placeholder={placeholder}
                    required
                    autoComplete="email"
                    addonRight={
                        isSubmitted ? (
                            <IconButton variant="secondary" icon={CheckIcon} label="done" hideTooltip />
                        ) : (
                            <Button variant="secondary" type="submit">
                                {cta}
                            </Button>
                        )
                    }
                />
            </FormControl>
        </form>
    );
};
