import React, { useRef, useState } from 'react';
import { CommonNavigation, GoToApp } from 'components/CommonNavigation';
import { useBreakpoint } from '@landr/maestro';
import { NavigationItems } from './components';
import { UniversalNavigationProps } from './types';
import { StyledDiv, StyledList } from './styled';

export const UniversalNavigation: React.FC<UniversalNavigationProps> = ({
    partnerLogo,
    partnerUrl,
    headerType,
    universalNavigationData,
    universalNavDropdownDocumentsData,
    pageContext,
    lang,
}) => {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const isTablet = useBreakpoint('lg');

    const listRef = useRef<HTMLUListElement>(null);
    const buttonListRef = useRef<HTMLLIElement>(null);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { go_to_app_label } = universalNavigationData;

    const menuItems = universalNavigationData.body;

    const toggleMobileMenu = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const shouldListItemShrink = lang === 'de-de';

    return (
        <CommonNavigation
            isMobileNavOpen={isMobileNavOpen}
            headerType={headerType}
            languageCode={pageContext.lang}
            partnerUrl={partnerUrl}
            partnerLogo={partnerLogo}
            toggleMobileMenu={toggleMobileMenu}
        >
            <StyledDiv isTablet={isTablet}>
                <StyledList ref={listRef} className="MainNavigation-items" onClick={toggleMobileMenu}>
                    {headerType !== 'PartnerHeader' && (
                        <>
                            <NavigationItems
                                menuItems={menuItems}
                                universalNavDropdownDocumentsData={universalNavDropdownDocumentsData}
                                shouldListItemShrink={shouldListItemShrink}
                                onClick={toggleMobileMenu}
                            />
                            <li className="MainNavigation-item MainNavigation-item--secondary">
                                <span className="Sign-up--mobileBtn">
                                    <GoToApp goToAppLabel={go_to_app_label} />
                                </span>
                            </li>
                        </>
                    )}
                    <li
                        ref={buttonListRef}
                        className={`MainNavigation-item MainNavigation-item--secondary Sign-up ${
                            headerType === 'PartnerHeader' ? 'SignUpPartner' : ''
                        }`}
                    >
                        <span ref={buttonListRef} className="u-hidden-lg-down">
                            <GoToApp goToAppLabel={go_to_app_label} />
                        </span>
                    </li>
                </StyledList>
            </StyledDiv>
        </CommonNavigation>
    );
};
