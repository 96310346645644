import React from 'react';
import { Carousel } from 'components/UI/Carousel';
import { Container } from 'components/UI/Container/Container';
import { Grid, GridCol, GridRow } from 'components/UI/Grid';
import { BlogLink, BlogPost, BlogTitle } from './components';
import { BlogProps } from './types';
import './Blog.scss';

export const Blog: React.FC<BlogProps> = ({ slice, sliceContext }) => {
    const { primary, fields } = slice;

    const theme = primary.blog_theme === 'light' ? 'light' : 'dark';
    return (
        <div className="Blog" data-theme={theme}>
            <Container className="Blog-container" size="md">
                <Grid>
                    <GridRow horizontalAlign="h-start" verticalAlign="v-end" className="Top-elements">
                        <GridCol colSize="6" mobileColSize="12" offsetSize="0">
                            <BlogTitle title={primary.blog_title} />
                        </GridCol>
                        <GridCol colSize="6" mobileColSize="12" offsetSize="0">
                            {primary.blog_link_url && primary.blog_link_text && (
                                <BlogLink
                                    blogUrl={primary.blog_link_url}
                                    sliceContext={sliceContext}
                                    linkText={primary.blog_link_text}
                                />
                            )}
                        </GridCol>
                    </GridRow>
                    <GridRow horizontalAlign="h-start" verticalAlign="v-stretch">
                        {fields.length && (
                            <>
                                {/* Mobile Carousel View */}
                                <Carousel className="Blog-posts  Blog-posts--mobile" theme={theme}>
                                    {fields.map((item, index) => (
                                        <BlogPost key={index} item={item} sliceContext={sliceContext} />
                                    ))}
                                </Carousel>
                                {/* Desktop Grid View */}
                                <div className="Blog-posts--desktop">
                                    {fields.map((item, index) => (
                                        <GridCol
                                            colSize="4"
                                            tabletColSize="6"
                                            mobileColSize="12"
                                            offsetSize="0"
                                            className="BlogPost-cell"
                                            key={index}
                                        >
                                            {<BlogPost item={item} sliceContext={sliceContext} />}
                                        </GridCol>
                                    ))}
                                </div>
                            </>
                        )}
                    </GridRow>
                </Grid>
            </Container>
        </div>
    );
};
