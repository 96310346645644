import React from 'react';
import { prismicText } from 'helpers';
import { PrismicFluidImage } from 'components/Prismic';
import { Container, SizeEnum } from '../../UI/Container/Container';
import { VisualImages } from './types';

const FullScreen: React.StatelessComponent<VisualImages> = ({ images, paddingSize, paddingBottomOverride }) => {
    if (!images[0]) {
        return null;
    }
    return (
        <Container
            size={!paddingSize ? 'md' : (paddingSize as SizeEnum)}
            paddingBottomOverride={paddingBottomOverride as SizeEnum}
            className="Visual-fullScreen"
        >
            <PrismicFluidImage
                image={images[0].visual_imageSharp}
                fallback={images[0].visual_image}
                alt={prismicText(images[0].visual_image_atl)}
            />
        </Container>
    );
};
export default FullScreen;
