import React from 'react';
import { useAuthentication } from 'components/Authentication';
import { AppContextType } from 'context/AppContext';
import { SupportedPrismicSlicesEnum } from '../enums';
import { BooleanOptionType } from '../types';

export type SignupSlice = {
    type: SupportedPrismicSlicesEnum.signup;
    primary: {
        show_login_first: BooleanOptionType;
    };
};

type SignupProps = {
    appContext: AppContextType;
    slice: SignupSlice;
};

const Signup: React.FC<SignupProps> = ({ slice }) => {
    const { loginUrl, signupUrl } = useAuthentication();

    if (typeof window === 'undefined' || !loginUrl) {
        return null;
    }

    const isSignup =
        slice.primary.show_login_first === 'No' ||
        slice.primary.show_login_first === ('false' as any) ||
        !slice.primary.show_login_first;

    window.location.replace(isSignup ? signupUrl : loginUrl);
    return null;
};

export { Signup };
