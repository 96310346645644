import React from 'react';
import Boxed from './Boxed';
import FullScreen from './FullScreen';
import TwoImages from './TwoImages';
import { ImageTypes, VisualProps, VisualImages } from './types';
import './Visual.scss';

const imageTypesMapping = {
    [ImageTypes.fullscreen]: FullScreen,
    [ImageTypes.boxed]: Boxed,
    [ImageTypes.two_images]: TwoImages,
};

const Visual = ({ slice }: VisualProps) => {
    const VisualComponent: React.StatelessComponent<VisualImages> = imageTypesMapping[slice.primary.visual_image_type];

    return (
        <VisualComponent
            images={slice.fields}
            paddingSize={slice.primary.v_paddingtop}
            paddingBottomOverride={slice.primary.v_paddingbottom}
        />
    );
};

export { Visual };
