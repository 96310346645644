import { graphql } from 'gatsby';

export const UniversalNavigationReferralsBarFragment = graphql`
    fragment UniversalNavigationReferralsBarFragment on PRISMIC_Universal_navigation {
        __typename
        referral_label_desktop
        referral_label_mobile
        banners {
            referral_label_desktop
            referral_label_mobile
            referral_utm_campaign
        }
    }
`;
