import React from 'react';
import { Grid, GridRow, GridCol } from 'components/UI/Grid';
import { PrismicHTML, PrismicFluidImage } from 'components/Prismic';
import './CallToAction.scss';
import { AppContextType } from 'context/AppContext';
import { PrismicButtonLink } from 'components/PrismicButtonLink';
import { SupportedPrismicSlicesEnum } from '../enums';
import { Container, SizeEnum } from '../../UI/Container/Container';
import {
    PrismicRichTextFieldType,
    PrismicKeyTextFieldType,
    PrismicTitleFieldType,
    PrismicLinkFieldType,
    SliceContext,
    PrismicImageField,
    PrismicFluidImageField,
} from '../types';

type CallToActionSlice = {
    type: SupportedPrismicSlicesEnum.cta;
    primary: {
        cta_paddingtop: SizeEnum | null;
        cta_paddingbottom: SizeEnum | null;
        call_to_action_theme: 'light' | 'dark';
        call_to_action_title?: PrismicTitleFieldType;
        call_to_action_description?: PrismicRichTextFieldType;
        call_to_action_label: PrismicKeyTextFieldType;
        call_to_action_link: PrismicLinkFieldType;
        call_to_action_background_image: PrismicImageField;
        call_to_action_background_imageSharp: PrismicFluidImageField;
    };
};

export type CallToActionProps = {
    slice: CallToActionSlice;
    sliceContext: SliceContext;
    appContext: AppContextType;
};

export const CallToAction: React.FC<CallToActionProps> = ({ slice, sliceContext }) => {
    const { primary } = slice;
    const paddingSize = slice.primary.cta_paddingtop;
    const paddingBottomOverride = slice.primary.cta_paddingbottom;

    return (
        <Container
            className="CallToAction-container"
            size={!paddingSize ? 'md' : (paddingSize as SizeEnum)}
            paddingBottomOverride={paddingBottomOverride as SizeEnum}
        >
            <div className="CallToAction" data-theme={primary.call_to_action_theme}>
                <Grid>
                    <GridRow horizontalAlign={'h-center'} verticalAlign={'v-center'}>
                        <GridCol colSize={`12`} offsetSize={`0`} mobileColSize={'12'}>
                            {(primary.call_to_action_title || primary.call_to_action_description) && (
                                <div className="CallToAction-content">
                                    <PrismicHTML
                                        content={primary.call_to_action_title}
                                        className="CallToAction-title"
                                    />
                                    <PrismicHTML
                                        content={primary.call_to_action_description}
                                        className="CallToAction-description"
                                    />
                                </div>
                            )}
                            {primary.call_to_action_link && (
                                <PrismicButtonLink
                                    link={primary.call_to_action_link}
                                    slice={sliceContext}
                                    size="lg"
                                    isRounded
                                >
                                    {primary.call_to_action_label}
                                </PrismicButtonLink>
                            )}
                        </GridCol>
                    </GridRow>
                </Grid>
                <PrismicFluidImage
                    className="CallToAction-background"
                    image={primary.call_to_action_background_imageSharp}
                    fallback={primary.call_to_action_background_image}
                />
            </div>
        </Container>
    );
};
