import { AlternateLanguage } from 'types';
import { FixedObject, FluidObject } from 'gatsby-image';
import { PageType } from 'templates/types';
import { SupportedPrismicSlicesEnum, PrismicLinkTypes } from './enums';

export type Slice = {
    type: SupportedPrismicSlicesEnum;
};

export type SliceContext = {
    position: number;
    type: SupportedPrismicSlicesEnum;
    featureFlagVariation?: string;
};

export type PrismicMeta = {
    id: string;
    alternateLanguages: AlternateLanguage[];
    firstPublicationDate: string | null;
    lang: string;
    lastPublicationDate: string | null;
    type: string;
    uid: string;
};

export type PrismicTitleFieldType = string | Record<string, unknown> | [Record<string, unknown>] | null;

export type PrismicRichTextFieldType = string | Record<string, unknown> | [Record<string, unknown>] | null;

export type PrismicLinkFieldType = PrismicInternalLink | PrismicExternalLink | null;

export type ThemeOptionType = 'Light' | 'Dark';

export type BooleanOptionType = 'Yes' | 'No';

export type PrismicKeyTextFieldType = string | null;

export type PrismicDateTextFieldType = string | null;

export type PrismicImageField = {
    url: string;
    alt: string | null;
    dimensions?: {
        width: number;
        height: number;
    };
} | null;

export type PrismicFixedImageField = {
    childImageSharp: {
        fixed: FixedObject;
    };
} | null;

export type PrismicFluidImageField = {
    childImageSharp: {
        fluid: FluidObject;
    };
} | null;

export type PrismicInternalLink = {
    _linkType: PrismicLinkTypes.internal;
    _meta: PrismicInternalLinkMeta;
    page_type: PageType;
    queryParams?: { [key: string]: string };
};

export type PrismicInternalLinkMeta = {
    uid: string;
    lang: string;
};

export type PrismicExternalLink = {
    _linkType: PrismicLinkTypes.external;
    url: string;
    target?: string;
};

export type CtaKind = 'link' | 'button' | 'iconButtonApple' | 'iconButtonWindows' | 'iconButtonAndroid' | 'default';

export type CtaVariant = 'primary' | 'ghost';

export enum VideoApiEnum {
    Wistia = 'Wistia',
    Youtube = 'Youtube',
}

export type VideoSizeEnum = 'fullscreen' | 'bordered';
