import { graphql } from 'gatsby';

export const QuotesVideoSliceFragment = graphql`
    fragment PrismicQuotesVideoFragment on PRISMIC_PageBodyQuotes__video {
        type
        primary {
            quotes_video_background_image
            quotes_video_background_imageSharp {
                ...FluidImageFragment
            }
            quotes_video_quote_text
            quotes_video_author_name
            quotes_video_author_role
            video_api
            quotes_video_wistia_video_id
            quotes_video_play_button_text
        }
    }
`;
