import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { navigate } from 'gatsby-link';
import { PrismicInternalLink } from 'components/Slices/types';
import { SegmentService } from 'analytics';
import { PrismicLinkProps } from 'components/PrismicLink';
import { linkResolver } from 'helpers';

type MaestroComponentBaseProps<T extends Record<string, unknown>> = T &
    React.PropsWithChildren<{
        as?: React.ElementType;
        to: string;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
    }>;

export interface InternalLinkProps<T extends Record<string, unknown>> extends PrismicLinkProps {
    link: PrismicInternalLink;
    maestroComponent?: React.FC<MaestroComponentBaseProps<T>>;
}

export const InternalLink = <T extends Record<string, unknown>>({
    slice,
    label,
    link,
    className,
    children,
    useMaestro,
    maestroComponent,
    ...rest
}: React.PropsWithChildren<InternalLinkProps<T>> & T): JSX.Element => {
    /**
     * Redirects the user to the target href and adds any existing query parameters.
     * @param {React.MouseEvent<HTMLAnchorElement>} event : the click event
     */
    const followLink = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();

        const url = event.currentTarget.getAttribute('href') || '';

        SegmentService.trackLinkFollowed({ 'L - Destination Link': url }, slice);
        navigate(url);
    };

    const url = linkResolver(link);

    if (useMaestro && maestroComponent) {
        // any is necessary because of this error https://stackoverflow.com/questions/56505560/how-to-fix-ts2322-could-be-instantiated-with-a-different-subtype-of-constraint
        const MaestroComponent: React.FC<any> = maestroComponent;
        return (
            <MaestroComponent as={GatsbyLink} to={url} onClick={followLink} {...rest}>
                {label || children}
            </MaestroComponent>
        );
    } else {
        return (
            <GatsbyLink to={url} className={className} activeClassName="is-active" partiallyActive onClick={followLink}>
                {label || children}
            </GatsbyLink>
        );
    }
};
