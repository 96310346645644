import React from 'react';
import { Container } from 'components/UI/Container/Container';
import { Grid, GridRow, GridCol } from 'components/UI/Grid';
import { VideoReactPlayer } from 'components/UI/VideoReactPlayer/VideoReactPlayer';
import './VideoPlayer.scss';
import { VideoApiEnum, VideoSizeEnum } from 'components/Slices/types';
import { SupportedPrismicSlicesEnum } from '../enums';

type VideoPlayerProps = {
    slice: {
        type: SupportedPrismicSlicesEnum.videoplayer;
        primary: {
            video_id: string;
            size: VideoSizeEnum;
            video_api: VideoApiEnum;
        };
    };
};

enum VideoplayerSizeToContainerSizeMapping {
    fullscreen = 'full',
    bordered = 'xs',
}

class VideoPlayer extends React.Component<VideoPlayerProps> {
    render() {
        return (
            <Container size={VideoplayerSizeToContainerSizeMapping[this.props.slice.primary.size]}>
                <Grid>
                    <GridRow horizontalAlign={'h-center'} verticalAlign={'v-center'}>
                        <GridCol colSize={`12`} className="Video-container">
                            <VideoReactPlayer
                                videoUrl={
                                    this.props.slice.primary.video_api === VideoApiEnum.Wistia
                                        ? `https://home.wistia.com/medias/${this.props.slice.primary.video_id}`
                                        : `https://www.youtube.com/embed/${this.props.slice.primary.video_id}`
                                }
                                hasControls={true}
                                isLooping={true}
                                isMuted={false}
                                isAutoPlaying={false}
                            />
                        </GridCol>
                    </GridRow>
                </Grid>
            </Container>
        );
    }
}

export { VideoPlayer, VideoPlayerProps };
