import React from 'react';
import { PrismicExternalLink } from 'components/Slices/types';
import { SegmentService } from 'analytics';
import { PrismicLinkProps } from 'components/PrismicLink';

type MaestroComponentBaseProps<T extends Record<string, unknown>> = T &
    React.PropsWithChildren<{
        as?: React.ElementType;
        to: string;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
    }>;

export interface ExternalLinkProps<T extends Record<string, unknown>> extends PrismicLinkProps {
    link: PrismicExternalLink;
    maestroComponent?: React.FC<MaestroComponentBaseProps<T>>;
}

export const ExternalLink = <T extends Record<string, unknown>>({
    label,
    link,
    className,
    children,
    slice,
    useMaestro,
    maestroComponent,
    ...rest
}: React.PropsWithChildren<ExternalLinkProps<T>> & T): JSX.Element => {
    /**
     * Sends the "Link Followed" event to Segment
     * @param {React.MouseEvent<HTMLAnchorElement>} event : the click event
     */
    const followLink = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        const url = event.currentTarget.getAttribute('href') || '';
        const { target } = event.currentTarget;

        if (!url) {
            return;
        }

        if (target && target === '_blank') {
            SegmentService.trackLinkFollowed({ 'L - Destination Link': url }, slice);
        } else {
            event.preventDefault();
            SegmentService.trackLinkFollowed({ 'L - Destination Link': url }, slice);

            setTimeout(() => {
                window.top && window.top.location.assign(url);
            }, 250);
        }
    };

    // fallback to _self because prismic return _blank or null
    const linkTarget = link.target || '_self';

    const linkProps =
        link.url && link.url.includes('#')
            ? // Link to an anchor
              {
                  href: link.url,
                  rel: 'noopener',
              }
            : {
                  href: link.url,
                  rel: 'noopener',
                  target: linkTarget,
                  onClick: followLink,
              };

    if (useMaestro && maestroComponent) {
        // any is necessary because of this error https://stackoverflow.com/questions/56505560/how-to-fix-ts2322-could-be-instantiated-with-a-different-subtype-of-constraint
        const MaestroComponent: React.FC<any> = maestroComponent;
        return (
            <MaestroComponent as="a" {...linkProps} {...rest}>
                {label || children}
            </MaestroComponent>
        );
    } else {
        return (
            <a className={className} {...linkProps}>
                {label || children}
            </a>
        );
    }
};
