import React from 'react';
import { prismicText } from 'helpers';
import { PrismicHTML, PrismicFluidImage } from 'components/Prismic';
import { Container } from '../../UI/Container/Container';
import { Grid, GridRow, GridCol } from '../../UI/Grid';
import { VisualImages } from './types';

const TwoImages: React.StatelessComponent<VisualImages> = ({ images }) => (
    <Container size={'md'} className="Visual-twoImages">
        <Grid>
            <GridRow horizontalAlign={'h-start'} verticalAlign={'v-center'}>
                {(images[0].visual_imageSharp || images[0].visual_image) && (
                    <GridCol colSize={`6`} mobileColSize={`12`}>
                        <PrismicFluidImage
                            image={images[0].visual_imageSharp}
                            fallback={images[0].visual_image}
                            alt={prismicText(images[0].visual_image_atl)}
                        />
                        <PrismicHTML
                            content={images[0].visual_image_description}
                            className="Visual-twoImagesDescription"
                        />
                    </GridCol>
                )}
                {(images[1].visual_image || images[1].visual_imageSharp) && (
                    <GridCol colSize={`6`} mobileColSize={`12`}>
                        <PrismicFluidImage
                            image={images[1].visual_imageSharp}
                            fallback={images[1].visual_image}
                            alt={prismicText(images[1].visual_image_atl)}
                        />
                        <PrismicHTML
                            content={images[1].visual_image_description}
                            className="Visual-twoImagesDescription"
                        />
                    </GridCol>
                )}
            </GridRow>
        </Grid>
    </Container>
);

export default TwoImages;
