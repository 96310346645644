import { navigate } from 'gatsby-link';
import { saveUTMsToCookies } from '@landr/core';
import React from 'react';
import PageTemplate from 'templates/Page';
import PageNotFoundTemplate from 'templates/PageNotFound';
import { UTMCookieSettings } from '../constants';
import { PageContextType, SupportedLanguages } from '../types';
import {
    isHomePage as isHomePageHelper,
    getTwoLetterLanguageCode,
    isPreviewActive,
    getPrismicLanguageCode,
} from '../helpers';
import { PageDataType } from './types';
import { AllProviders } from './withLayout';
import { PageNotFoundDataType } from './types';

function getAlternatePage(page: PageDataType, abbreviatedBrowserLanguage: any) {
    const alternatePage = page._meta.alternateLanguages.find((translatedPage) => {
        return translatedPage.lang.substring(0, 2) === abbreviatedBrowserLanguage;
    });

    return alternatePage ? alternatePage.uid : null;
}

function redirectToPageLanguage({
    prismicPage,
    twoLetterLanguageCode,
    isHomePage,
}: {
    prismicPage?: PageDataType | null;
    twoLetterLanguageCode: string;
    isHomePage: boolean;
}) {
    let pageSlug: string | null = '';
    const query = window.location.search;

    if (prismicPage && !isHomePage) {
        pageSlug = getAlternatePage(prismicPage, twoLetterLanguageCode);

        // Fallback to english if no alternate pageSlug found
        if (pageSlug === null) {
            pageSlug = prismicPage._meta.uid;
            twoLetterLanguageCode = SupportedLanguages.en;
        }
    }

    const relativeURL = `/${twoLetterLanguageCode}/${pageSlug}${query}`;

    navigate(relativeURL);
}

const isUnpublishedPagedWithShortLang = (location: Location) => {
    if (location.pathname !== '/unpublished/') {
        return false;
    }

    const searchParams = new URLSearchParams(location.search);
    const langParam = searchParams.get('lang');

    return langParam && langParam.length === 2;
};

const redirectToUnpublishedPage = (location: Location) => {
    const searchParams = new URLSearchParams(location.search);
    const langParam = searchParams.get('lang');

    searchParams.set('lang', getPrismicLanguageCode(langParam));

    navigate(`/unpublished/?${searchParams.toString()}`);
};

type Props = {
    location: any;
    pageContext: PageContextType;
    data?: {
        prismic: {
            page?: PageDataType;
            not_found_page?: PageNotFoundDataType;
        };
    };
    children: any;
};

const App: React.FC<Props> = ({ children, location, data, pageContext }) => {
    const PageComponent =
        (data && pageContext.type === 'page') || location.pathname === '/unpublished/'
            ? PageTemplate
            : PageNotFoundTemplate;
    /**
     * Need to save the UTMs (and partner params like Impact) to cookies on initial app load in order to properly
     * attribute campaigns to sign ups
     */
    if (typeof window !== 'undefined') {
        saveUTMsToCookies(UTMCookieSettings);
    }

    const browserLanguage = (typeof navigator !== 'undefined' && navigator.language) || 'en-US';
    const twoLetterLanguageCode = getTwoLetterLanguageCode(browserLanguage);
    const prismicPage = (data && data.prismic && data.prismic.page) || null;
    const isHomePage = (prismicPage && isHomePageHelper(prismicPage._meta.uid)) || false;
    const pathsToBeConsideredForRedirect = ['/', '/login/', '/signup/', '/password/reset/'];

    /**
     * Redirect non-english users (based on browser language) to a translated page for special pages
     * listed in pathsToBeConsideredForRedirect
     */
    if (pathsToBeConsideredForRedirect.includes(location.pathname) && twoLetterLanguageCode !== 'en') {
        redirectToPageLanguage({ prismicPage, twoLetterLanguageCode, isHomePage });
        return null;
    }

    /**
     * By default gatsby-source-prismic-graphql will redirect with query param `lang` being the short lang (2 chars) instead of 5 char.
     * So this is a hack until they fix it.
     * example: `lang=fr` becomes `lang=fr-fr`
     */
    if (isUnpublishedPagedWithShortLang(location)) {
        redirectToUnpublishedPage(location);
    }

    return (
        <AllProviders page={prismicPage} pageContext={pageContext} location={location}>
            {/*
            PageComponent is similar to 'children' but it's unmounted at every page change. This make the UI flicker so we keep it mounted
            However, for the preview, we must use the dynamic component injected through children.
            */}
            {isPreviewActive() ? children : <PageComponent location={location} data={data} pageContext={pageContext} />}
        </AllProviders>
    );
};

export default App;
