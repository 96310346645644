/**
 * !!!DO NOT EDIT THIS FILE!!!
 *
 * The following code is fully generated from the definitions provided in the Analytics AirTable definition.
 * You can regenerate it with "yarn generate-segment"
 */
// @ts-ignore: no-unused-parameters
import { SliceContext } from '../components/Slices/types';
import { AbstractSegmentService, TransformMethods } from './segment-abstract';

interface AudioPlayerLaunchedParams {
    'L - Destination Link': string;
    'L - Asset Genre': string;
}

interface AudioPlayerToggledParams {
    'L - Asset Version': string;
}

interface ClickedCtaParams {
    'L - User Tenant'?: string;
    'L - Cta Goal': string;
    'L - Cta Context': string;
    'L - Release Id'?: string;
    'L - Release Kind'?: string;
    'L - Release Name'?: string;
    'L - Release Primary Artist'?: string;
    'L - Link'?: string;
    'L - Link Type'?: string;
    'L - Dsp Name'?: string;
    'L - Cta Target': string;
    'L - Slice Id'?: string;
    'L - User Interaction Source'?: string;
}

interface LinkFollowedParams {
    'L - Destination Link': string;
}

interface VideoPlayedParams {
    'L - Video Name': string;
}

export class SegmentService extends AbstractSegmentService {
    /**
     * When visitors & users are listening to the difference between master and original and launched the player.
     */
    trackAudioPlayerLaunched(params: AudioPlayerLaunchedParams, sliceContext?: SliceContext): Promise<void> {
        return this.track<AudioPlayerLaunchedParams>(
            'Audio Player Launched',
            'Guest Site',
            null,
            null,
            params,
            sliceContext,
        );
    }
    trackAudioPlayerToggled(params: AudioPlayerToggledParams, sliceContext?: SliceContext): Promise<void> {
        return this.track<AudioPlayerToggledParams>(
            'Audio Player Toggled',
            'Guest Site',
            null,
            null,
            params,
            sliceContext,
        );
    }
    trackCarouselSlideChanged(sliceContext: SliceContext = {} as SliceContext): Promise<void> {
        return this.track<any>('Carousel Slide Changed', 'Guest Site', null, null, {}, sliceContext);
    }
    /**
     * When users are clicking on one of the Call To Action.
     */
    trackClickedCta(params: ClickedCtaParams, sliceContext?: SliceContext): Promise<void> {
        params['L - Cta Goal'] = this.transform(TransformMethods.UPPERCASE, params['L - Cta Goal']);
        params['L - Cta Context'] = this.transform(TransformMethods.UPPERCASE, params['L - Cta Context']);
        params['L - Cta Target'] = this.transform(TransformMethods.UPPERCASE, params['L - Cta Target']);
        return this.track<ClickedCtaParams>('Clicked CTA', 'Guest Site', null, null, params, sliceContext);
    }
    /**
     * When visitors & users are clicking on one of the Call to Action on the slide.
     */
    trackLinkFollowed(params: LinkFollowedParams, sliceContext?: SliceContext): Promise<void> {
        return this.track<LinkFollowedParams>(
            'Link Followed',
            'Guest Site',
            'L - Destination Link',
            null,
            params,
            sliceContext,
        );
    }
    /**
     * When visitors & users are scrolling on a Guest Site page and they are seeing a slide.
     */
    trackSliceViewed(sliceContext: SliceContext = {} as SliceContext): Promise<void> {
        return this.track<any>('Slice Viewed', 'Guest Site', 'L - Slice Type', null, {}, sliceContext);
    }
    /**
     * When visitors & users are launching a video on the web site
     */
    trackVideoPlayed(params: VideoPlayedParams, sliceContext?: SliceContext): Promise<void> {
        return this.track<VideoPlayedParams>(
            'Video Played',
            'Guest Site',
            'L - Video Name',
            null,
            params,
            sliceContext,
        );
    }
}
