import { graphql } from 'gatsby';

export const BlogSliceFragment = graphql`
    fragment PrismicBlogFragment on PRISMIC_PageBodyBlog {
        type
        primary {
            blog_title
            blog_link_text
            blog_link_url {
                ...LinkFragment
            }
            blog_theme
        }
        fields {
            blog_post_title
            blog_post_image
            blog_post_imageSharp {
                childImageSharp {
                    fixed(width: 368, height: 200) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            blog_post_alt_text
            blog_post_category
            blog_post_date
            blog_post_description
            blog_post_link {
                ...LinkFragment
            }
        }
    }
`;
