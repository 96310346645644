import { graphql } from 'gatsby';

export const PressListSliceFragment = graphql`
    fragment PrismicPressListFragment on PRISMIC_PageBodyPress_list {
        type
        primary {
            press_list_number_of_columns
        }
        fields {
            press_list_image
            press_list_title
            press_list_content
            press_list_link {
                ...LinkFragment
            }
        }
    }
`;
