import React from 'react';
import { classNames } from 'react-extras';

export enum HeadingElement {
    heading1 = 'h1',
    heading2 = 'h2',
    heading3 = 'h3',
    heading4 = 'h4',
    heading5 = 'h5',
    heading6 = 'h6',
}

type HeadingSizes = 'mg' | 'jb' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';

export type HeadingType = keyof typeof HeadingElement | null;

type HeadingProps = {
    children: React.ReactNode;
    element?: HeadingType;
    size?: HeadingSizes | null;
    className?: string;
};

const Heading = (props: HeadingProps) => {
    const { element, size, className } = props;

    const Element: HeadingElement =
        element && element in HeadingElement ? HeadingElement[element] : HeadingElement.heading2;

    const HeadingClasses = classNames('Heading', {
        [`Heading--${size}`]: size,
        'Heading--md': !size,
        [`${className}`]: className,
    });

    return <Element className={HeadingClasses}>{props.children}</Element>;
};

export { Heading, HeadingProps, HeadingSizes };
