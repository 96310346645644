import { SegmentLoader } from '@landr/core';
import { PageDataType } from 'templates/types';
import { SliceContext } from 'components/Slices/types';
import { getEnglishUID } from '../helpers/getEnglishUID';

export const APPLICATION_NAME = 'GUEST SITE';

export interface SegmentWindow extends Window {
    analytics?: any;
}

export enum TransformMethods {
    JOIN,
    UPPERCASE,
    LOWERCASE,
}

type CommonParameters = {
    'L - Application Name': string;
};

type CommonPageParameters = {
    'L - Page': string;
    'L - Page Language': string;
    'L - Count of Slices': number;
};

export class AbstractSegmentService {
    public commonPageParams: CommonPageParameters = {} as CommonPageParameters;
    private window: SegmentWindow | undefined;
    private disabled: boolean = process.env.SEGMENT_DISABLED === 'true';
    private commonParams: CommonParameters = {
        'L - Application Name': APPLICATION_NAME,
    };
    private segmentLoader: SegmentLoader;

    constructor() {
        this.segmentLoader = new SegmentLoader(typeof window !== 'undefined' ? window : undefined);

        if (typeof window !== 'undefined') {
            this.window = window;
        }
    }

    /**
     * This method is based on the Segment snippet (and converted to Typescript).
     * It asynchronously loads Segment, and creates a queue used until Segment is ready.
     */
    load(): void {
        if (this.disabled) {
            return;
        }

        this.segmentLoader.load(process.env.GATSBY_SEGMENT_WRITE_KEY as string);
    }

    reset(): void {
        if (this.disabled) {
            return;
        }

        if (typeof this.window !== 'undefined') {
            this.window.analytics.reset();
        }
    }

    page(page: PageDataType): void {
        if (this.disabled) {
            return;
        }
        const body = (<PageDataType>page).body || [];
        const pageName = getEnglishUID(page._meta);
        this.commonPageParams = {
            'L - Page': pageName,
            'L - Page Language': page._meta.lang.substring(0, 2),
            'L - Count of Slices': body.length,
        };

        // Do not call window.analytics.page, analyticsV2 will take care of it
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    track<T extends object>(
        name: string,
        category: string,
        gaLabelProperty: keyof T | null,
        gaValueProperty: keyof T | null,
        params: T,
        sliceContext: SliceContext = {} as SliceContext,
    ): Promise<void> {
        return new Promise((resolve) => {
            if (this.disabled) {
                resolve();
                return;
            }

            if (typeof this.window !== 'undefined') {
                const gaLabel = gaLabelProperty ? params[gaLabelProperty] : undefined;
                const gaValue = gaValueProperty ? params[gaValueProperty] : undefined;
                let sliceType: string = sliceContext.type;

                if (sliceContext.featureFlagVariation) {
                    sliceType = `${sliceContext.type}_${sliceContext.featureFlagVariation}`;
                }

                this.window.analytics.track(
                    name,
                    {
                        ...this.commonParams,
                        ...this.commonPageParams,
                        ...(params as Record<string, unknown>),
                        'Event Category': category,
                        'L - Slice Type': sliceType,
                        'L - Slice Position': sliceContext.position,
                        label: gaLabel,
                        value: gaValue,
                    },
                    {},
                    resolve,
                );
            }
        });
    }

    transform(method: TransformMethods, value: string | string[]): any {
        let result = value;

        switch (method) {
            case TransformMethods.JOIN:
                result = Array.isArray(result) ? result.join(', ') : result;
                break;
            case TransformMethods.LOWERCASE:
                result = Array.isArray(result) ? result.map((r) => r.toLowerCase()) : result.toLowerCase();
                break;
            case TransformMethods.UPPERCASE:
                result = Array.isArray(result) ? result.map((r) => r.toUpperCase()) : result.toUpperCase();
                break;
            default:
                break;
        }

        return result;
    }
}
