import { AuthService } from '@landr/auth';
import { analyticsV2 } from 'analytics';
import { EventId } from 'enum/eventId';
import { log } from '../log';

export async function sendAnalyticsIdentify(auth: AuthService) {
    let analyticsProperties = null;

    const user = await auth.getUser();
    if (user) {
        try {
            analyticsProperties = await auth.getAnalyticsProperties();
        } catch (e) {
            log.error('Error getting analytics properties', EventId.AnalyticsPropertiesError, e);
        }

        await analyticsV2.identify(user.userId, analyticsProperties);
    }
}
