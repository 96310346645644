import React from 'react';
import { PrismicImageField } from 'components/Slices/types';

interface PrismicExactImageProps {
    alt?: string;
    className?: string;
    image: PrismicImageField;
    width?: number | string;
    height?: number | string;
}

export const PrismicExactImage: React.FC<PrismicExactImageProps> = ({ alt, className, image, width, height }) => {
    if (!image || !image.url) {
        return null;
    }

    return (
        <img alt={alt || image.alt || undefined} className={className} src={image.url} width={width} height={height} />
    );
};
