import React from 'react';
import { Grid, GridRow } from 'components/UI/Grid';
import './CookieConsent.scss';
import { PrismicRichTextFieldType, PrismicMeta } from 'components/Slices/types';
import { PrismicHTML } from 'components/Prismic';

export type CookieConsentData = {
    _meta: PrismicMeta;
    cookie_consent_description: PrismicRichTextFieldType;
    cookie_consent_agree_text: string;
};

export type CookieConsentProps = {
    data: CookieConsentData;
    onAgreeToCookieConsent: () => void;
};

export const CookieConsent = (props: CookieConsentProps) => {
    const { data, onAgreeToCookieConsent } = props;
    return (
        <Grid className="CookieConsent-container">
            <GridRow horizontalAlign={'h-center'} verticalAlign={'v-stretch'}>
                <div className="CookieConsent-content">
                    <span className="CookieConsent-text">
                        <PrismicHTML content={data.cookie_consent_description} />
                    </span>
                    <span className="CookieConsent-seperator" />
                    <button className="CookieConsent-agreeText" onClick={onAgreeToCookieConsent}>
                        {data.cookie_consent_agree_text}
                    </button>
                </div>
            </GridRow>
        </Grid>
    );
};
