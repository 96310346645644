import React from 'react';
import { PrismicHTML } from 'components/Prismic';
import { Arrow } from '../../Icons';
import { Item } from './types';

const Style = ({ style, handleStyle, isActiveStyle }: { style: Item; handleStyle: any; isActiveStyle: boolean }) => (
    <div className={`MasteringDemos-style ${isActiveStyle ? 'Active' : ''}`} onClick={handleStyle}>
        <PrismicHTML content={style.mastering_demos_style} className="MasteringDemos-style-name u-text-xl" />
        <span className="MasteringDemos-style-arrow">
            <Arrow size="small" />
        </span>
        <div className="Wrapper-track-name">
            <PrismicHTML content={style.mastering_demos_track_title} className="MasteringDemos-style-title u-text-xl" />
        </div>
    </div>
);

export { Style };
