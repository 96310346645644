import React from 'react';
import { PrismicHTML } from 'components/Prismic';
import './TextBlock.scss';
import { Grid, GridRow } from 'components/UI/Grid';
import { Container, SizeEnum } from 'components/UI/Container/Container';
import { PrismicRichTextFieldType } from '../types';
import { SupportedPrismicSlicesEnum } from '../enums';

type TextBlockProps = {
    slice: {
        type: SupportedPrismicSlicesEnum.textBlock;
        primary: {
            tb_paddingtop: SizeEnum | null;
            tb_paddingbottom: SizeEnum | null;
            text_block_content: PrismicRichTextFieldType;
        };
    };
};

const TextBlock = ({ slice }: TextBlockProps) => {
    const paddingSize = slice.primary.tb_paddingtop;
    const paddingBottomOverride = slice.primary.tb_paddingbottom;

    return (
        <Container
            className="TextBlock-container"
            size={!paddingSize ? 'md' : (paddingSize as SizeEnum)}
            paddingBottomOverride={paddingBottomOverride as SizeEnum}
        >
            <Grid>
                <GridRow horizontalAlign={'h-center'} verticalAlign={'v-center'}>
                    <PrismicHTML content={slice.primary.text_block_content} />
                </GridRow>
            </Grid>
        </Container>
    );
};

export { TextBlock, TextBlockProps };
