import { AppleFilled, WindowsFilled, AndroidFilled, IconProps, ButtonVariant, ButtonKind } from '@landr/maestro';
import { Link } from 'gatsby';
import { CtaKind } from 'components/Slices/types';
import { PrismicLinkTypes } from 'components/Slices/enums';
import { PrismicLinkFieldType, PrismicExternalLink, PrismicInternalLink } from 'components/Slices/types';
import { linkResolver } from 'helpers';
import { MaestroComponent } from '@landr/maestro-legacy';
import { LinkProps } from './PrismicButton.types';

export const isAnInternalLink = (link: PrismicLinkFieldType): boolean => {
    return !!link && link._linkType === PrismicLinkTypes.internal;
};

// Note: We should align ctaKind with maestro ButtonKind
// and add new field for icon instead
export const getIconFromCtaKind = (kind?: CtaKind): MaestroComponent<IconProps, Record<string, any>> | undefined => {
    switch (kind) {
        case 'iconButtonApple':
            return AppleFilled;
        case 'iconButtonWindows':
            return WindowsFilled;
        case 'iconButtonAndroid':
            return AndroidFilled;
    }

    return undefined;
};

export const getTarget = (link: PrismicLinkFieldType): string => {
    if (!link) {
        return '_self';
    }

    const externalLinkTarget = (!isAnInternalLink(link) && (link as PrismicExternalLink).target) || '_self';

    return isAnInternalLink(link) ? '_self' : externalLinkTarget;
};

export const getIsSolidButtonKind = (ctaKind?: CtaKind): boolean => {
    const isSolidButtonKind =
        ctaKind === 'button' ||
        ctaKind === 'iconButtonApple' ||
        ctaKind === 'iconButtonWindows' ||
        ctaKind === 'iconButtonAndroid';

    return isSolidButtonKind;
};

export const getVariant = (ctaKind?: CtaKind, ctaVariant?: ButtonVariant): ButtonVariant => {
    const defaultVariant = getIsSolidButtonKind(ctaKind) ? 'primary' : 'secondary';

    return ctaVariant ? ctaVariant : defaultVariant;
};

export const getKind = (ctaKind?: CtaKind): ButtonKind => {
    return getIsSolidButtonKind(ctaKind) ? 'solid' : 'link';
};

export const getLinkProps = (isInternalLink: boolean, url: string, target: string): LinkProps => {
    const externalLinkProps = { forwaredAs: 'a', href: url, target };
    const internalLinkProps = { forwaredAs: Link, to: url };

    return isInternalLink ? internalLinkProps : externalLinkProps;
};

export const getUrl = (link: PrismicLinkFieldType): string => {
    if (!link) {
        return '';
    }

    return (link as PrismicExternalLink).url
        ? (link as PrismicExternalLink).url
        : linkResolver(link as PrismicInternalLink);
};
