import { PrismicLinkFieldType } from 'components/Slices/types';
import { PrismicLinkTypes } from 'components/Slices/enums';
import { linkResolver, addTrailingSlash } from 'helpers';
import { SupportedLanguages } from '../types';
import { getTwoLetterLanguageCode, isHomePage } from './index';

const isEnglishPage = (pathname: string) => {
    return pathname.startsWith(`/${SupportedLanguages.en}/`);
};

const getCanonicalFromPathname = (pathname: string) => {
    const pathnameWithoutEnglish = isEnglishPage(pathname) ? pathname.slice(3) : pathname;

    return addTrailingSlash(`${process.env.GATSBY_GUESTSITE_ENDPOINT}${pathnameWithoutEnglish}`);
};

export const getMetaCanonicalLink = (metaCanonical: PrismicLinkFieldType): string | null => {
    if (!metaCanonical) {
        return null;
    }

    return metaCanonical._linkType === PrismicLinkTypes.external
        ? metaCanonical.url
        : getCanonicalFromPathname(linkResolver(metaCanonical));
};

export const getCanonicalLink = (location: Location, metaCanonical: PrismicLinkFieldType = null): string =>
    getMetaCanonicalLink(metaCanonical) ?? getCanonicalFromPathname(location.pathname);

export const getAlternateLink = ({ language, uid }: { language: string; uid: string }): string => {
    const languageAbbreviation = getTwoLetterLanguageCode(language);
    let result = '/';

    if (languageAbbreviation !== SupportedLanguages.en) {
        result += `${languageAbbreviation}/`;
    }

    if (!isHomePage(uid)) {
        result += `${uid}/`;
    }

    return result;
};
