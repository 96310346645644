import React, { useState } from 'react';
import { Box, CaretDownOutlined, Collapse, Nav, Text } from '@landr/maestro';
import { PrismicLinkFieldType } from 'components/Slices/types';
import { CollapseIconBox, StyledCollapse, StyledLeftBorder, StyledPrismicLink } from './styled';
import { BadgeTitle } from './BadgeTitle';

interface NavCollapseHeadProps {
    title: string;
}

const NavCollapseHead: React.FC<NavCollapseHeadProps> = ({ children, title }) => {
    const [isOpened, setIsOpened] = useState(false);

    const handleOnClick = (): void => {
        setIsOpened(!isOpened);
    };

    const handleStopPropagation = (event: React.SyntheticEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <Nav.Item onClick={handleStopPropagation}>
            <StyledCollapse>
                <Collapse.Trigger />
                <Collapse.Title
                    // Issue: Property 'onClick' does not exist on type
                    // https://github.com/LandrAudio/LANDR.Maestro/issues/973
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onClick={handleOnClick}
                >
                    <Box display="flex" alignItems="center">
                        <Box py="xs">
                            <Text size="lg" fontWeight="bold" color="neutral.0">
                                {title}
                            </Text>
                        </Box>
                        <Box display="flex" ml="md">
                            <CollapseIconBox isOpened={isOpened} height="24px">
                                <CaretDownOutlined color="neutral.400" />
                            </CollapseIconBox>
                        </Box>
                    </Box>
                </Collapse.Title>
                <Collapse.Content p="xs">
                    <StyledLeftBorder>{children}</StyledLeftBorder>
                </Collapse.Content>
            </StyledCollapse>
        </Nav.Item>
    );
};

interface ItemProps {
    link: PrismicLinkFieldType;
    text: string;
    onClick: () => void;
    badgeTitle?: string;
}

const Item: React.FC<ItemProps> = ({ link, text, onClick, badgeTitle }) => {
    return (
        <StyledPrismicLink link={link}>
            <Box display="flex" alignItems="center" mb="sm" onClick={onClick}>
                <Text size="lg" fontWeight="bold">
                    {text}
                </Text>
                <BadgeTitle content={badgeTitle} />
            </Box>
        </StyledPrismicLink>
    );
};

export const NavCollapse = {
    Head: NavCollapseHead,
    Item: Item,
};
