import { graphql } from 'gatsby';

export const LinkListSliceFragment = graphql`
    fragment PrismicLinkListFragment on PRISMIC_PageBodyLinklist {
        type
        fields {
            link_title
            link_description
            link_url {
                ...LinkFragment
            }
        }
    }
`;
