import React from 'react';
import styled from 'styled-components';
import { Box, Button, Divider, Heading } from '@landr/maestro';
import { color } from '@landr/maestro-legacy';
import { Grid, GridCol, GridRow } from '../../UI/Grid';
import { Container } from '../../UI/Container/Container';
import { PrismicLink } from '../../PrismicLink';
import { CartItemProps, CartProps, SummaryColumnProps } from '../Cart/types';
import { PrismicFluidImage, PrismicHTML, PrismicText } from '../../Prismic';
import './Cart.scss';

const StyledGridCol = styled(GridCol)`
    background-color: ${({ theme }) => (theme.mode === 'dark' ? color('neutral.900') : color('neutral.200'))};
`;

const CartItem: React.FC<CartItemProps> = ({ image, imageSharp, title, price, description }) => {
    return (
        <GridRow horizontalAlign="h-start" className="Cart Row">
            <StyledGridCol mobileColSize="12" className="Cart Image">
                <PrismicFluidImage fallback={image} image={imageSharp} />
            </StyledGridCol>
            <GridCol mobileColSize="12">
                <PrismicHTML content={title} />
                <PrismicText content={price} />
                <Box mt="md">
                    <PrismicHTML shouldShowListIcon={true} content={description} />
                </Box>
            </GridCol>
        </GridRow>
    );
};

const SummaryColumn: React.FC<SummaryColumnProps> = ({ checkoutLink, cartPrice }) => {
    return (
        <GridCol>
            <GridRow horizontalAlign="h-between" className="Cart Row">
                <GridCol>Subtotal</GridCol>
                <GridCol>
                    <PrismicText content={cartPrice} />
                </GridCol>
            </GridRow>
            <GridRow horizontalAlign="h-between" className="Cart Row">
                <GridCol>Tax</GridCol>
                <GridCol>Calculated at checkout</GridCol>
            </GridRow>
            <Divider color="white" />
            <GridRow horizontalAlign="h-between" className="Cart Row">
                <GridCol>Total</GridCol>
                <GridCol>
                    <PrismicText content={cartPrice} />
                </GridCol>
            </GridRow>
            <Divider />
            <Button pt="xs" mt="md" isFull={true}>
                <PrismicLink link={checkoutLink} className="Cart-cta-link">
                    <div className="Grid Grid--alignCenter">
                        <p>Checkout</p>
                    </div>
                </PrismicLink>
            </Button>
        </GridCol>
    );
};

const StyledContainer = styled(Container)`
    color: ${({ theme }) => (theme.mode === 'dark' ? color('neutral.100') : color('neutral.1000'))};
`;

export const Cart: React.FC<CartProps> = ({ slice: { primary } }) => {
    const { cart_price, cart_title, cart_checkout_link, cart_image, cart_description, cart_imageSharp } = primary;

    return (
        <StyledContainer className="Cart-container" size="md">
            <Grid>
                <GridRow horizontalAlign="h-start">
                    <GridCol colSize="8" tabletColSize="6" mobileColSize="12">
                        <GridCol>
                            <Heading size="xl">Cart</Heading>
                        </GridCol>
                        <GridCol mobileColSize="12">
                            <CartItem
                                image={cart_image}
                                imageSharp={cart_imageSharp}
                                price={cart_price}
                                title={cart_title}
                                description={cart_description}
                            />
                        </GridCol>
                    </GridCol>
                    <GridCol colSize="4" tabletColSize="6" mobileColSize="12">
                        <GridCol>
                            <Heading size="xl">Summary</Heading>
                        </GridCol>
                        <SummaryColumn checkoutLink={cart_checkout_link} cartPrice={cart_price} />
                    </GridCol>
                </GridRow>
            </Grid>
        </StyledContainer>
    );
};
