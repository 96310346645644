import styled from 'styled-components';
import { Box, Collapse } from '@landr/maestro';
import { palette, spacing } from '@landr/maestro-legacy';
import { PrismicLink } from 'components/PrismicLink';

export const StyledCollapse = styled(Collapse)`
    background-color: transparent;
    border: none;
    > div {
        padding: 0;
        min-height: unset;
    }
`;

StyledCollapse.displayName = 'StyledCollapse';

export const StyledLeftBorder = styled(Box)`
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: ${palette('neutral.800')};
    padding-left: ${spacing('xs')};
`;

StyledLeftBorder.displayName = 'StyledLeftBorder';

export const CollapseIconBox = styled(Box)<{ isOpened: boolean }>`
    color: ${palette('neutral.400')};
    transform: ${({ isOpened }): string => (isOpened ? `rotate(-180deg)` : 'rotate(0)')};
    transition: transform 0.25s ease-out;
`;

CollapseIconBox.displayName = 'CollapseIconBox';

export const StyledPrismicLink = styled(PrismicLink)`
    text-decoration: none;
`;

StyledPrismicLink.displayName = 'StyledPrismicLink';
