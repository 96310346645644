import { Observable, Subscriber } from 'rxjs';
import { EventId } from '../enum/eventId';
import { log as AppLogger } from '../log';

const log = AppLogger.getInstance('Geolocation');

export const getUserCountry = (): Observable<string> => {
    const geolocationRoute = `${process.env.GATSBY_KUBERNETES_ENDPOINT}/geolocation/api/v1/geolocation`;

    return Observable.create((observer: Subscriber<string>) => {
        fetch(geolocationRoute)
            .then(response => response.json())
            .then(({ data }) => {
                observer.next(data.countryCodeIso2Letters);
                observer.complete();
            })
            // Handling the error if no geolocation was found for the user
            .catch(error => {
                log.info('Fetching geolocation failed', EventId.FetchGeolocationFailed, error);
                // Pass undefined to the observer
                observer.next(undefined);
                observer.complete();
            });
    });
};

export const getGDPRStatus = (): Observable<boolean> => {
    const geolocationRoute = `${process.env.GATSBY_KUBERNETES_ENDPOINT}/geolocation/api/v1/geolocation`;

    return Observable.create((observer: Subscriber<string>) => {
        fetch(geolocationRoute)
            .then(response => response.json())
            .then(({ data }) => {
                observer.next(data.isGdpr);
                observer.complete();
            })
            .catch(error => {
                log.info('Fetching geolocation failed', EventId.FetchGeolocationFailed, error);
                observer.next(undefined);
                observer.complete();
            });
    });
};
