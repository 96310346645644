import { graphql } from 'gatsby';

export const CallToActionSliceFragment = graphql`
    fragment PrismicCallToActionFragment on PRISMIC_PageBodyCta {
        type
        primary {
            cta_paddingtop
            cta_paddingbottom
            call_to_action_theme
            call_to_action_title
            call_to_action_description
            call_to_action_label
            call_to_action_link {
                ...LinkFragment
            }
            call_to_action_background_image
            call_to_action_background_imageSharp {
                ...FluidImageFragment
            }
        }
    }
`;
