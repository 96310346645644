import React from 'react';
import { graphql } from 'gatsby';
import { PageContextType } from '../types';
import { PageMetadata } from '../components/PageMetadata';
import { Slices, SliceFragments } from '../components/Slices';
import { withLayoutWhenPreviewIsActive } from './withLayout';
import { LinkFragments, MetaFragment } from './fragments.gql';
import { PageDataType } from './types';
import { getUnpublishedPageData } from './pageData';

export type PageTemplateProps = {
    location: Location;
    pageContext: PageContextType;
    data?: {
        prismic: {
            page?: PageDataType;
        };
    };
};

const PageTemplateContent: React.FC<PageTemplateProps> = ({ data, pageContext, location }) => {
    const page = (data && data.prismic.page) || getUnpublishedPageData(location);

    return (
        <>
            <PageMetadata page={page} pageContext={pageContext} location={location} />
            <Slices location={location} pageContext={pageContext} slices={page.body} />
        </>
    );
};

export const PageTemplate = withLayoutWhenPreviewIsActive(PageTemplateContent);

// NOTE: This variable must ALWAYS remain named `query` or Prismic previews will break
export const query = graphql`
    query($uid: String!, $lang: String!) {
        prismic {
            page(uid: $uid, lang: $lang) {
                ...MetaFragment
                title
                meta_title
                meta_description
                meta_canonical {
                    ...LinkFragment
                }
                meta_image
                meta_twitter_image
                body {
                    ...PrismicBlogFragment
                    ...PrismicCallToActionFragment
                    ...PrismicFaqFragment
                    ...PrismicFeaturedArtistsFragment
                    ...PrismicFeatureFragment
                    ...PrismicFeatureVideoFragment
                    ...PrismicCartFragment
                    ...PrismicHeroFragment
                    ...PrismicHeroVideoFragment
                    ...PrismicLinkListFragment
                    ...PrismicLogoListFragment
                    ...PrismicMasteringDemosFragment
                    ...PrismicPressListFragment
                    ...PrismicPricingFragment
                    ...PrismicQuotesFragment
                    ...PrismicQuotesVideoFragment
                    ...PrismicSignupFragment
                    ...PrismicTextBlockFragment
                    ...PrismicTextGridFragment
                    ...PrismicVideoPlayerFragment
                    ...PrismicVisualFragment
                    ...RoyaltyCalculatorFragment
                }
                home_page
                show_navigation_header
                page_type
                header_type
                partner_logo
                partner_url {
                    ...LinkFragment
                }
                show_footer
                theme
            }
        }
    }
`;

// We must manually add `fragments` property to the component for Prismic previews to work
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
PageTemplate.fragments = [LinkFragments, MetaFragment, ...SliceFragments];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
PageTemplate.query = query;

export default PageTemplate;
