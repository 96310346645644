import React from 'react';
import { prismicText } from 'helpers';
import { PrismicHTML, PrismicExactImage } from 'components/Prismic';
import { Container } from '../../UI/Container/Container';
import { Grid, GridRow, GridCol } from '../../UI/Grid';
import { VisualImages } from './types';

const Boxed: React.StatelessComponent<VisualImages> = ({ images }): any =>
    images[0].visual_image && (
        <Container size={'md'} className="Visual-boxedImage">
            <Grid>
                <GridRow horizontalAlign={'h-start'} verticalAlign={'v-center'}>
                    <GridCol colSize={`12`}>
                        <PrismicExactImage
                            alt={prismicText(images[0].visual_image_atl)}
                            image={images[0].visual_image}
                        />
                        <PrismicHTML
                            content={images[0].visual_image_description}
                            className="Visual-boxedImageDescription"
                        />
                    </GridCol>
                </GridRow>
            </Grid>
        </Container>
    );

export default Boxed;
