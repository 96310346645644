import { isHomePage } from 'helpers';

/**
 * Returns a modified version of an internal (relative) link :
 * - Removes any homepage slugs.
 * - Ensures the url starts and ends with a '/'.
 * - Adds any query params after the trailing '/'.
 * @param {string} url : the relative url to sanitize
 */
export const getSanitizedURL = (url: string): string => {
    const splitUrl = url.split('?');
    const path = splitUrl[0].split('/').filter(part => part !== '');
    const params = splitUrl[1];
    // Home pages urls should only include the language
    if (isHomePage(path[1])) {
        path.splice(1);
    }

    let result = `/${path.join('/')}/`;

    if (params) {
        result += `?${params}`;
    }

    return result;
};
