import { PrismicInternalLink } from 'components/Slices/types';
import { PrismicLinkTypes } from 'components/Slices/enums';

export const homepageLink = (languageCode: string): PrismicInternalLink => {
    return {
        _linkType: PrismicLinkTypes.internal,
        _meta: {
            uid: '',
            lang: languageCode,
        },
        page_type: 'Default',
    };
};
