import React from 'react';
import './Container.scss';
import { classNames } from 'react-extras';

export type SizeEnum = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full' | 'none';

const Container = ({
    children,
    size,
    paddingBottomOverride,
    className,
    anchorId,
    ...props
}: {
    children: React.ReactNode;
    size?: SizeEnum;
    paddingBottomOverride?: SizeEnum;
    className?: string;
    anchorId?: string | null;
}) => {
    const containerClasses = classNames('Container', {
        [`Container--${size}`]: size,
    });

    const containerClassesBottomOverride = classNames({
        [`OverrideBottomPadding--${paddingBottomOverride}`]: paddingBottomOverride,
    });

    return (
        <div
            className={`${containerClasses} ${containerClassesBottomOverride} ${className || ''}`}
            id={`${anchorId || ''}`}
            {...props}
        >
            {children}
        </div>
    );
};

export { Container };
