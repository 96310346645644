/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { GiftOutlined, NotificationBar, useBreakpoint } from '@landr/maestro';
import { PrismicHTML } from 'components/Prismic';
import { UniversalNavigationReferralData } from 'components/UniversalNavigation';
import './ReferralsBanners.scss';

export interface ReferralsBannerProps {
    data: UniversalNavigationReferralData;
}

export const ReferralsBanner: React.FC<ReferralsBannerProps> = ({ data }) => {
    const isMobile = useBreakpoint('sm');
    const pageParams = new URLSearchParams(window.location.search);

    const utmCampaign = pageParams.get('utm_campaign');

    if (!data || !data.banners) {
        return null;
    }

    const banner = data.banners.find((banner) => banner.referral_utm_campaign === utmCampaign);

    if (!banner) {
        return null;
    }

    return (
        <NotificationBar variant="informational" icon={GiftOutlined}>
            <PrismicHTML
                className="Referrals-text"
                content={isMobile ? banner.referral_label_mobile : banner.referral_label_desktop}
            />
        </NotificationBar>
    );
};
