import { Languages, PricingTabsEnum } from '@landr/pricing';

export const pricingTabToUid = {
    [PricingTabsEnum.Mastering]: {
        [Languages.English]: 'master',
        [Languages.French]: 'masteriser',
        [Languages.German]: 'mastern',
        [Languages.Italian]: 'masterizza',
        [Languages.Japanese]: 'マスタリンクと',
        [Languages.Portuguese]: 'masterize',
        [Languages.Spanish]: 'masterizacion',
    },
    [PricingTabsEnum.Distribution]: {
        [Languages.English]: 'release',
        [Languages.French]: 'distribution',
        [Languages.German]: 'vertrieb',
        [Languages.Italian]: 'pubblicazioni',
        [Languages.Japanese]: 'リリース',
        [Languages.Portuguese]: 'lancamento',
        [Languages.Spanish]: 'distribucion',
    },
    [PricingTabsEnum.Sessions]: {
        [Languages.English]: 'sessions',
        [Languages.French]: 'sessions',
        [Languages.German]: 'sessions',
        [Languages.Italian]: 'sessions',
        [Languages.Japanese]: 'sessions',
        [Languages.Portuguese]: 'sessions',
        [Languages.Spanish]: 'sessions',
    },
    [PricingTabsEnum.Samples]: {
        [Languages.English]: 'samples-en',
        [Languages.French]: 'samples-fr',
        [Languages.German]: 'samples-de',
        [Languages.Italian]: 'samples-it',
        [Languages.Japanese]: 'samples-jp',
        [Languages.Portuguese]: 'samples-pt',
        [Languages.Spanish]: 'samples-es',
    },
    [PricingTabsEnum.Plugins]: {
        [Languages.English]: 'plugins',
        [Languages.French]: 'plugins',
        [Languages.German]: 'plugins',
        [Languages.Italian]: 'plugin',
        [Languages.Japanese]: 'プラグイン',
        [Languages.Portuguese]: 'plug-ins',
        [Languages.Spanish]: 'plugins',
    },
    [PricingTabsEnum.Bundle]: {
        [Languages.English]: 'studio',
        [Languages.French]: 'studio',
        [Languages.German]: 'studio',
        [Languages.Italian]: 'studio',
        [Languages.Japanese]: 'studio',
        [Languages.Portuguese]: 'studio',
        [Languages.Spanish]: 'studio',
    },
    [PricingTabsEnum.Courses]: {
        [Languages.English]: 'courses',
        [Languages.French]: 'cours',
        [Languages.German]: 'kurse',
        [Languages.Italian]: 'corsi',
        [Languages.Japanese]: 'コース',
        [Languages.Portuguese]: 'cursos',
        [Languages.Spanish]: 'cursos',
    },
};

export const pricingUidToTab = {
    // Mastering
    master: PricingTabsEnum.Mastering,
    masteriser: PricingTabsEnum.Mastering,
    mastern: PricingTabsEnum.Mastering,
    masterizza: PricingTabsEnum.Mastering,
    マスタリンクと: PricingTabsEnum.Mastering,
    masterize: PricingTabsEnum.Mastering,
    masterizacion: PricingTabsEnum.Mastering,
    // Distribution
    release: PricingTabsEnum.Distribution,
    distribution: PricingTabsEnum.Distribution,
    vertrieb: PricingTabsEnum.Distribution,
    pubblicazioni: PricingTabsEnum.Distribution,
    リリース: PricingTabsEnum.Distribution,
    lancamento: PricingTabsEnum.Distribution,
    distribucion: PricingTabsEnum.Distribution,
    // Sessions
    sessions: PricingTabsEnum.Sessions,
    // Samples
    samples: PricingTabsEnum.Samples,
    'samples-de': PricingTabsEnum.Samples,
    'samples-en': PricingTabsEnum.Samples,
    'samples-es': PricingTabsEnum.Samples,
    'samples-fr': PricingTabsEnum.Samples,
    'samples-it': PricingTabsEnum.Samples,
    'samples-jp': PricingTabsEnum.Samples,
    'samples-pt': PricingTabsEnum.Samples,
    // bundle
    studio: PricingTabsEnum.Bundle,
    // Plugins
    chromatic: PricingTabsEnum.Plugins,
    plugins: PricingTabsEnum.Plugins,
    'plug-ins': PricingTabsEnum.Plugins,
    plugin: PricingTabsEnum.Plugins,
    プラグイン: PricingTabsEnum.Plugins,
    // Courses
    education: PricingTabsEnum.Courses,
    courses: PricingTabsEnum.Courses,
    cours: PricingTabsEnum.Courses,
    kurse: PricingTabsEnum.Courses,
    corsi: PricingTabsEnum.Courses,
    コース: PricingTabsEnum.Courses,
    cursos: PricingTabsEnum.Courses,
};
