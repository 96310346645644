import React from 'react';
import styled from 'styled-components';
import { Box, Nav, Paragraph, Text, useBreakpoint } from '@landr/maestro';
import { StyledListItem } from 'components/UniversalNavigation/styled';
import { PrismicInternalLink } from 'components/Slices/types';
import { PricingUris } from 'helpers/pricingLinkUtils';
import { NavigationDropdownItemGroupedData, NavigationDropdownItemProps, DropdownContentProps } from './types';
import { BadgeTitle, StyledPrismicLink, NavCollapse, DynamicIcon } from './components';

const StyledText = styled(Text)`
    text-transform: uppercase;
`;

const DropdownContent: React.FC<DropdownContentProps> = ({ blocks }) => {
    return (
        <>
            {blocks.map((block, index) => {
                const groupLabel =
                    block.type === 'grouped' && (block as NavigationDropdownItemGroupedData).primary.group_label;

                return (
                    <React.Fragment key={index}>
                        {groupLabel && (
                            <Box ml="md" mt={index ? 'md' : 0}>
                                <StyledText contrast="low" size="xs">
                                    {groupLabel}
                                </StyledText>
                            </Box>
                        )}
                        {block.fields.map((field, index) => {
                            if (!field.item_link) {
                                return null;
                            } else {
                                return (
                                    <Nav.DropdownItem as={'div'} key={index}>
                                        <StyledPrismicLink key={index} link={field.item_link}>
                                            <Box display="flex" alignItems="center">
                                                {field.item_icon && (
                                                    <Box mr="md">
                                                        <DynamicIcon iconName={field.item_icon} />
                                                    </Box>
                                                )}
                                                <Box>
                                                    <Box display="flex" alignItems="center">
                                                        <Box>
                                                            <Paragraph m="0">{field.item_label}</Paragraph>
                                                        </Box>
                                                        <BadgeTitle content={field.item_badge} />
                                                    </Box>
                                                    <Paragraph contrast="low" m="0" size="sm">
                                                        {field.item_description}
                                                    </Paragraph>
                                                </Box>
                                            </Box>
                                        </StyledPrismicLink>
                                    </Nav.DropdownItem>
                                );
                            }
                        })}
                    </React.Fragment>
                );
            })}
        </>
    );
};

const StyledSpan = styled.span<{ isActiveDropdown: boolean }>`
    font-weight: 700;
    margin-top: 2px;
    :after {
        content: '';
        display: block;
        width: ${({ isActiveDropdown }) => (isActiveDropdown ? '100%' : 0)};
        height: 2px;
        background: white;
        padding-bottom: 2px;
        margin-top: 1px;
        transition: width 0.2s;
    }
    :hover:after {
        width: 100%;
    }
`;

const StyledPopoverContent = styled(Nav.DropdownContent)`
    max-width: 480px;
`;

export const NavigationDropdownItem: React.FC<NavigationDropdownItemProps> = ({
    data,
    allDropdownDocuments,
    shouldListItemShrink,
    onClick,
}) => {
    const isTablet = useBreakpoint('lg');

    const { id } = data.primary.navigation_dropdown_document._meta;
    const dropdownDocument = allDropdownDocuments.find((document) => document._meta.id === id);

    if (!dropdownDocument) {
        return null;
    }

    const dropdownItemsUids = dropdownDocument.body[0].fields.map(({ item_link }) => {
        // PrismicInternalLink has _meta, but PrismicExternalLink doesn't
        if (item_link && (item_link as PrismicInternalLink)._meta) {
            return (item_link as PrismicInternalLink)._meta.uid;
        } else {
            return null;
        }
    });
    const isActiveDropdown =
        typeof window !== 'undefined' &&
        !!dropdownItemsUids.find((uid) => uid && window.location.pathname.includes(uid)) &&
        !PricingUris.find((uri) => window.location.pathname.includes(uri));

    if (isTablet) {
        return (
            <NavCollapse.Head title={dropdownDocument.menu_label}>
                {dropdownDocument.body.map((block, index) => (
                    <React.Fragment key={index}>
                        {block.fields.map((field, index) => {
                            if (field.item_link && field.item_label) {
                                return (
                                    <NavCollapse.Item
                                        key={index}
                                        link={field.item_link}
                                        text={field.item_label}
                                        badgeTitle={field.item_badge}
                                        onClick={onClick}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </React.Fragment>
                ))}
            </NavCollapse.Head>
        );
    }

    return (
        <StyledListItem shouldShrink={shouldListItemShrink} className="MainNavigation-item">
            <Nav.Dropdown triggerEvent="hover">
                <Nav.DropdownTrigger>
                    <StyledSpan isActiveDropdown={isActiveDropdown}>{dropdownDocument.menu_label}</StyledSpan>
                </Nav.DropdownTrigger>
                <StyledPopoverContent>
                    <DropdownContent blocks={dropdownDocument.body} />
                </StyledPopoverContent>
            </Nav.Dropdown>
        </StyledListItem>
    );
};
