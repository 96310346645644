import { graphql } from 'gatsby';

export const FeatureSliceFragment = graphql`
    fragment PrismicFeatureFragment on PRISMIC_PageBodyFeature {
        type
        primary {
            feature_eyebrow
            feature_title
            feature_position
            feature_description
            feature_cta_label
            feature_cta_kind
            feature_cta_variant
            feature_cta_link {
                ...LinkFragment
            }
            feature_image
            feature_imageSharp {
                ...FluidImageFragment
            }
            feature_image_cta_link {
                ...LinkFragment
            }
        }
    }
`;
