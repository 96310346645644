import { useEffect } from 'react';

/**
 * This script will inject backend logic for customer io. It will scan the form and submit the result to customer io
 * See https://customer.io/docs/connected-forms/
 */
export const useCustomerIoForm = () => {
    useEffect(() => {
        if (!document.getElementById('cio-forms-handler')) {
            const script = document.createElement('script');
            const latestScriptInDocument = document.getElementsByTagName('script')[0];
            script.async = true;
            script.id = 'cio-forms-handler';
            script.setAttribute('data-site-id', process.env.GATSBY_CUSTOMER_IO_SITE_ID as string);
            script.setAttribute('data-base-url', 'https://customerioforms.com');

            script.src = 'https://customerioforms.com/assets/forms.js';
            if (latestScriptInDocument && latestScriptInDocument.parentNode) {
                latestScriptInDocument.parentNode.insertBefore(script, latestScriptInDocument);
            }
        }

        // We don't remove the script on cleanup as it's better to keep it and let it loaded once, especially when the preview is active
    }, []);
};
