import React from 'react';
import Img from 'gatsby-image';
import { PrismicImageField, PrismicFixedImageField } from 'components/Slices/types';
import { PrismicExactImage } from './PrismicExactImage';

interface PrismicFixedImageProps {
    alt?: string;
    className?: string;
    fallback: PrismicImageField;
    image: PrismicFixedImageField;
}

export const PrismicFixedImage: React.FC<PrismicFixedImageProps> = ({ alt, className, fallback, image }) => {
    if (image && image.childImageSharp && image.childImageSharp.fixed) {
        return <Img alt={alt} className={className} fixed={image.childImageSharp.fixed} />;
    }

    if (fallback) {
        return <PrismicExactImage alt={alt} className={className} image={fallback} />;
    }

    return null;
};
