import React from 'react';

export const LrLogo = () => (
    <svg width="75px" height="60px" viewBox="0 0 78 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>LANDR Logo</title>
        <g>
            <g>
                <g>
                    <path
                        fill="#F9FAFB"
                        d="M30.5,61c-16.5,0-30-13.5-30-30S14,1,30.5,1c16.5,0,30,13.5,30,30S47.1,61,30.5,61z M30.5,2.5
               C14.8,2.5,2,15.3,2,31c0,15.7,12.8,28.5,28.5,28.5S59,46.7,59,31C59,15.3,46.3,2.5,30.5,2.5z"
                    />
                </g>
                <g>
                    <path
                        fill="#F9FAFB"
                        d="M45.4,61c-16.5,0-30-13.5-30-30s13.5-30,30-30s30,13.5,30,30S62,61,45.4,61z M45.4,2.5
               C29.7,2.5,16.9,15.3,16.9,31c0,15.7,12.8,28.5,28.5,28.5c15.7,0,28.5-12.8,28.5-28.5C73.9,15.3,61.1,2.5,45.4,2.5z"
                    />
                </g>
            </g>
            <path
                fill="#F9FAFB"
                d="M27.8,35.2L27,33.3h-3.7l-0.8,1.9H21l3.5-8.4h1.1l3.5,8.4H27.8z M26.5,32.1l-1.4-3.3l-1.4,3.3H26.5z"
            />
            <path fill="#F9FAFB" d="M40.6,35.2l-4.1-5.9v5.9h-1.3v-8.4h1.1l4.1,5.9v-5.9h1.3v8.4H40.6z" />
            <path
                fill="#F9FAFB"
                d="M51.6,26.8c2.3,0,4.1,1.5,4.1,4.2c0,2.6-1.8,4.2-4.1,4.2h-3.3v-8.4H51.6z M49.6,34h2c1.6,0,2.8-1,2.8-3
       c0-2-1.1-3-2.7-3h-2V34z"
            />
            <g>
                <path fill="#F9FAFB" d="M12.5,34v1.2H7.2v-8.4h1.3V34H12.5z" />
                <path
                    fill="#F9FAFB"
                    d="M67.2,32h-1.8v3.2h-1.3v-8.4h3.4c1.5,0,2.6,0.9,2.6,2.6c0,1.2-0.6,2-1.4,2.4l1.9,3.4H69L67.2,32z M67.3,30.8
           c0.8,0,1.4-0.5,1.4-1.4c0-0.9-0.6-1.4-1.4-1.4h-1.9v2.8H67.3z"
                />
            </g>
        </g>
    </svg>
);
