import React from 'react';

export const LogoKebab = () => (
    <svg
        width="20px"
        height="13px"
        viewBox="0 0 20 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="LogoKebab"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-282.000000, -25.000000)">
                <g transform="translate(276.000000, 16.000000)">
                    <rect fillOpacity="0" fill="#142739" fillRule="nonzero" x="0" y="0" width="32" height="32" />
                    <g strokeWidth="1" fillRule="evenodd" transform="translate(6.666667, 9.333333)" stroke="#F9FAFB">
                        <polyline
                            strokeWidth="1.5"
                            transform="translate(9.333333, 6.666667) scale(-1, 1) translate(-9.333333, -6.666667) "
                            points="18.6666667 6 14.9333333 6 11.2 6 7.46666667 6 3.73333333 6 0 6"
                        />
                        <polyline
                            strokeWidth="1.5"
                            transform="translate(11.333333, 1.333333) scale(-1, 1) translate(-11.333333, -1.333333) "
                            points="18.6666667 0.666666667 15.7333333 0.666666667 12.8 0.666666667 9.86666667 0.666666667 6.93333333 0.666666667 4 0.666666667"
                        />
                        <polyline
                            strokeWidth="1.5"
                            transform="translate(10.666667, 12.000000) scale(-1, 1) translate(-10.666667, -12.000000) "
                            points="18.6666667 11.3333333 15.4666667 11.3333333 12.2666667 11.3333333 9.06666667 11.3333333 5.86666667 11.3333333 2.66666667 11.3333333"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
