import styled, { css } from 'styled-components';
import { Button as MaestroButton, ButtonKind } from '@landr/maestro';

export const Button = styled(MaestroButton)<{ kind?: ButtonKind }>`
    & span {
        position: relative;
    }

    & span:after {
        ${({ kind }) =>
            kind === 'link' &&
            css`
                position: absolute;
                bottom: -4px;
                left: 0;
                right: 0;
                width: 0;
                content: '';
                background: currentColor;
                height: 2px;
                transition: all 0.2s;
            `}
    }

    &:hover:not(:disabled) {
        text-decoration: none;
    }

    &:hover:not(:disabled) span:after {
        ${({ kind }) =>
            kind === 'link' &&
            css`
                width: 100%;
            `}
    }
`;
