import React from 'react';
import { ArrowForwardOutlined } from '@landr/maestro';
import { Container } from 'components/UI/Container/Container';
import { Grid, GridCol, GridColProps, GridRow } from 'components/UI/Grid';
import { PrismicHTML } from 'components/Prismic';
import { PrismicButton } from 'components/PrismicButton';
import { PrismicText } from 'components/Prismic';
import styled from 'styled-components';
import { Box } from '@landr/maestro';
import { color } from '@landr/maestro-legacy';
import { BaseFeatureSliceProps, Positions, PositionsStyles } from './types';
import './BaseFeatureSlice.scss';

export const positionsMapping: { [index: string]: PositionsStyles } = {
    [Positions.Left]: {
        flexDirection: 'u-flex-row',
        leftColSize: '4',
        leftColOffset: '0',
        rightColSize: '7',
        rightColOffset: '1',
        r_tabletColSize: '6',
    },
    [Positions.Right]: {
        flexDirection: 'u-flex-rowReverse',
        leftColSize: '4',
        leftColOffset: '1',
        rightColSize: '7',
        rightColOffset: '0',
        r_tabletColSize: '6',
    },
    [Positions.Center]: {
        flexDirection: 'u-text-center',
        leftColSize: '6',
        leftColOffset: '3',
        rightColSize: '12',
        rightColOffset: '0',
        r_tabletColSize: '12',
    },
};

const getPositionsForItems = (position: Positions | null): PositionsStyles => {
    if (!position || !(position in Positions)) {
        return positionsMapping[Positions.Left];
    }
    return positionsMapping[position];
};

const StyledContainer = styled(Container)`
    color: ${({ theme }) => (theme.mode === 'dark' ? color('neutral.100') : color('neutral.900'))};
`;

export const BaseFeatureSlice: React.FC<BaseFeatureSliceProps> = ({
    eyeBrow,
    title,
    position,
    description,
    ctaLabel,
    ctaLink,
    ctaKind,
    ctaVariant,
    sliceContext,
    children,
}) => {
    const positions = getPositionsForItems(position);

    const commonGridProps = {
        mobileColSize: '12',
        tabletOffsetSize: '0',
        mobileOffsetSize: '0',
    } as GridColProps;

    const iconRight = ctaKind === 'default' || ctaKind === 'link' ? ArrowForwardOutlined : undefined;

    return (
        <StyledContainer className="BaseFeature-container" size={'md'}>
            <Grid>
                <GridRow horizontalAlign="h-start" verticalAlign="v-center" className={positions.flexDirection}>
                    <GridCol
                        {...commonGridProps}
                        colSize={positions.leftColSize}
                        offsetSize={positions.leftColOffset}
                        tabletColSize={positions.r_tabletColSize}
                        className="BaseFeature Text"
                    >
                        <PrismicHTML
                            content={eyeBrow}
                            className="BaseFeature-eyeBrow"
                            styleClassName="Heading Heading--lg"
                        />
                        <PrismicHTML
                            content={title}
                            className="BaseFeature-title"
                            styleClassName="Heading Heading--xx"
                        />
                        <PrismicHTML
                            shouldShowListIcon={true}
                            content={description}
                            className="BaseFeature-description"
                        />
                        {ctaLink && ctaLabel && ctaKind && ctaVariant && (
                            <PrismicButton
                                ctaKind={ctaKind}
                                link={ctaLink}
                                ctaVariant={ctaKind === 'button' ? ctaVariant : 'secondary'}
                                sliceContext={sliceContext}
                                iconRight={iconRight}
                            >
                                <PrismicText content={ctaLabel} />
                            </PrismicButton>
                        )}
                    </GridCol>
                    <GridCol
                        {...commonGridProps}
                        colSize={positions.rightColSize}
                        offsetSize={positions.rightColOffset}
                        tabletColSize={positions.r_tabletColSize}
                        className="BaseFeature Image"
                    >
                        <Box p="lg">{children}</Box>
                    </GridCol>
                </GridRow>
            </Grid>
        </StyledContainer>
    );
};
