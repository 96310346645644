import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'components/UI/Container/Container';
import { Grid, GridRow, GridCol } from 'components/UI/Grid';
import { PrismicLink } from 'components/PrismicLink';
import { getDataForLang, getLanguageFromLocation, getPrismicLanguageCode } from 'helpers';
import { PageContextType } from '../types';
import { withLayoutWhenPreviewIsActive } from './withLayout';
import { LinkFragments, MetaFragment } from './fragments.gql';
import './PageNotFound.scss';
import { PageNotFoundDataType } from './types';
import { UnpublishedPageNotFoundData } from './pageData';

export type PageNotFoundTemplateProps = {
    location: Location;
    pageContext: PageContextType;
    data?: {
        prismic: {
            allNot_found_pages?: PageNotFoundDataType;
        };
    };
};

const PageNotFoundTemplateContent: React.FC<PageNotFoundTemplateProps> = ({ data, location }) => {
    const lang = getLanguageFromLocation(location);
    const pageData = (data && data.prismic && data.prismic.allNot_found_pages) || UnpublishedPageNotFoundData;
    const page = getDataForLang(pageData, getPrismicLanguageCode(lang));

    return (
        <Container className="PageNotFound" size="lg">
            <Grid>
                <GridRow horizontalAlign="h-center">
                    <GridCol className="PageNotFound-content">
                        <p className="PageNotFound-secondaryText">{page && page.not_found_small_text}</p>
                        <p className="PageNotFound-primaryText">{page && page.not_found_large_text}</p>
                        {page && page.not_found_button_link && (
                            <PrismicLink
                                link={page.not_found_button_link}
                                className="Button Button--primary Button--small"
                            >
                                <div className="Button-wrapper">
                                    <div className="Button-text">{page && page.not_found_button_text}</div>
                                </div>
                            </PrismicLink>
                        )}
                    </GridCol>
                </GridRow>
            </Grid>
        </Container>
    );
};

export const PageNotFoundTemplate = withLayoutWhenPreviewIsActive(PageNotFoundTemplateContent);

// NOTE: This variable must ALWAYS remain named `query` or Prismic previews will break
export const query = graphql`
    query PageNotFound {
        prismic {
            allNot_found_pages {
                edges {
                    node {
                        ...MetaFragment
                        not_found_small_text
                        not_found_large_text
                        not_found_button_text
                        not_found_button_link {
                            ...LinkFragment
                        }
                    }
                }
            }
        }
    }
`;

// We must manually add `fragments` property to the component for Prismic previews to work
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
PageNotFoundTemplate.fragments = [LinkFragments, MetaFragment];

export default PageNotFoundTemplate;
