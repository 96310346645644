import { graphql } from 'gatsby';

export const FaqSliceFragment = graphql`
    fragment PrismicFaqFragment on PRISMIC_PageBodyFaq {
        type
        primary {
            title: faq_title
            note: faq_note
            allowMultipleOpenItems: faq_multiple_open_items
        }
        fields {
            question: faq_question
            answer: faq_answer
        }
    }
`;
