import React from 'react';
import { PrismicHTML, PrismicExactImage } from 'components/Prismic';
import { Container } from '../../UI/Container/Container';
import { PrismicLink } from '../../PrismicLink';
import './PressList.scss';
import { Grid, GridRow, GridCol } from '../../UI/Grid';
import { SliceContext, PrismicRichTextFieldType, PrismicImageField, PrismicLinkFieldType } from '../types';
import { SupportedPrismicSlicesEnum } from '../enums';

type PressListItem = {
    press_list_image: PrismicImageField;
    press_list_title: string | null;
    press_list_content: PrismicRichTextFieldType;
    press_list_link: PrismicLinkFieldType;
};

type PressListSlice = {
    type: SupportedPrismicSlicesEnum.pressList;
    primary: {
        press_list_number_of_columns: '2' | '3';
    };
    fields: PressListItem[];
};

type PressListProps = {
    slice: PressListSlice;
    sliceContext: SliceContext;
};

class PressList extends React.Component<PressListProps> {
    render() {
        const { fields } = this.props.slice;
        const nbrCol = this.props.slice.primary.press_list_number_of_columns;
        const colSize = nbrCol === '2' ? '6' : '4';

        return (
            <Container size="md">
                <Grid className="PressList">
                    <GridRow horizontalAlign={'h-start'} verticalAlign={'v-center'}>
                        {fields.map((item, index) => (
                            <GridCol
                                colSize={colSize}
                                offsetSize={`0`}
                                mobileColSize={`12`}
                                tabletColSize={`6`}
                                key={index}
                            >
                                <div className="PressList-item">
                                    <div className="PressList-item-header">
                                        <PrismicExactImage
                                            className="PressList-item-image"
                                            image={item.press_list_image}
                                        />
                                        <div className="PressList-item-title">{item.press_list_title}</div>
                                    </div>
                                    <PrismicHTML content={item.press_list_content} className="PressList-item-content" />
                                    {/* TODO Remove this once we have actual icons */}
                                    <PrismicLink
                                        label="↗"
                                        link={item.press_list_link}
                                        className="PressList-item-link"
                                        slice={this.props.sliceContext}
                                    />
                                </div>
                            </GridCol>
                        ))}
                    </GridRow>
                </Grid>
            </Container>
        );
    }
}

export { PressList, PressListProps, PressListSlice, PressListItem };
