import React from 'react';
import { ButtonProps, ButtonVariant } from '@landr/maestro';
import { CtaKind } from 'components/Slices/types';
import { PrismicLinkFieldType, SliceContext, PrismicKeyTextFieldType } from 'components/Slices/types';
import * as Styled from './PrismicButton.styled';
import { usePrismicButton } from './usePrismicButton';

export interface PrismicButtonProps extends Omit<ButtonProps, 'label' | 'kind' | 'color'> {
    label?: PrismicKeyTextFieldType;
    link: PrismicLinkFieldType;
    className?: string;
    children?: React.ReactNode;
    sliceContext?: SliceContext;
    ctaKind?: CtaKind;
    ctaVariant?: ButtonVariant;
    defaultIcon?: any;
}

export const PrismicButton: React.FC<PrismicButtonProps> = ({
    label,
    link,
    children,
    ctaKind,
    ctaVariant,
    icon,
    defaultIcon,
    sliceContext,
    ...rest
}) => {
    const { ctaContent, ...buttonProps } = usePrismicButton({
        children,
        ctaKind,
        ctaVariant,
        defaultIcon,
        icon,
        label,
        link,
        sliceContext,
    });

    if (!link || !ctaContent) {
        return null;
    }

    return (
        <Styled.Button {...buttonProps} {...rest} isRounded>
            {ctaContent}
        </Styled.Button>
    );
};
