import React from 'react';
import { Cart } from 'components/Slices/Cart';
import { withAppContext, AppContextType } from '../../context/AppContext';
import { PageContextType } from '../../types';
import { Slice, SliceContext } from './types';
import { SupportedPrismicSlicesEnum } from './enums';
import { Hero } from './Hero';
import { HeroVideo } from './HeroVideo';
import { LogoList } from './LogoList';
import { CallToAction } from './CallToAction';
import { PressList } from './PressList';
import { TextGrid } from './TextGrid';
import { TextBlock } from './TextBlock';
import { Visual } from './Visual';
import { Quotes } from './Quotes';
import { QuotesVideo } from './QuotesVideo';
import { FeaturedArtists } from './FeaturedArtists';
import { Feature } from './Feature';
import { FeatureVideo } from './FeatureVideo';
import { MasteringDemos } from './MasteringDemos';
import { Faq } from './Faq';
import { VideoPlayer } from './VideoPlayer';
import { Blog } from './Blog';
import { Signup } from './Signup';
import { Pricing } from './Pricing';
import { LinkList } from './LinkList';
import { RoyaltyCalculatorSlice } from './RoyaltyCalculator';

type SlicesTypesMapping = { [index: string]: any };

export const slicesTypesMapping: SlicesTypesMapping = {
    [SupportedPrismicSlicesEnum.blog]: Blog,
    [SupportedPrismicSlicesEnum.cta]: CallToAction,
    [SupportedPrismicSlicesEnum.faq]: Faq,
    [SupportedPrismicSlicesEnum.feature]: Feature,
    [SupportedPrismicSlicesEnum.feature_video]: FeatureVideo,
    [SupportedPrismicSlicesEnum.cart]: Cart,
    [SupportedPrismicSlicesEnum.featured_artists]: FeaturedArtists,
    [SupportedPrismicSlicesEnum.hero]: Hero,
    [SupportedPrismicSlicesEnum.hero_video]: HeroVideo,
    [SupportedPrismicSlicesEnum.logoList]: LogoList,
    [SupportedPrismicSlicesEnum.mastering_demos]: MasteringDemos,
    [SupportedPrismicSlicesEnum.pressList]: PressList,
    [SupportedPrismicSlicesEnum.pricing]: Pricing,
    [SupportedPrismicSlicesEnum.quotes]: Quotes,
    [SupportedPrismicSlicesEnum.quotes_video]: QuotesVideo,
    [SupportedPrismicSlicesEnum.signup]: Signup,
    [SupportedPrismicSlicesEnum.textBlock]: TextBlock,
    [SupportedPrismicSlicesEnum.text_grid]: TextGrid,
    [SupportedPrismicSlicesEnum.videoplayer]: VideoPlayer,
    [SupportedPrismicSlicesEnum.visual]: Visual,
    [SupportedPrismicSlicesEnum.linklist]: LinkList,
    [SupportedPrismicSlicesEnum.royalty_calculator]: RoyaltyCalculatorSlice,
};

type SlicesProps = {
    location: Location;
    pageContext: PageContextType;
    slices: Slice[];
    context: AppContextType;
};

class SlicesComponent extends React.Component<SlicesProps> {
    render() {
        const { location, pageContext, slices, context } = this.props;

        if (!slices) {
            return null;
        }

        return slices.map((slice: Slice, index: any) => {
            const SliceComponent = slicesTypesMapping[slice.type];

            const sliceContext: SliceContext = {
                position: index,
                type: slice.type,
            };
            return (
                SliceComponent && (
                    <SliceComponent
                        key={index}
                        location={location}
                        pageContext={pageContext}
                        appContext={context} // in real life this is the page detail context
                        sliceContext={sliceContext}
                        slice={slice}
                    />
                )
            );
        });
    }
}

const Slices = withAppContext(SlicesComponent);

export { SliceFragments } from './fragments';
export { Slices, SlicesComponent, SlicesProps };
