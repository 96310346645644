import { graphql } from 'gatsby';

export const CartSliceFragment = graphql`
    fragment PrismicCartFragment on PRISMIC_PageBodyCart {
        type
        primary {
            cart_title
            cart_price
            cart_description
            cart_checkout_link {
                ...LinkFragment
            }
            cart_image
            cart_imageSharp {
                ...FluidImageFragment
            }
        }
    }
`;
