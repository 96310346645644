import React, { useEffect, useState } from 'react';
import { PlayButton } from 'components/UI/PlayButton';
import { PrismicHTML } from 'components/Prismic';
import { useId } from '@landr/maestro';
import { EventId } from 'enum/eventId';
import { Grid, GridCol, GridRow } from '../../UI/Grid';
import { log } from '../../../log';
import { MasteringDemosProps, Versions } from './types';
import { StyleImage } from './StyleImage';
import { BtnVersion } from './BtnVersion';
import { Style } from './Style';
import { backgroundResize, componentContentAnimation, moveToggle, setProgressBgToDefault } from './Animations';
import './MasteringDemos.scss';
import { AudioPlayers } from './AudioPlayers';
import { AudioPlayersProgress } from './AudioPlayers/AudioPlayersProgress';

const logger = log.getInstance('MasteringDemos');

export const MasteringDemos: React.FC<MasteringDemosProps> = ({ slice, sliceContext }) => {
    const [activeStyle, setActiveStyle] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [activeVersion, setActiveVersion] = useState(Versions.original);
    const [originalBtnWidth, setOriginalBtnWidth] = useState(0);
    const [masterBtnWidth, setMasterBtnWidth] = useState(0);
    const [isSinglePlayer, setIsSinglePlayer] = useState<boolean | undefined>();
    const [circleProgress, setCircleProgress] = useState<AudioPlayersProgress>();

    const SelectedBackgroundBtn: React.RefObject<any> = React.createRef();
    const BtnOriginal: React.RefObject<any> = React.createRef();
    const BtnMastered: React.RefObject<any> = React.createRef();
    const SpinnerRef: React.RefObject<any> = React.createRef();
    const ProgressBg: React.RefObject<any> = React.createRef();

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        mastering_demos_title,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        mastering_demos_original_button_copy,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        mastering_demos_mastered_button_copy,
    } = slice.primary;
    const audioStyles = slice.fields;

    const uniquePlayerID = useId();

    useEffect(() => {
        if (audioStyles === null) {
            logger.error('Mastering Demos slice failed', EventId.AbPlayerFailed);
            return;
        }
        componentContentAnimation();

        if (audioStyles) {
            setIsSinglePlayer(audioStyles[0].mastering_demos_mastered === null ? true : false);
        }

        setCircleProgress(new AudioPlayersProgress());
    }, [audioStyles]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            backgroundResize(isSinglePlayer, BtnOriginal, BtnMastered, SelectedBackgroundBtn);
        });

        if (!isSinglePlayer) {
            setOriginalBtnWidth(BtnOriginal.current.offsetWidth);
            setMasterBtnWidth(BtnMastered.current.offsetWidth);
        }
    }, [isSinglePlayer, BtnOriginal]);

    const handleStyle = (i: number) => {
        setActiveStyle(i);
        setIsPlaying(false);
        setActiveVersion(Versions.original);
        !isSinglePlayer && animateToggle(Versions.original);
    };

    const handleVersion = (version: Versions) => {
        setActiveVersion(version);
        SelectedBackgroundBtn.current.style.width = `${originalBtnWidth}px`;
        if (!isPlaying) {
            setIsPlaying(true);
        }

        if (!isSinglePlayer) {
            animateToggle(version);
            setOriginalBtnWidth(BtnOriginal.current.offsetWidth);
            setMasterBtnWidth(BtnMastered.current.offsetWidth);
        }
    };

    const animateToggle = (version: Versions) => {
        if (version === Versions.original) {
            SelectedBackgroundBtn.current.style.width = `${originalBtnWidth}px`;
            moveToggle(SelectedBackgroundBtn.current, 0);
        } else {
            SelectedBackgroundBtn.current.style.width = `${masterBtnWidth}px`;
            moveToggle(SelectedBackgroundBtn.current, originalBtnWidth);
        }
    };

    const handlePlayerState = () => {
        isPlaying ? setIsPlaying(false) : setIsPlaying(true);
    };

    const updateLoadingSpinner = (active: boolean) => {
        if (SpinnerRef.current === null) {
            return;
        }

        if (active) {
            SpinnerRef.current.style.display = 'block';
            setProgressBgToDefault(ProgressBg.current);
        } else {
            SpinnerRef.current.style.display = 'none';
        }
    };

    useEffect(() => {
        return function cleanup() {
            window.removeEventListener('resize', () => {
                backgroundResize(isSinglePlayer, BtnOriginal, BtnMastered, SelectedBackgroundBtn);
            });
        };
    });

    return (
        <div className="MasteringDemos-fullscreen">
            <Grid className="MasteringDemos-container">
                <GridRow horizontalAlign={'h-start'} verticalAlign={'v-center'} className="FeaturedArtists-content">
                    <GridCol colSize={`12`} offsetSize={`0`} className="Wrapper-info">
                        <PrismicHTML content={mastering_demos_title} className="MasteringDemos-title" />
                        {audioStyles.map((style, i) => (
                            <div key={i}>
                                <Style
                                    key={i}
                                    style={style}
                                    handleStyle={() => handleStyle(i)}
                                    isActiveStyle={activeStyle === i}
                                />
                            </div>
                        ))}
                        <GridCol colSize={`6`} offsetSize={`0`} mobileColSize={'12'} className="Wrapper-info-bottom">
                            <div className="Wrapper-abcontrol">
                                <canvas id="Progress-bar" width="100px" height="100px" />
                                <div className="Progress-bg" ref={ProgressBg} />
                                <PlayButton
                                    isPlaying={isPlaying}
                                    audioAction={() => handlePlayerState()}
                                    className="MasteringDemos-playButton"
                                />
                                {audioStyles.map((style, i) => {
                                    return (
                                        <AudioPlayers
                                            key={i}
                                            playerNumber={i}
                                            audioStyles={style}
                                            activeVersion={activeVersion}
                                            activeStyle={activeStyle}
                                            isPlaying={isPlaying}
                                            sliceContext={sliceContext}
                                            isSinglePlayer={isSinglePlayer}
                                            showSpinner={() => updateLoadingSpinner(true)}
                                            hideSpinner={() => updateLoadingSpinner(false)}
                                            circleProgress={circleProgress}
                                            uniquePlayerID={uniquePlayerID}
                                        />
                                    );
                                })}
                            </div>
                        </GridCol>
                        {!isSinglePlayer && (
                            <GridCol colSize={`6`} offsetSize={`0`} mobileColSize={'12'} className="Block-version">
                                <BtnVersion
                                    changeVersionToOriginal={() => handleVersion(Versions.original)}
                                    changeVersionToMastered={() => handleVersion(Versions.mastered)}
                                    selectedVersion={activeVersion}
                                    masteredButtonCopy={mastering_demos_mastered_button_copy}
                                    originalButtonCopy={mastering_demos_original_button_copy}
                                    SelectedBackgroundBtn={SelectedBackgroundBtn}
                                    BtnOriginal={BtnOriginal}
                                    BtnMastered={BtnMastered}
                                />
                            </GridCol>
                        )}
                    </GridCol>
                </GridRow>
            </Grid>
            {audioStyles.map((style, i) => (
                <StyleImage key={i} style={style} isSelectedStyle={i === activeStyle} />
            ))}
        </div>
    );
};
