/* eslint-disable */
/**
 * !!!DO NOT EDIT THIS FILE!!!
 *
 * The following code is fully generated from the definitions provided in the Analytics AirTable definition.
 * You can regenerate it with "yarn generate:analytics:v2"
 */
import { AbstractSegmentServiceV2, AnalyticsApplication } from '@landr/core';

export enum CtaGoal {
    AccessMusixmatch = 'Access Musixmatch',
    AddClients = 'Add Clients',
    AddtoCalendarGoogle = 'Add to Calendar - Google',
    AddtoCalendariCal = 'Add to Calendar - iCal',
    AddtoCalendarOutlook = 'Add to Calendar - Outlook',
    BacktoPreviousStep = 'Back to Previous Step',
    BrowseNetwork = 'Browse Network',
    BrowsePros = 'Browse Pros',
    BrowseSamples = 'Browse Samples',
    BrowseServiceCategory = 'Browse Service Category',
    BrowseTopService = 'Browse Top Service',
    Checkout = 'Checkout',
    CopyLink = 'Copy Link',
    CreateProfile = 'Create Profile',
    CreateQuote = 'Create Quote',
    DownloadPluginLandrFXAccoustic = 'Download Plugin - Landr FX Accoustic',
    DownloadPluginLandrFXBass = 'Download Plugin - Landr FX Bass',
    DownloadPluginLandrFXBeats = 'Download Plugin - Landr FX Beats',
    DownloadPluginLandrFXElectric = 'Download Plugin - Landr FX Electric',
    DownloadPluginLandrFXVoice = 'Download Plugin - Landr FX Voice',
    DownloadPluginLethal = 'Download Plugin - Lethal',
    DownloadVocAlign = 'Download VocAlign',
    FindaPro = 'Find a Pro',
    GetDeal = 'Get Deal',
    GetTier2Plan = 'Get Tier 2 Plan',
    GetTier3Plan = 'Get Tier 3 Plan',
    GotoChromatic = 'Go to Chromatic',
    GotoCreator = 'Go to Creator',
    GotoDistribution = 'Go to Distribution',
    GotoLibrary = 'Go to Library',
    GotoNetwork = 'Go to Network',
    GotoPlugins = 'Go to Plugins',
    GotoProjects = 'Go to Projects',
    GotoSamples = 'Go to Samples',
    GotoSessions = 'Go to Sessions',
    InstallPluginLandrFXAccoustic = 'Install Plugin - Landr FX Accoustic',
    InstallPluginLandrFXBass = 'Install Plugin - Landr FX Bass',
    InstallPluginLandrFXBeats = 'Install Plugin - Landr FX Beats',
    InstallPluginLandrFXElectric = 'Install Plugin - Landr FX Electric',
    InstallPluginLandrFXSuite = 'Install Plugin - Landr FX Suite',
    InstallPluginLandrFXVoice = 'Install Plugin - Landr FX Voice',
    InstallPluginLethal = 'Install Plugin - Lethal',
    JoinSession = 'Join Session',
    LearnaboutChromatic = 'Learn about Chromatic',
    NextStep1 = 'Next Step 1',
    NextStep2 = 'Next Step 2',
    NextStep3 = 'Next Step 3',
    NextStep4 = 'Next Step 4',
    OpeninCreator = 'Open in Creator',
    ReviewOrder = 'Review Order',
    SearchNetwork = 'Search Network',
    ShowMore = 'Show More',
    SkipStep1 = 'Skip Step 1',
    SkipStep2 = 'Skip Step 2',
    SkipStep3 = 'Skip Step 3',
    SkipStep4 = 'Skip Step 4',
    StartVocAlign = 'Start VocAlign',
    TryCreator = 'Try Creator',
    TurnOffAutoPlay = 'Turn Off Auto-Play',
    TurnOnAutoPlay = 'Turn On Auto-Play',
    UpgradeDistributionPlan = 'Upgrade Distribution Plan',
    UpgradeMasteringPlan = 'Upgrade Mastering Plan',
    UpgradePlan = 'Upgrade Plan',
    ViewAAPPricing = 'View AAP Pricing',
    ViewAdvancedMasterSettings = 'View Advanced Master Settings',
    ViewAutoTunePlugin = 'View Auto-Tune Plugin',
    ViewBeeperPlugin = 'View Beeper Plugin',
    ViewBlueCatFlangerPlugin = 'View Blue Cat Flanger Plugin',
    ViewBoogexPlugin = 'View Boogex Plugin',
    ViewDetail = 'View Detail',
    ViewLandrFXBundle = 'View Landr FX Bundle',
    ViewLandrFXPlugin = 'View Landr FX Plugin',
    ViewLatencyPlugin = 'View Latency Plugin',
    ViewLethalPlugin = 'View Lethal Plugin',
    ViewMessages = 'View Messages',
    ViewNewDropdown = 'View New Dropdown',
    ViewNotifications = 'View Notifications',
    ViewOldSkoolVerbPlusPlugin = 'View OldSkoolVerb Plus Plugin',
    ViewOvertoneGEQPlugin = 'View Overtone GEQ Plugin',
    ViewPanCakePlugin = 'View PanCake Plugin',
    ViewPlugin = 'View Plugin',
    ViewPricing = 'View Pricing',
    ViewProject = 'View Project',
    ViewPromolink = 'View Promolink',
    ViewRecorderPlugin = 'View Recorder Plugin',
    ViewReferralRewards = 'View Referral Rewards',
    ViewSamplesPlugin = 'View Samples Plugin',
    ViewServices = 'View Services',
    ViewSessionsPlugin = 'View Sessions Plugin',
    ViewSpanPlugin = 'View Span Plugin',
    ViewStereoTouchPlugin = 'View Stereo Touch Plugin',
    ViewTripleEQ4Plugin = 'View Triple EQ 4 Plugin',
    WatchReferenceMasteringVideo = 'Watch Reference Mastering Video',
}

export enum CtaType {
    Button = 'Button',
    Card = 'Card',
    Dropdown = 'Dropdown',
    EllipsisMenu = 'Ellipsis Menu',
    PlainText = 'Plain Text',
}

export enum InteractionSource {
    AddAction = 'Add Action',
    AssetAction = 'Asset Action',
    AssetActionDropdown = 'Asset Action - Dropdown',
    AssetActionFeed = 'Asset Action - Feed',
    AssetActionFile = 'Asset Action - File',
    AssetActionMain = 'Asset Action - Main',
    Automatic = 'Automatic',
    Banner = 'Banner',
    BellNotification = 'Bell Notification',
    BounceFolder = 'Bounce Folder',
    CalloutBanner = 'Callout Banner',
    CardAction = 'Card Action',
    CategoryTitle = 'Category Title',
    CommentAction = 'Comment Action',
    CompareModal = 'Compare Modal',
    CreatorAction = 'Creator Action',
    Direct = 'Direct',
    DownloadAllAction = 'Download All Action',
    DragandDrop = 'Drag and Drop',
    Email = 'Email',
    InvitationModal = 'Invitation Modal',
    LibraryTray = 'Library Tray',
    MainAction = 'Main Action',
    MasterPreviewModal = 'Master Preview Modal',
    MasterWaitzine = 'Master Waitzine',
    MessagesAction = 'Messages Action',
    MultiselectAction = 'Multiselect Action',
    Navbar = 'Navbar',
    NewAction = 'New Action',
    NewActionAlbumMaster = 'New Action - Album Master',
    NewActionMaster = 'New Action - Master',
    NewActionUpload = 'New Action - Upload',
    Notification = 'Notification',
    PluginCard = 'Plugin Card',
    PricingNav = 'Pricing Nav',
    ProfileCardAction = 'Profile Card Action',
    ProfileSurvey = 'Profile Survey',
    ProjectsAction = 'Projects Action',
    ReleaseSplitDetailModal = 'Release Split Detail Modal',
    RoomCard = 'Room Card',
    ServiceBrief = 'Service Brief',
    ServiceQuote = 'Service Quote',
    SettingAction = 'Setting Action',
    SharedLink = 'Shared Link',
    StartVocAlignModal = 'Start VocAlign Modal',
    TextAction = 'Text Action',
    UpdateModal = 'Update Modal',
    UpsellModal = 'Upsell Modal',
}

export type CTAClickedProperties = {
    /**
     * cta goal = the purpose of the CTA (i.e. master track, sample download, album creation/initialization)
     */
    ctaGoal: CtaGoal;
    /**
     * cta type = the type of CTA {e.g.: button, interstitial, banner}
     */
    ctaType: CtaType;
    /**
     * interaction source = interaction the user took to perform the action (e.g.: name of notification, cta prompt, etc.)
     */
    interactionSource: InteractionSource;
    /**
     * cta destination = generally a URL destination of clicking the CTA. Before the 2021 schema update, this is called CTA target (which is confusing with CTA goal).
     */
    ctaDestination?: string;
    /**
     * cta text = the literal text displayed of the CTA (if applicable). Pass this text in English, regardless of the language that the user sees.
     */
    ctaText?: string;
};

export class AnalyticsV2Service extends AbstractSegmentServiceV2 {
    constructor() {
        super(AnalyticsApplication.GuestSite, typeof window === 'undefined' ? undefined : window);
    }
    /**
     * Triggered when a user clicks a CTA button on a page. See Notion page for all details of when CTA Clicked is triggered on the website: https://www.notion.so/landr/CTA-Clicked-834eb12b5ea64184848cd4d8ce9127a7
     */
    trackCTAClicked(properties: CTAClickedProperties): Promise<void> {
        return this.track('CTA Clicked', {
            cta_goal: properties.ctaGoal,
            cta_type: properties.ctaType,
            interaction_source: properties.interactionSource,
            cta_destination: properties.ctaDestination ?? null,
            cta_text: properties.ctaText ?? null,
        });
    }
}
