import React from 'react';
import { Badge, Box } from '@landr/maestro';

export const BadgeTitle: React.FC<{ content?: string }> = ({ content }) => {
    if (content) {
        return (
            <Box display="flex" ml="xs">
                <Badge size="sm" variant="secondary" content={content} />
            </Box>
        );
    }

    return null;
};
