import { TweenLite, TweenMax, Power2 } from 'gsap';

const componentContentAnimation = (
    heroTitle: React.RefObject<any>,
    heroDescription: React.RefObject<any>,
    heroActionsWrapper: React.RefObject<any>,
    heroArrowWrapper: React.RefObject<any>,
) => {
    if (heroTitle) {
        TweenLite.to(heroTitle.current, 0.3, {
            delay: 0.3,
            opacity: 1,
            y: 0,
            ease: Power2.easeIn,
        });
    }

    if (heroDescription) {
        TweenLite.to(heroDescription.current, 0.3, {
            opacity: 1,
            y: 0,
            delay: 0.5,
            ease: Power2.easeIn,
        });
    }

    if (heroActionsWrapper) {
        TweenLite.to(heroActionsWrapper.current, 0.3, {
            opacity: 1,
            y: 0,
            delay: 0.8,
            ease: Power2.easeIn,
        });
    }

    if (heroArrowWrapper) {
        TweenMax.to(heroArrowWrapper.current, 0.3, {
            opacity: 1,
            delay: 0.8,
            ease: Power2.easeIn,
        });
    }
};
export { componentContentAnimation };
