import { RefObject, useEffect, useState } from 'react';

interface Args extends IntersectionObserverInit {
    freezeOnceVisible?: boolean;
}

// https://usehooks-ts.com/react-hook/use-intersection-observer
function useIntersectionObserver(
    elementRef: RefObject<Element>,
    { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }: Args,
): IntersectionObserverEntry | undefined {
    const [entry, setEntry] = useState<IntersectionObserverEntry>();

    const frozen = entry && entry.isIntersecting && freezeOnceVisible;

    const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
        setEntry(entry);
    };

    useEffect(() => {
        const node = elementRef && elementRef.current; // DOM Ref
        let hasIOSupport = false;

        if (typeof window !== 'undefined') {
            hasIOSupport = !!window.IntersectionObserver;
        }

        if (!hasIOSupport || frozen || !node) {
            return;
        }

        const observerParams = { threshold, root, rootMargin };
        const observer = new IntersectionObserver(updateEntry, observerParams);

        observer.observe(node);

        return () => observer.disconnect();
    }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen]);

    return entry;
}

export default useIntersectionObserver;
