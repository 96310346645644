import React from 'react';
import { classNames } from 'react-extras';
import { Versions } from './types';

const BtnVersion = ({
    changeVersionToOriginal,
    changeVersionToMastered,
    selectedVersion,
    masteredButtonCopy,
    originalButtonCopy,
    SelectedBackgroundBtn,
    BtnOriginal,
    BtnMastered,
}: {
    changeVersionToOriginal: any;
    changeVersionToMastered: any;
    selectedVersion: Versions;
    masteredButtonCopy: string;
    originalButtonCopy: string;
    SelectedBackgroundBtn: any;
    BtnOriginal: any;
    BtnMastered: any;
}) => (
    <div className="ButtonSwitch ButtonSwitch--light ButtonSwitch--stackable-xsm">
        <button
            className={classNames('ButtonSwitch-item original-version Button--xs', {
                'is-selected': selectedVersion === Versions.original,
            })}
            onClick={changeVersionToOriginal}
            aria-label="Switch to original version"
            ref={BtnOriginal}
        >
            <span className="Button-wrapper">
                <span className="Button-text">{originalButtonCopy}</span>
            </span>
        </button>
        <button
            className={classNames('ButtonSwitch-item mastered-version Button--xs', {
                'is-selected': selectedVersion === Versions.mastered,
            })}
            onClick={changeVersionToMastered}
            aria-label="Switch to mastered version"
            ref={BtnMastered}
        >
            <span className="Button-wrapper">
                <span className="Button-text">{masteredButtonCopy}</span>
            </span>
        </button>
        <div className="Selected-background" ref={SelectedBackgroundBtn} />
    </div>
);

export { BtnVersion };
