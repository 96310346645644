import { graphql } from 'gatsby';

export const PricingSliceFragment = graphql`
    fragment PrismicPricingFragment on PRISMIC_PageBodyPricing {
        type
        primary {
            pricing_title
        }
    }
`;
