import React from 'react';
import { classNames } from 'react-extras';
import './CarouselControls.scss';
import { Grid, GridRow, GridCol } from '../Grid';

type CarouselControlsProps = {
    slide: {
        slideCount: number;
        currentSlide: number;
    };
    theme?: 'dark' | 'light';
    onClick: (slideNumber: number) => any;
};

const CarouselControls = ({ slide, onClick, theme = 'light' }: CarouselControlsProps) => {
    return (
        <Grid>
            <GridRow horizontalAlign={'h-end'} verticalAlign={'v-stretch'}>
                <GridCol colSize={`12`}>
                    <ul className={`CarouselControls CarouselControls--${theme}`}>
                        {Array.from(Array(slide.slideCount).keys()).map(slideNumber => (
                            <li
                                key={slideNumber}
                                className={classNames('CarouselControls-control', {
                                    'Carousel-control--active': slide.currentSlide === slideNumber,
                                })}
                            >
                                <button
                                    className={classNames('CarouselControls-controlButton', {
                                        'CarouselControls-controlButton--active':
                                            slide.currentSlide === slideNumber,
                                    })}
                                    onClick={onClick(slideNumber)}
                                    aria-label={`Go to slide ${slideNumber + 1}`}
                                />
                            </li>
                        ))}
                    </ul>
                </GridCol>
            </GridRow>
        </Grid>
    );
};

export { CarouselControls, CarouselControlsProps };
