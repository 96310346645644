// Documentation: https://mixgenius.atlassian.net/wiki/spaces/Landr/pages/29589571/Loggly+Integration
// The Guest site has event IDs of 13000 to 13999 reserved

export enum EventId {
    FetchGeolocationFailed = 13000,
    AbPlayerFailed = 13001,
    SegmentAnalyticsFailed = 13002,
    UnsupportedReturnUrlWarning = 13003,
    CatchedReturnUrlWarning = 13004,
    FeatureFlagsFailed = 13005,
    AnalyticsPropertiesError = 13006,
}
