import React from 'react';
import { PrismicHTML, PrismicExactImage, PrismicFluidImage } from 'components/Prismic';
import { PrismicButton } from 'components/PrismicButton';
import { PrismicLink } from 'components/PrismicLink';
import './TextGrid.scss';
import { useBreakpoint } from '@landr/maestro';
import { GridCol, colSize_t, offsetSize_t } from '../../UI/Grid';
import { TextGridItemVideo } from './TextGridItemVideo';
import { TextGridItemSliceEnum } from '.';

export type TextGridItemContentProps = {
    item: TextGridItemSliceEnum;
};

export const TextGridItemContent: React.FC<TextGridItemContentProps> = ({ item }) => {
    return (
        <>
            {item.text_grid_image_cta_url ? (
                <PrismicLink link={item.text_grid_image_cta_url}>
                    <div
                        className={`TextGrid-item-header ${
                            item.text_grid_vertical_logo_title === true ? 'Vertical' : ''
                        }`}
                    >
                        {item.text_grid_logo && (
                            <div className="WrapperImg">
                                <PrismicExactImage className="u-mr-1" image={item.text_grid_logo} />
                            </div>
                        )}
                        <PrismicHTML content={item.text_grid_title} styleClassName="Heading Heading--lg" />
                    </div>
                </PrismicLink>
            ) : (
                <div
                    className={`TextGrid-item-header ${item.text_grid_vertical_logo_title === true ? 'Vertical' : ''}`}
                >
                    {item.text_grid_logo && (
                        <div className="WrapperImg">
                            <PrismicExactImage className="u-mr-1" image={item.text_grid_logo} />
                        </div>
                    )}
                    <PrismicHTML content={item.text_grid_title} styleClassName="Heading Heading--lg" />
                </div>
            )}
            <PrismicHTML content={item.text_grid_description} className="TextGrid-item-content" />
            {item.text_grid_cta && item.text_grid_cta_type && item.text_grid_cta_label && (
                <PrismicButton
                    ctaKind={item.text_grid_cta_type}
                    link={item.text_grid_cta}
                    label={item.text_grid_cta_label}
                    size="lg"
                    isFull
                />
            )}
        </>
    );
};

export type TextGridItemProps = {
    item: TextGridItemSliceEnum;
    colSize: colSize_t | undefined;
    offsetSize: offsetSize_t | undefined;
    isHoverVideoMuted: boolean;
    setVideoAudio: () => void;
};

export const TextGridItem: React.FC<TextGridItemProps> = ({
    item,
    colSize,
    offsetSize,
    isHoverVideoMuted,
    setVideoAudio,
}) => {
    const videoUrl = item.text_grid_hover_video_id;
    const isMobile = useBreakpoint('sm');

    return (
        <GridCol
            colSize={colSize}
            offsetSize={offsetSize}
            tabletColSize={'6'}
            tabletOffsetSize={'0'}
            mobileColSize={'12'}
            className="Bloc-text"
        >
            <div className="TextGrid-item">
                <>
                    {videoUrl && !isMobile ? (
                        <TextGridItemVideo
                            item={item}
                            videoUrl={videoUrl}
                            isHoverVideoMuted={isHoverVideoMuted}
                            setVideoAudio={setVideoAudio}
                        />
                    ) : (
                        <>
                            {item.text_grid_image_cta_url ? (
                                <PrismicLink link={item.text_grid_image_cta_url} className="TextGrid-item-image-cta">
                                    <PrismicFluidImage
                                        className="TextGrid-item-fullimage"
                                        image={item.text_grid_full_imageSharp}
                                        fallback={item.text_grid_full_image}
                                    />
                                </PrismicLink>
                            ) : (
                                <PrismicFluidImage
                                    className="TextGrid-item-fullimage"
                                    image={item.text_grid_full_imageSharp}
                                    fallback={item.text_grid_full_image}
                                />
                            )}
                        </>
                    )}
                </>
                <TextGridItemContent item={item} />
            </div>
        </GridCol>
    );
};
