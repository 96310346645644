import React, { forwardRef } from 'react';
import { classNames } from 'react-extras';
import './PlayButton.scss';

type PlayButttonProps = {
    isPlaying: boolean;
    audioAction: any;
    className?: string;
};

const PlayButton = forwardRef((props: PlayButttonProps, ref: React.Ref<any>) => {
    const { isPlaying, audioAction, className = '' } = props;
    return (
        <div className={classNames('PlayButton', className)} onClick={audioAction}>
            <button
                className="Button Button--secondary Button--round"
                id="uniqueID"
                aria-label={isPlaying ? 'Stop music' : 'Play music'}
                ref={ref}
            >
                <div className="Button-wrapper">
                    <div className="Button-icon">
                        {!isPlaying && <div className="Arrow-play" />}
                        {isPlaying && <div className="Arrow-stop" />}
                    </div>
                </div>
            </button>
        </div>
    );
});

export { PlayButton };
