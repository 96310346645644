import React from 'react';
import './FeaturedArtists.scss';
import { Carousel, Box } from '@landr/maestro';
import {
    PrismicTitleFieldType,
    PrismicRichTextFieldType,
    PrismicLinkFieldType,
    SliceContext,
    PrismicFluidImageField,
    PrismicImageField,
} from '../types';
import { Artist } from './Artist';

export type ArtistItems = {
    featured_artists_eyebrow: PrismicTitleFieldType;
    featured_artists_title: PrismicTitleFieldType;
    featured_artists_cta_link: PrismicLinkFieldType;
    featured_artists_cta_label: PrismicTitleFieldType;
    featured_artists_description: PrismicRichTextFieldType;
    featured_artists_image: PrismicImageField;
    featured_artists_imageSharp: PrismicFluidImageField;
};

export type FeaturedArtistsProps = {
    slice: {
        fields: ArtistItems[];
    };
    sliceContext: SliceContext;
};

export type FeaturedArtistsState = {
    currentSlideIndex: number;
};

class FeaturedArtists extends React.Component<FeaturedArtistsProps, FeaturedArtistsState> {
    state = {
        currentSlideIndex: 0,
    };

    setCurrentSlide = (slideIndex: number) => () => {
        this.setState({ currentSlideIndex: slideIndex });
    };

    render() {
        const artists = this.props.slice.fields;

        return (
            <>
                <Box my={'lg'}>
                    <Carousel
                        items={artists.map((artist, index) => (
                            <Artist artist={artist} key={index} sliceContext={this.props.sliceContext} />
                        ))}
                        slidesPerPage={1}
                        gutter="xs"
                        arrowPosition="inside"
                    />
                </Box>
            </>
        );
    }
}

export { FeaturedArtists };
