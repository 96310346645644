// eslint-disable-next-line no-unused-vars
import React from 'react';
import { registerLinkResolver } from 'gatsby-source-prismic-graphql';
// eslint-disable-next-line no-unused-vars
import App from './src/templates/App';
import 'nodelist-foreach-polyfill';
import { previewLinkResolver } from './src/helpers';

const transitionDelay = 1;

// Used to redirect the user to the correct page when loading a Prismic preview
registerLinkResolver(previewLinkResolver);

export const shouldUpdateScroll = () => false;

export const onRouteUpdate = () => window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);

export const wrapPageElement = ({ element, props }) => {
    return <App {...props}>{element}</App>;
};
