import React from 'react';
import { Button, useBreakpoint } from '@landr/maestro';
import { useAuthentication } from 'components/Authentication';

export const GoToApp: React.FC<{ goToAppLabel: string }> = ({ goToAppLabel }) => {
    const { isAuthenticated, signupUrl } = useAuthentication();
    const isMobile = useBreakpoint('lg');

    return (
        <Button
            as="a"
            variant="secondary"
            size={isMobile ? 'sm' : 'md'}
            href={isAuthenticated ? process.env.GATSBY_APP_ENDPOINT : signupUrl}
            isRounded
        >
            {goToAppLabel}
        </Button>
    );
};
