import { graphql } from 'gatsby';

// Must be separate from other queries, since it can't be sent to Prismic with the preview query
// without causing errors (File is an ImageSharp type, not a Prismic type).
export const ImageSharpFragments = graphql`
    fragment FluidImageFragment on File {
        childImageSharp {
            fluid(maxWidth: 1920, quality: 85) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`;

export const MetaFragment = graphql`
    fragment MetaFragment on PRISMIC__Document {
        _meta {
            id
            uid
            lang
            type
            firstPublicationDate
            lastPublicationDate
            alternateLanguages {
                lang
                uid
                type
                id
            }
        }
    }
`;

export const LinkFragments = graphql`
    fragment ExternalLinkFragment on PRISMIC__ExternalLink {
        _linkType
        target
        url
    }

    fragment InternalLinkFragment on PRISMIC_Page {
        _linkType
        _meta {
            uid
            lang
        }
        page_type
    }

    fragment FileLinkFragment on PRISMIC__FileLink {
        name
        url
        size
    }

    fragment LinkFragment on PRISMIC__Linkable {
        ...ExternalLinkFragment
        ...InternalLinkFragment
        ...FileLinkFragment
    }
`;
