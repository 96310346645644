import { graphql } from 'gatsby';

export const FeatureVideoSliceFragment = graphql`
    fragment PrismicFeatureVideoFragment on PRISMIC_PageBodyFeature_video {
        type
        primary {
            feature_video_eyebrow
            feature_video_title
            feature_video_position
            feature_video_description
            feature_video_cta_label
            feature_video_cta_kind
            feature_video_cta_variant
            feature_video_cta_link {
                ...LinkFragment
            }
            feature_video_api
            feature_video_id
            feature_video_size
        }
    }
`;
