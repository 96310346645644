import React, { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Layout from 'components/Layout';
import { PageContextType } from 'types';
import { AuthenticationProvider } from 'components/Authentication';
import { getLanguageFromLocation, getPrismicLanguageCode, isPreviewActive, pricingUidToTab } from 'helpers';
import { PageTemplateProps } from 'templates/Page';
import { MaestroThemeProvider } from '@landr/maestro-legacy';
import { PlayerProvider } from '@landr/react-player';
import { AnalyticsIdentityController } from 'components/Analytics';
import { ExperimentalProvider, ResponsiveProvider } from '@landr/maestro';
import { log } from '../log';
import { getGDPRStatus } from '../helpers/geolocation';
import { AppContextProvider } from '../context/AppContext';
import { FlagsProvider } from '../context/FlagsContext';
import { customTheme } from '../styles/customTheme';
import { PricingTabToHomePageEnum, getLocationTabUid } from '../components/Slices/Pricing';
import { getUnpublishedPageData } from './pageData';
import { PageDataType, PageType } from './types';
import { PageNotFoundTemplateProps } from './PageNotFound';

const textToBooleanMapping: { [index: string]: boolean } = {
    yes: true,
    no: false,
};
type AllProvidersProps = {
    location: Location;
    page: PageDataType | null;
    pageContext: PageContextType;
};

function isNormalPage(page: PageDataType): page is PageDataType {
    return !!(page as PageDataType).body;
}

export const getAppProviderOptions = (page: PageDataType | null) => {
    return page && isNormalPage(page)
        ? {
              show_navigation_header: textToBooleanMapping[page.show_navigation_header],
              header_type: page.header_type,
              page_type: page.page_type,
              partner_logo: page.partner_logo,
              partner_url: page.partner_url,
              show_footer: textToBooleanMapping[page.show_footer],
          }
        : {
              show_navigation_header: true,
              header_type: 'Main Navigation',
              page_type: 'Default' as PageType,
              partner_logo: null,
              partner_url: null,
              show_footer: true,
          };
};

function getPricingTab(location: Location) {
    const tabUid = getLocationTabUid(location) as keyof typeof pricingUidToTab;
    if (!tabUid) {
        return undefined;
    }
    return PricingTabToHomePageEnum[pricingUidToTab[tabUid]];
}

function getPrismicPage(page: PageDataType | null) {
    return page && isNormalPage(page) && page.home_page;
}

export const AllProviders: React.FC<AllProvidersProps> = ({ location, page, pageContext, children }) => {
    // Setting this to true by default so there is no flickering in the UI
    const [hasGivenCookieConsent, setHasGivenCookieConsent] = useState(true);
    const options = getAppProviderOptions(page);

    // Unpublished pages don't contain data at compile time, so we need defaults here.
    const layoutData = {
        alternateLanguages: (page && page._meta.alternateLanguages) || [],
        homePage: getPrismicPage(page) || getPricingTab(location) || '',
        lang: (page && page._meta.lang) || getPrismicLanguageCode(getLanguageFromLocation(location)),
    };

    const handleAgreeToCookieConsent = useCallback(() => {
        Cookies.set('cookieconsent_status', 'dismiss', {
            path: '/',
            domain: process.env.COOKIE_DOMAIN,
            expires: 365,
        });
        setHasGivenCookieConsent(true);
    }, []);

    useEffect(() => {
        const geolocation$ = getGDPRStatus().subscribe((isPartOfGDPR: boolean) => {
            const hasDismissedBanner = Cookies.get('cookieconsent_status') === 'dismiss';
            // If the user is part of the GDPR, then show the cookie consent banner
            // if they haven't already accepted the terms
            if (isPartOfGDPR && !hasDismissedBanner) {
                setHasGivenCookieConsent(false);
            }
        });

        return () => {
            geolocation$.unsubscribe();
        };
    }, []);

    return (
        <FlagsProvider>
            <AppContextProvider
                pageContext={pageContext}
                alternateLanguages={layoutData.alternateLanguages}
                options={options}
                homePage={layoutData.homePage}
            >
                <MaestroThemeProvider theme={customTheme} mode={(page && page.theme.toLowerCase()) || ('dark' as any)}>
                    <ResponsiveProvider theme={customTheme}>
                        <ExperimentalProvider
                            experiments={{
                                tabsArrow: true,
                            }}
                        >
                            <AuthenticationProvider>
                                <AnalyticsIdentityController>
                                    <PlayerProvider log={log}>
                                        {isPreviewActive() ? (
                                            // Skip Layout component in preview mode, `PageTemplate` will take care of it
                                            children
                                        ) : (
                                            <Layout
                                                lang={layoutData.lang}
                                                alternateLanguages={layoutData.alternateLanguages}
                                                options={options}
                                                homePage={layoutData.homePage}
                                                pageContext={pageContext}
                                                hasGivenCookieConsent={hasGivenCookieConsent}
                                                onAgreeToCookieConsent={handleAgreeToCookieConsent}
                                            >
                                                {children}
                                            </Layout>
                                        )}
                                    </PlayerProvider>
                                </AnalyticsIdentityController>
                            </AuthenticationProvider>
                        </ExperimentalProvider>
                    </ResponsiveProvider>
                </MaestroThemeProvider>
            </AppContextProvider>
        </FlagsProvider>
    );
};

const getPageData = (pageContext: PageContextType, data?: PageTemplateProps['data']) => {
    // Unpublished pages have no `pageContext.type`
    if (pageContext.type === 'page' || (data && data.prismic && data.prismic.page)) {
        return (data && data.prismic.page) || getUnpublishedPageData(location);
    }
    return null;
};

export const withLayoutWhenPreviewIsActive = (
    Component: React.ComponentType<PageTemplateProps | PageNotFoundTemplateProps>,
) => {
    const wrapper: React.FC<PageTemplateProps> = ({ children, data, pageContext, location, ...props }) => {
        const page = getPageData(pageContext, data);
        const options = getAppProviderOptions(page);
        const layoutData = {
            alternateLanguages: (page && page._meta.alternateLanguages) || [],
            homePage: (page && isNormalPage(page) && page.home_page) || '',
            lang: (page && page._meta.lang) || getPrismicLanguageCode(getLanguageFromLocation(location)),
        };

        if (isPreviewActive()) {
            return (
                <Layout
                    lang={layoutData.lang}
                    alternateLanguages={layoutData.alternateLanguages}
                    options={options}
                    homePage={layoutData.homePage}
                    pageContext={pageContext}
                    hasGivenCookieConsent={true}
                    onAgreeToCookieConsent={() => undefined}
                >
                    <Component pageContext={pageContext} data={data} location={location} {...props}>
                        {children}
                    </Component>
                </Layout>
            );
        } else {
            return (
                <Component pageContext={pageContext} data={data} location={location} {...props}>
                    {children}
                </Component>
            );
        }
    };

    return wrapper;
};
