import React from 'react';

export const ArrowHeroCta = (): JSX.Element => (
    <svg width="40" height="24" viewBox="8 -4 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g strokeWidth="2" stroke="#F9FAFB" fill="none">
            <path d="M1.613.284V9h15.024" />
            <path d="M9 1.363L16.637 9 9 16.637" />
        </g>
    </svg>
);
