import React from 'react';
import {
    Box,
    FacebookFilled,
    Flex,
    InstagramFilled,
    Logo,
    SpotifyFilled,
    Text,
    TiktokOutlined,
    TwitterFilled,
    useBreakpoint,
    YoutubeFilled,
    LinkedinFilled,
} from '@landr/maestro';
import { homepageLink } from 'helpers';
import { PrismicLink } from 'components/PrismicLink';
import { PrismicButtonLink } from 'components/PrismicButtonLink';
import { LanguageSelector } from '../LanguageSelector';
import { MainFooterData } from '../types';

function updateYearInCopy(copy: string): string {
    return copy.replace(/20[0-9][0-9]/, new Date().getFullYear().toString());
}

const tabletLayout = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'center',
    gap: 'md',
};

const desktopLayout = {
    display: 'grid',
    gridTemplateColumns: '1fr 6fr 3fr 2fr',
    alignItems: 'center',
};

export interface MainFooterBottomProps {
    lang: string;
    data: MainFooterData;
}

export const MainFooterBottom: React.FC<MainFooterBottomProps> = ({ lang, data }) => {
    const isTablet = useBreakpoint('lg');
    const marginProps = isTablet ? { mt: 'sm' } : { ml: 'sm' };

    return (
        <Box mt="jb" sx={isTablet ? tabletLayout : desktopLayout}>
            <PrismicLink link={homepageLink(lang)}>
                <Logo variant="brand" />
            </PrismicLink>
            <Flex sx={{ flexDirection: isTablet ? 'column' : 'row', alignItems: 'center' }}>
                <Text sx={marginProps} contrast="low">
                    {updateYearInCopy(data.copyright_info)}
                </Text>
                <Box sx={marginProps}>
                    <PrismicButtonLink
                        label={data.privacy_policy_label}
                        link={data.privacy_policy_link}
                        variant="ghost"
                        kind="link"
                    />
                </Box>
                <Box sx={marginProps}>
                    <PrismicButtonLink label={data.tos_label} link={data.tos_link} variant="ghost" kind="link" />
                </Box>
            </Flex>
            <Flex justifyContent={isTablet ? 'space-between' : 'flex-end'} width="100%" pr={isTablet ? '0px' : '20px'}>
                {data.social_items.map(
                    (item, index) =>
                        item.link && (
                            <Flex py={0} px={'5px'} key={`wrapperSocial-${index}`}>
                                <PrismicLink key={index} link={item.link} contrast="low">
                                    {(() => {
                                        switch (item.label) {
                                            case 'Facebook':
                                                return <FacebookFilled size="md" />;
                                            case 'Instagram':
                                                return <InstagramFilled size="md" />;
                                            case 'Spotify':
                                                return <SpotifyFilled size="md" />;
                                            case 'Twitter':
                                                return <TwitterFilled size="md" />;
                                            case 'Youtube':
                                                return <YoutubeFilled size="md" />;
                                            case 'Tiktok':
                                                return <TiktokOutlined size="md" />;
                                            case 'LinkedIn':
                                                return <LinkedinFilled size="md" />;
                                        }
                                    })()}
                                </PrismicLink>
                            </Flex>
                        ),
                )}
            </Flex>
            <LanguageSelector />
        </Box>
    );
};
