import React from 'react';
import { ButtonVariant } from '@landr/maestro';
import { SegmentService } from 'analytics';
import { navigate } from 'gatsby-link';
import { CtaKind, PrismicExternalLink } from 'components/Slices/types';
import { PrismicLinkFieldType, SliceContext, PrismicKeyTextFieldType } from 'components/Slices/types';
import {
    isAnInternalLink,
    getIconFromCtaKind,
    getTarget,
    getVariant,
    getKind,
    getLinkProps,
    getUrl,
} from './PrismicButton.helpers';
import { PrismicButtonReturn } from './PrismicButton.types';

export const usePrismicButton = ({
    children,
    ctaKind,
    ctaVariant,
    defaultIcon,
    icon,
    label,
    link,
    sliceContext,
}: {
    children?: React.ReactNode;
    ctaKind?: CtaKind;
    ctaVariant?: ButtonVariant;
    defaultIcon?: any;
    icon?: any;
    label?: PrismicKeyTextFieldType | null;
    link: PrismicLinkFieldType;
    sliceContext?: SliceContext;
}): PrismicButtonReturn => {
    const ctaIcon = icon || getIconFromCtaKind(ctaKind) || defaultIcon;

    const kind = getKind(ctaKind);
    const variant = getVariant(ctaKind, ctaVariant);
    const isInternalLink = isAnInternalLink(link);
    const isExternalLink = !isInternalLink;

    const url = getUrl(link);
    const target = getTarget(link);

    const isExternalAnchorLink =
        isExternalLink && (link as PrismicExternalLink).url && (link as PrismicExternalLink).url.includes('#');

    const handleOnClick = (event: React.MouseEvent): void => {
        SegmentService.trackLinkFollowed({ 'L - Destination Link': url }, sliceContext);
        if (isInternalLink) {
            event.preventDefault();
            navigate(url);
        }

        if (isExternalLink && target !== '_blank' && !isExternalAnchorLink) {
            event.preventDefault();
            setTimeout(() => {
                window.top && window.top.location.assign(url);
            }, 250);
        }

        if (isExternalLink && target === '_blank') {
            window && window.open(url, '_blank');
        }
    };

    const linkProps = getLinkProps(isInternalLink, url, target);

    const sharedProps = {
        forwardRef: true,
        onClick: handleOnClick,
        kind,
        variant,
        icon: ctaIcon,
        ctaContent: label || children,
    };

    return { ...linkProps, ...sharedProps };
};
