import React, { useRef } from 'react';
import { Box, Progress } from '@landr/maestro';
// it's possible to lazy load the bundle here 'react-player/lazy' but create some errors with babel for now
import ReactPlayer from 'react-player';

export type VideoReactPlayerProps = {
    videoUrl: string;
    hasControls: boolean;
    isLooping: boolean;
    isMuted: boolean;
    isAutoPlaying: boolean;
    handleOnReady?: () => void;
    handleOnStart?: () => void;
};

// This video library will detect witch streaming service is used form the videoUrl and load the proper player
export const VideoReactPlayer: React.FC<VideoReactPlayerProps> = ({
    videoUrl,
    hasControls,
    isLooping,
    isMuted,
    isAutoPlaying,
    handleOnReady,
    handleOnStart,
}) => {
    const player = useRef<ReactPlayer>(null);

    return (
        <Box position="relative" minHeight={'100%'} height={'100%'} width="100%" data-testid="video-react-player">
            {/* loading state progress */}
            <Box
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent={'center'}
                width="100%"
                height="100%"
            >
                <Progress />
            </Box>
            {/* Responsive video player - Player ratio: 100 / (1280 / 720) - Padding top on the wrapper is needed - https://www.npmjs.com/package/react-player */}
            <Box pt={'56.25%'}>
                <ReactPlayer
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    ref={player}
                    url={videoUrl}
                    width={'100%'}
                    height={'100%'}
                    loop={isLooping}
                    volume={isMuted ? 0 : 1}
                    controls={hasControls}
                    muted={isMuted}
                    playing={isAutoPlaying}
                    onReady={handleOnReady && handleOnReady}
                    onStart={handleOnStart && handleOnStart}
                />
            </Box>
        </Box>
    );
};
