import React, { useEffect } from 'react';
import { SliceContext } from 'components/Slices/types';
import { usePlayer } from '@landr/react-player';
import { Versions } from '../types';
import { AudioPlayersProgress } from './AudioPlayersProgress';

export type AudioPlayersProps = {
    playerNumber: number;
    audioStyles: any;
    activeVersion: Versions;
    activeStyle: number;
    isPlaying: boolean;
    sliceContext: SliceContext;
    isSinglePlayer: boolean | undefined;
    showSpinner: () => void;
    hideSpinner: () => void;
    circleProgress: AudioPlayersProgress | undefined;
    uniquePlayerID: string;
};

export const AudioPlayers: React.FC<AudioPlayersProps> = ({
    playerNumber,
    audioStyles,
    activeVersion,
    activeStyle,
    isPlaying,
    isSinglePlayer,
    showSpinner,
    hideSpinner,
    circleProgress,
    uniquePlayerID,
}) => {
    const player = usePlayer(`player_${uniquePlayerID}_${playerNumber}`);

    useEffect(
        function setSources() {
            const playerSources: any = [];
            if (isSinglePlayer !== undefined) {
                playerSources.push({
                    id: `v-${uniquePlayerID}-original`,
                    url: audioStyles.mastering_demos_original.url,
                    preload: true,
                });
                if (!isSinglePlayer) {
                    playerSources.push({
                        id: `v-${uniquePlayerID}-mastered`,
                        url: audioStyles.mastering_demos_mastered.url,
                        preload: true,
                    });
                }
                player.setSources(playerSources);
                player.setActiveSourceId(`v-${uniquePlayerID}-original`);
                circleProgress && circleProgress.updateSource(player);
            }
        },
        [audioStyles, isSinglePlayer],
    );

    useEffect(
        function setLoading() {
            player.loading ? showSpinner() : hideSpinner();
        },
        [player.loading],
    );

    useEffect(
        function setPlaying() {
            if (activeStyle === playerNumber) {
                isPlaying ? player.setPlaying(true) : player.setPlaying(false);
                isPlaying && circleProgress && circleProgress.startProgress();
            }
        },
        [isPlaying],
    );

    useEffect(
        function setProgress() {
            circleProgress && circleProgress.update(player.currentTime, player.totalTime);
        },
        [player.currentTime, player.totalTime],
    );

    useEffect(
        function setVersion() {
            player.setActiveSourceId(`v-${uniquePlayerID}-${activeVersion}`);
        },
        [activeVersion],
    );

    useEffect(
        function destroyProgress() {
            circleProgress && circleProgress.destroy();
        },
        [activeStyle],
    );

    return <></>;
};
