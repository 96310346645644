import React, { useEffect, useState } from 'react';
import { BaseHeroSlice } from 'components/BaseHeroSlice';
import { Box, Progress, useBreakpoint } from '@landr/maestro';
import { PrismicFluidImage } from 'components/Prismic';
import { VideoReactPlayer } from 'components/UI/VideoReactPlayer/VideoReactPlayer';
import { SupportedPrismicSlicesEnum } from '../enums';
import {
    BooleanOptionType,
    CtaKind,
    PrismicFluidImageField,
    PrismicImageField,
    PrismicKeyTextFieldType,
    PrismicLinkFieldType,
    PrismicRichTextFieldType,
    PrismicTitleFieldType,
    SliceContext,
} from '../types';

export type HeroVideoProps = {
    slice: {
        type: SupportedPrismicSlicesEnum.hero_video;
        primary: {
            hero_video_title: PrismicTitleFieldType;
            hero_video_title_gtc: string;
            hero_video_title_gbc: string;
            hero_video_description: PrismicRichTextFieldType;
            hero_video_alignment: 'Left' | 'Center';
            hero_video_cta_kind: CtaKind;
            hero_video_cta_label: PrismicKeyTextFieldType;
            hero_video_cta_link: PrismicLinkFieldType;
            hero_video_cta_kind_secondary: CtaKind;
            hero_video_cta_label_secondary: PrismicKeyTextFieldType;
            hero_video_cta_link_secondary: PrismicLinkFieldType;
            hero_video_cta_label_authenticated: PrismicKeyTextFieldType;
            hero_video_cta_link_authenticated: PrismicLinkFieldType;
            hero_video_show_arrow: BooleanOptionType;
            hero_video_wistia_id: string;
            hero_video_image_mobile: PrismicImageField;
            hero_video_image_mobileSharp: PrismicFluidImageField;
        };
    };
    sliceContext: SliceContext;
};

type WrapperWistiaVideoProps = {
    videoID: string;
};

const WrapperWistiaVideo: React.FC<WrapperWistiaVideoProps> = ({ videoID }) => {
    const [videoHeight, setVideoHeight] = useState(0);

    const calculateHeight = () => {
        const height = window.outerWidth > 1024 ? (window.innerWidth * 9) / 16 : window.innerHeight;
        return height;
    };

    useEffect(() => {
        setVideoHeight(calculateHeight());
        function handleResize() {
            setVideoHeight(calculateHeight());
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <Box position="relative" minHeight={'100%'} height={videoHeight} width="100%" data-testid="wistia-player">
            <Box
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent={'center'}
                width="100%"
                height="100%"
            >
                <Progress />
            </Box>
            <VideoReactPlayer
                videoUrl={`https://home.wistia.com/medias/${videoID}`}
                hasControls={false}
                isLooping={true}
                isMuted={true}
                isAutoPlaying={true}
            />
        </Box>
    );
};

export const HeroVideo: React.FC<HeroVideoProps> = ({ slice, sliceContext }) => {
    const {
        primary: {
            hero_video_title: title,
            hero_video_title_gtc: titleGradientColorTop,
            hero_video_title_gbc: titleGradientColorBottom,
            hero_video_description: description,
            hero_video_alignment: alignment,
            hero_video_cta_kind: ctaKind,
            hero_video_cta_label: ctaLabel,
            hero_video_cta_link: ctaLink,
            hero_video_cta_kind_secondary: secondaryCtaKind,
            hero_video_cta_label_secondary: secondaryCtaLabel,
            hero_video_cta_link_secondary: secondaryCtaLink,
            hero_video_cta_label_authenticated: authenticatedCtaLabel,
            hero_video_cta_link_authenticated: authenticatedCtaLink,
            hero_video_show_arrow: showArrow,
            hero_video_wistia_id: videoId,
            hero_video_image_mobile: heroMobileImage,
            hero_video_image_mobileSharp: heroMobileImageSharp,
        },
    } = slice;

    const isMobile = useBreakpoint('lg');

    return (
        <BaseHeroSlice
            title={title}
            titleGradientColorTop={titleGradientColorTop}
            titleGradientColorBottom={titleGradientColorBottom}
            description={description}
            alignment={alignment}
            ctaKind={ctaKind}
            ctaLabel={ctaLabel}
            ctaLink={ctaLink}
            secondaryCtaKind={secondaryCtaKind}
            secondaryCtaLabel={secondaryCtaLabel}
            secondaryCtaLink={secondaryCtaLink}
            authenticatedCtaLabel={authenticatedCtaLabel}
            authenticatedCtaLink={authenticatedCtaLink}
            showArrow={showArrow}
            sliceContext={sliceContext}
            isHeroVideo={true}
        >
            {!isMobile ? (
                <Box position={'absolute'} top={0} left={0} width={'100%'} height="100%">
                    <Box width={'100%'} height={'100%'} position={'absolute'} zIndex={2} />
                    <WrapperWistiaVideo videoID={videoId} />
                </Box>
            ) : (
                <PrismicFluidImage image={heroMobileImageSharp} fallback={heroMobileImage} fullscreen={true} />
            )}
        </BaseHeroSlice>
    );
};
