import React, { useState } from 'react';
import { ArrowForwardOutlined } from '@landr/maestro';
import { prismicText } from 'helpers';
import { PrismicHTML } from 'components/Prismic';
import { PrismicButton } from 'components/PrismicButton';
import { PrismicText } from 'components/Prismic';
import { Container, SizeEnum } from '../../UI/Container/Container';
import './TextGrid.scss';
import { Grid, GridRow, GridCol, colSize_t, offsetSize_t } from '../../UI/Grid';
import {
    PrismicRichTextFieldType,
    PrismicTitleFieldType,
    PrismicImageField,
    PrismicFluidImageField,
    PrismicLinkFieldType,
    PrismicKeyTextFieldType,
    CtaKind,
} from '../types';
import { SupportedPrismicSlicesEnum } from '../enums';
import { TextGridItem } from './TextGridItem';

export type TextGridProps = {
    slice: TextGridSlice;
};

type TextGridSlice = {
    type: SupportedPrismicSlicesEnum.text_grid;
    primary: {
        tg_paddingtop: SizeEnum | null;
        tg_paddingbottom: SizeEnum | null;
        text_grid_number_of_columns: '2' | '3';
        grid_title: PrismicTitleFieldType;
        text_grid_show_cta: boolean;
        text_grid_cta_label?: PrismicTitleFieldType;
        text_grid_cta_link?: PrismicLinkFieldType;
        text_grid_slice_anchor?: PrismicKeyTextFieldType;
    };
    fields: TextGridItemSliceEnum[];
};

export type TextGridItemSliceEnum = {
    text_grid_title: PrismicTitleFieldType;
    text_grid_description: PrismicRichTextFieldType;
    text_grid_image_cta_url?: PrismicLinkFieldType;
    text_grid_cta_type?: CtaKind;
    text_grid_cta_label?: PrismicKeyTextFieldType;
    text_grid_cta?: PrismicLinkFieldType;
    text_grid_logo: PrismicImageField;
    text_grid_full_image: PrismicImageField;
    text_grid_vertical_logo_title: boolean;
    text_grid_full_imageSharp: PrismicFluidImageField;
    text_grid_hover_video_id: string;
};

// Do the conversion in column width
const getColSize = (nbrCol: string, hasTitle: boolean): colSize_t | undefined => {
    switch (nbrCol) {
        case '2':
            return hasTitle ? '4' : '6';
        case '3':
            return hasTitle ? '3' : '4';
        default:
            return;
    }
};

// Returns an offset when there is a title and the element is the first of the 2nd+ row.
// This allows the title have the full height of the grid
const calculateOffset = (i: number, nbrCol: string, hasTitle: boolean): offsetSize_t | undefined => {
    if (hasTitle && i !== 0) {
        const modulo: number = i % parseInt(nbrCol, 10);

        if (modulo === 0) {
            return getColSize(nbrCol, hasTitle);
        }
    }
};

export const TextGrid: React.FC<TextGridProps> = ({ slice }) => {
    const nbrCol = slice.primary.text_grid_number_of_columns;
    const hasTitle = !!prismicText(slice.primary.grid_title);
    const colSize = getColSize(nbrCol, hasTitle);
    const paddingSize = slice.primary.tg_paddingtop;
    const paddingBottomOverride = slice.primary.tg_paddingbottom;

    const [isHoverVideoMuted, setIsHoverVideoMuted] = useState(true);

    const setVideoAudio = () => {
        isHoverVideoMuted ? setIsHoverVideoMuted(false) : setIsHoverVideoMuted(true);
    };

    return (
        <Container
            className="TextGrid-container"
            size={!paddingSize ? 'md' : (paddingSize as SizeEnum)}
            paddingBottomOverride={paddingBottomOverride as SizeEnum}
            anchorId={slice.primary.text_grid_slice_anchor}
        >
            <Grid className="Text-bloc">
                <GridRow horizontalAlign={'h-start'} verticalAlign={'v-stretch'} className="Bloc-row">
                    {hasTitle && (
                        <GridCol colSize={colSize} tabletColSize={'12'} className="Col-title">
                            <PrismicHTML
                                content={slice.primary.grid_title}
                                className="TextGrid-title"
                                styleClassName="Heading Heading--xx"
                            />
                        </GridCol>
                    )}

                    {slice.fields.map((item, i: number) => (
                        <TextGridItem
                            key={i}
                            item={item}
                            colSize={colSize}
                            offsetSize={calculateOffset(i, nbrCol, hasTitle)}
                            isHoverVideoMuted={isHoverVideoMuted}
                            setVideoAudio={setVideoAudio}
                        />
                    ))}
                    {slice.primary.text_grid_show_cta &&
                        slice.primary.text_grid_cta_label &&
                        slice.primary.text_grid_cta_link && (
                            <GridCol
                                colSize={colSize}
                                tabletColSize={'6'}
                                tabletOffsetSize={'0'}
                                mobileColSize={'12'}
                                className="TextGrid-cta"
                            >
                                <PrismicButton
                                    link={slice.primary.text_grid_cta_link}
                                    ctaKind="link"
                                    iconRight={ArrowForwardOutlined}
                                >
                                    <PrismicText content={slice.primary.text_grid_cta_label} />
                                </PrismicButton>
                            </GridCol>
                        )}
                </GridRow>
            </Grid>
        </Container>
    );
};
