import React from 'react';
import { FAQPage, WithContext } from 'schema-dts';
import { Helmet } from 'react-helmet';
import { PrismicTitleFieldType } from '../types';
import { FaqProps } from './types';

type ArrayItemWithText = [{ text: string }];

function hasTextField(item: PrismicTitleFieldType): item is ArrayItemWithText {
    return Array.isArray(item) && item.length > 0 && Boolean(item[0].text);
}

export const FaqSchema: React.FC<FaqProps> = ({ slice }) => {
    const title = hasTextField(slice.primary.title) && slice.primary.title[0].text;

    const json: WithContext<FAQPage> = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        ...(title ? { name: title } : {}),
        mainEntity: slice.fields
            .filter((slice): slice is { question: ArrayItemWithText; answer: ArrayItemWithText } => {
                return hasTextField(slice.question) && hasTextField(slice.answer);
            })
            .map(({ question, answer }) => {
                return {
                    '@type': 'Question',
                    name: question[0].text,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: answer[0].text,
                    },
                };
            }),
    };

    return (
        <Helmet>
            <script data-testid="ldjson" type="application/ld+json">
                {JSON.stringify(json)}
            </script>
        </Helmet>
    );
};
