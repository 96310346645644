/**
 * Helpers to check pathname and retrieve UID
 * Support encoded language by decoding the URI before
 */

export const getDecodedPathname = (encoded: string): string => {
    try {
        return decodeURI(encoded);
    } catch (e) {
        // catches a malformed URI and fallback return the string as received
        return encoded;
    }
};

export const addTrailingSlash = (string: string) => {
    // Guarantee the string always end with only one '/'
    while (string.endsWith('/') || string.endsWith('\\')) {
        string = string.slice(0, -1);
    }
    return `${string}/`;
};

export const isDecodedPathnameContainsUID = (decodedPathname: string, uid: string): boolean => {
    return decodedPathname.includes(`/${uid}/`);
};

export const isLocationContainsUID = (location: Location, uid: string): boolean => {
    const decodedPathname = addTrailingSlash(getDecodedPathname(location.pathname));
    return isDecodedPathnameContainsUID(decodedPathname, uid);
};
