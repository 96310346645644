import React from 'react';
import { classNames } from 'react-extras';
import './Grid.scss';

export type colSize_t = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
export type offsetSize_t = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
export type tabletColSize_t = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
type tabletOffsetSize_t = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
type phabletColSize_t = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
type phabletOffsetSize_t = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
type mobileColSize_t = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
type mobileOffsetSize_t = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

export interface GridColProps {
    children: React.ReactNode;
    colSize?: colSize_t;
    offsetSize?: offsetSize_t;
    tabletColSize?: tabletColSize_t;
    tabletOffsetSize?: tabletOffsetSize_t;
    phabletColSize?: phabletColSize_t;
    phabletOffsetSize?: phabletOffsetSize_t;
    mobileColSize?: mobileColSize_t;
    mobileOffsetSize?: mobileOffsetSize_t;
    className?: string;
}

export const GridCol: React.FC<GridColProps> = ({
    children,
    colSize,
    offsetSize,
    tabletColSize,
    tabletOffsetSize,
    phabletColSize,
    phabletOffsetSize,
    mobileColSize,
    mobileOffsetSize,
    className,
    ...props
}) => {
    const colClasses = classNames('Col', {
        [`Col--${colSize}`]: colSize,
        [`Offset--${offsetSize}`]: offsetSize,
        [`Col-tablet--${tabletColSize}`]: tabletColSize,
        [`Offset-tablet--${tabletOffsetSize}`]: tabletOffsetSize,
        [`Col-phablet--${phabletColSize}`]: phabletColSize,
        [`Offset-phablet--${phabletOffsetSize}`]: phabletOffsetSize,
        [`Col-mobile--${mobileColSize}`]: mobileColSize,
        [`Offset-mobile--${mobileOffsetSize}`]: mobileOffsetSize,
    });

    return (
        <div className={`${colClasses} ${className || ''}`} {...props}>
            {children}
        </div>
    );
};
