import { defaultTheme } from '@landr/maestro-legacy';

/**
 * Override fontsize to use to base unit 16px from styleguide.
 * All values are Maestro's default values divided by 16.
 */
export const customTheme = {
    ...defaultTheme,
    fontSizes: {
        xs: '0.6875rem',
        sm: '0.8125',
        base: '0.9375rem',
        md: '0.9375rem',
        lg: '1.125rem',
        xl: '1.5rem',
        xxl: '1.875rem',
        xxxl: '2.25rem',
        jb: '3rem',
        mg: '3.75rem',
    },
};
