import React from 'react';
import { Grid, GridRow, GridCol } from 'components/UI/Grid';
import { PrismicHTML, PrismicFluidImage } from 'components/Prismic';
import { SliceContext } from '../types';
import { FeaturedArtistsCTA } from './FeaturedArtistsCTA';
import { ArtistItems } from '.';

type ArtistProps = {
    artist: ArtistItems;
    sliceContext: SliceContext;
};

export const Artist = (props: ArtistProps) => {
    const {
        featured_artists_eyebrow: eyebrow,
        featured_artists_title: title,
        featured_artists_cta_label: label,
        featured_artists_cta_link: link,
        featured_artists_description: description,
        featured_artists_image: fallbackImage,
        featured_artists_imageSharp: image,
    } = props.artist;

    if (!image && !fallbackImage) {
        return null;
    }

    return (
        <div className="FeaturedArtists-container">
            <PrismicFluidImage image={image} fallback={fallbackImage} fullscreen={true} />
            <Grid>
                <GridRow horizontalAlign={'h-start'} verticalAlign={'v-center'} className="FeaturedArtists-content">
                    <GridCol colSize={`4`} offsetSize={`0`} mobileColSize={'10'}>
                        <PrismicHTML content={eyebrow} className="FeaturedArtists-eyebrow" />
                        <PrismicHTML content={title} className="FeaturedArtists-title" />
                        <PrismicHTML content={description} className="FeaturedArtists-description" />
                        <FeaturedArtistsCTA ctaLabel={label} ctaLink={link} sliceContext={props.sliceContext} />
                    </GridCol>
                </GridRow>
            </Grid>
        </div>
    );
};
