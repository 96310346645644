import { graphql } from 'gatsby';

export const SignupSliceFragment = graphql`
    fragment PrismicSignupFragment on PRISMIC_PageBodySignup {
        type
        primary {
            show_login_first
        }
    }
`;
