import React from 'react';

export type ArrowProps = {
    size?: 'small' | 'large';
    color?: 'light' | 'dark' | 'blue';
    className?: string;
};

const SmallArrow = ({ color, className }: { color: string; className: string }) => (
    <svg
        width="20"
        height="20"
        xmlns="http://www.w3.org/2000/svg"
        className={`Arrow ${className}`}
        data-testid="icon"
    >
        <g fill="none" fillRule="evenodd">
            <path fill="none" d="M-2-2H8V8H-2z" />
            <g stroke={color} strokeWidth="2">
                <path d="M.836 10h18.328M10 .836L19.164 10 10 19.164" />
            </g>
        </g>
    </svg>
);

const LargeArrow = ({ color, className }: { color: string; className: string }) => (
    <svg
        width="38"
        height="38"
        xmlns="http://www.w3.org/2000/svg"
        className={`LargeArrow ${className}`}
        data-testid="icon"
    >
        <g fill="none" fillRule="evenodd">
            <path fill="none" d="M-5-5h48v48H-5z" />
            <g stroke={color} strokeWidth="2">
                <path d="M.672 19h36.656M19 .672L37.328 19 19 37.328" />
            </g>
        </g>
    </svg>
);

export const Arrow = ({ size = 'small', color = 'light', className = '' }: ArrowProps) => {
    const ArrowComponentMapping = {
        small: SmallArrow,
        large: LargeArrow,
    };

    let colorValue = '#F9FAFB';
    if (color === 'dark') {
        colorValue = '#142739';
    }
    if (color === 'blue') {
        colorValue = '#32cbcb';
    }

    const ArrowComponent = ArrowComponentMapping[size];

    return <ArrowComponent color={colorValue} className={className} />;
};
