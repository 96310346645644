import {
    CtaKind,
    CtaVariant,
    PrismicLinkFieldType,
    PrismicRichTextFieldType,
    PrismicTitleFieldType,
    SliceContext,
} from 'components/Slices/types';
import { colSize_t, offsetSize_t, tabletColSize_t } from 'components/UI/Grid';

export enum Positions {
    Left = 'Left',
    Right = 'Right',
    Center = 'Center',
}

export type PositionsStyles = {
    flexDirection: string;
    leftColSize: colSize_t;
    leftColOffset: offsetSize_t;
    rightColSize: colSize_t;
    rightColOffset: offsetSize_t;
    r_tabletColSize: tabletColSize_t;
};

export type BaseFeatureSliceProps = {
    eyeBrow: PrismicTitleFieldType | null;
    title: PrismicTitleFieldType | null;
    position: Positions | null;
    description: PrismicRichTextFieldType | null;
    ctaKind: CtaKind | null;
    ctaLabel: PrismicTitleFieldType | null;
    ctaLink: PrismicLinkFieldType | null;
    ctaVariant: CtaVariant | null;
    sliceContext: SliceContext;
};
