import { graphql } from 'gatsby';

export const VideoPlayerSliceFragment = graphql`
    fragment PrismicVideoPlayerFragment on PRISMIC_PageBodyVideoplayer {
        type
        primary {
            video_api
            video_id
            size
        }
    }
`;
