import { PrismicFixedImage, PrismicHTML, PrismicText } from 'components/Prismic';
import { Heading } from 'components/LANDR/Heading';
import React from 'react';
import { SliceContext } from 'components/Slices/types';
import { prismicText } from 'helpers';
import { PrismicLink } from 'components/PrismicLink';
import { BlogItem } from '../types';

export const BlogPost: React.FC<{ item: BlogItem; sliceContext: SliceContext }> = ({ item, sliceContext }) => {
    const {
        blog_post_link,
        blog_post_alt_text,
        blog_post_category,
        blog_post_date,
        blog_post_image,
        blog_post_title,
        blog_post_imageSharp,
        blog_post_description,
    } = item;

    if (!(blog_post_link && blog_post_image && blog_post_date && blog_post_title && blog_post_description)) {
        return null;
    }

    return (
        <PrismicLink className="BlogPost" link={blog_post_link} slice={sliceContext}>
            <PrismicFixedImage
                image={blog_post_imageSharp}
                fallback={blog_post_image}
                alt={prismicText(blog_post_alt_text)}
                className="BlogPost-image"
            />
            <div className="BlogPost-meta">
                <PrismicText component="span" className="BlogPost-category" content={blog_post_category} />
                {blog_post_date && (
                    <time className="BlogPost-date" dateTime={blog_post_date}>
                        {blog_post_date}
                    </time>
                )}
                <Heading className="BlogPost-title" size="lg">
                    <PrismicText content={blog_post_title} />
                </Heading>
                <PrismicHTML content={blog_post_description} className="BlogPost-description" />
            </div>
        </PrismicLink>
    );
};
