import { CurrencyEnum } from '../enum/currency';
import { CurrencyStoreEnum } from '../enum/currencyStore';
import { Price } from '../types';

export type CountryCurrency = {
    currency: CurrencyEnum;
    store: CurrencyStoreEnum;
};

export const DEFAULT_COUNTRY_CURRENCY = {
    currency: CurrencyEnum.USD,
    store: CurrencyStoreEnum.DEFAULT,
};

export const CountryCurrencies: { [key: string]: CountryCurrency } = {
    JP: { currency: CurrencyEnum.JPY, store: CurrencyStoreEnum.JP },
    AT: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    BE: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    CY: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    DE: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    EE: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    ES: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    FI: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    FR: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    GR: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    IE: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    IT: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    LT: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    LU: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    LV: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    MT: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    NL: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    PT: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    SI: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    SK: { currency: CurrencyEnum.EUR, store: CurrencyStoreEnum.EURTIER1 },
    SE: { currency: CurrencyEnum.SEK, store: CurrencyStoreEnum.SWEDEN },
    GB: { currency: CurrencyEnum.GBP, store: CurrencyStoreEnum.UK },
    AU: { currency: CurrencyEnum.AUD, store: CurrencyStoreEnum.AUS },
};

export const getCountryCurrency = (country: string | undefined): CountryCurrency => {
    // if country is undefined, return the default currency (USD)
    return (country && CountryCurrencies[country]) || DEFAULT_COUNTRY_CURRENCY;
};

/**
 * Return the price corresponding to `countryCurrency`, if no price was found,
 * we try to fallback to the default countryCurrency
 *
 * @param prices
 * @param countryCurrency
 */
export function findPriceForCountryCurrency(prices: Price[], countryCurrency: CountryCurrency): Price | null {
    const currencyCode = countryCurrency.currency;
    // TODO: Delete this Once plans catalog is fetch from fusebill
    const currencyCodeWithStore = `${countryCurrency.currency}-${countryCurrency.store}`;

    const price = prices.find(p => p.currencyCode === currencyCodeWithStore || p.currencyCode === currencyCode);

    // If there is no price for the requested currency, try getting the USD price
    return (
        price ||
        (countryCurrency !== DEFAULT_COUNTRY_CURRENCY &&
            findPriceForCountryCurrency(prices, DEFAULT_COUNTRY_CURRENCY)) ||
        null
    );
}
