import { graphql } from 'gatsby';

export const VisualSliceFragment = graphql`
    fragment PrismicVisualFragment on PRISMIC_PageBodyVisual {
        type
        primary {
            v_paddingtop
            v_paddingbottom
            visual_image_type
        }
        fields {
            visual_image
            visual_imageSharp {
                ...FluidImageFragment
            }
            visual_image_atl
            visual_image_description
        }
    }
`;
