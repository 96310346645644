import React, { Suspense } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql';
import { isBrowser, SiteLayout } from '@landr/maestro';
import { AlternateLanguage, PageContextType, Edges } from 'types';
import { CookieConsent, CookieConsentData } from 'components/CookieConsent';
import { MainFooter, MainFooterData } from 'components/MainFooter';
import './Layout.scss';
import { LinkFragments, MetaFragment } from 'templates/fragments.gql';
import { MainFooterFragment } from 'components/MainFooter/MainFooter.gql';
import { CookieConsentFragment } from 'components/CookieConsent/CookieConsent.gql';
import { PrismicUniversalNavigationFragments } from 'components/UniversalNavigation/UniversalNavigation.gql';
import { getDataForLang, isPreviewActive } from 'helpers';
import {
    UniversalNavigation,
    UniversalNavigationData,
    UniversalNavigationReferralData,
} from 'components/UniversalNavigation';
import { NavigationDropdownItemDocumentData } from 'components/UniversalNavigation/components/NavigationDropdownItem';
import { PrismicLinkFieldType, PrismicImageField } from '../Slices/types';

const ReferralsBanner = React.lazy(() =>
    import('../ReferralsBanners').then((module) => ({ default: module.ReferralsBanner })),
);

interface LayoutProps {
    lang: string;
    homePage: string | null;
    pageContext: PageContextType;
    alternateLanguages: AlternateLanguage[];
    hasGivenCookieConsent: boolean;
    onAgreeToCookieConsent: () => void;
    options: {
        show_navigation_header: boolean;
        header_type: string;
        partner_logo: PrismicImageField;
        partner_url: PrismicLinkFieldType;
        show_footer: boolean;
    };
}

export type LayoutData = {
    prismic: {
        allUniversal_navigations: Edges<UniversalNavigationData>;
        allUniversal_navigation_item_dropdowns: Edges<NavigationDropdownItemDocumentData>;
        allMain_footers: Edges<MainFooterData>;
        allCookie_consents: Edges<CookieConsentData>;
    };
};

const LayoutQuery = graphql`
    query LayoutQuery {
        prismic {
            allUniversal_navigations {
                edges {
                    node {
                        ...PrismicUniversalNavigationFragment
                    }
                }
            }
            allUniversal_navigation_item_dropdowns(first: 100) {
                edges {
                    node {
                        ...PrismicUniversalNavigationDropdownItemDocumentsFragment
                    }
                }
            }
            allMain_footers {
                edges {
                    node {
                        ...PrismicMainFooterFragment
                    }
                }
            }
            allCookie_consents {
                edges {
                    node {
                        ...PrismicCookieConsentFragment
                    }
                }
            }
        }
    }
`;

const LazyLoadedBanner: React.FC<{ data: UniversalNavigationReferralData }> = ({ data }) => {
    if (!isBrowser) {
        return null;
    }
    return (
        <Suspense fallback={null}>
            <ReferralsBanner data={data} />
        </Suspense>
    );
};

export const Layout: React.FC<LayoutProps> = ({
    children,
    lang,
    alternateLanguages,
    options,
    pageContext,
    homePage,
    hasGivenCookieConsent,
    onAgreeToCookieConsent,
}) => {
    const render = ({
        prismic: {
            allUniversal_navigations,
            allUniversal_navigation_item_dropdowns,
            allMain_footers,
            allCookie_consents,
        },
    }: LayoutData) => {
        const universalNavData = options.show_navigation_header && getDataForLang(allUniversal_navigations, lang);
        const universalNavDropdownDocumentsData = options.show_navigation_header
            ? allUniversal_navigation_item_dropdowns.edges.map((item) => item.node)
            : [];
        const mainFooterData = options.show_footer && getDataForLang(allMain_footers, lang);
        const cookieConsentData = !hasGivenCookieConsent && getDataForLang(allCookie_consents, lang);

        return (
            <SiteLayout className="Site">
                <SiteLayout.TopBar>
                    {universalNavData && <LazyLoadedBanner data={universalNavData} />}
                </SiteLayout.TopBar>

                <SiteLayout.Header sx={{ position: 'relative' }}>
                    {universalNavData && (
                        <UniversalNavigation
                            pageContext={pageContext}
                            homePage={homePage}
                            universalNavigationData={universalNavData}
                            universalNavDropdownDocumentsData={universalNavDropdownDocumentsData}
                            headerType={options.header_type}
                            partnerLogo={options.partner_logo}
                            partnerUrl={options.partner_url}
                            lang={lang}
                        />
                    )}
                </SiteLayout.Header>
                <SiteLayout.Content className="Site-body">{children}</SiteLayout.Content>
                <SiteLayout.Footer>
                    {mainFooterData && (
                        <MainFooter
                            mainFooterData={mainFooterData}
                            alternateLanguages={alternateLanguages}
                            lang={lang}
                        />
                    )}
                    {cookieConsentData && (
                        <CookieConsent data={cookieConsentData} onAgreeToCookieConsent={onAgreeToCookieConsent} />
                    )}
                </SiteLayout.Footer>
            </SiteLayout>
        );
    };

    return (
        <StaticQuery
            query={`${LayoutQuery}`}
            render={
                // Use withPreview ONLY when preview is active, as it makes the navigation flicker
                isPreviewActive()
                    ? (withPreview(render, LayoutQuery, [
                          MainFooterFragment,
                          CookieConsentFragment,
                          MetaFragment,
                          LinkFragments,
                          ...PrismicUniversalNavigationFragments,
                      ]) as any)
                    : render
            }
        />
    );
};
