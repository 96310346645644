import { graphql } from 'gatsby';

export const TextGridSliceFragment = graphql`
    fragment PrismicTextGridFragment on PRISMIC_PageBodyText_grid {
        type
        primary {
            tg_paddingtop
            tg_paddingbottom
            text_grid_number_of_columns
            grid_title
            text_grid_show_cta
            text_grid_cta_label
            text_grid_slice_anchor
            text_grid_cta_link {
                ...LinkFragment
            }
        }
        fields {
            text_grid_title
            text_grid_description
            text_grid_logo
            text_grid_image_cta_url {
                ...LinkFragment
            }
            text_grid_vertical_logo_title
            text_grid_cta_type
            text_grid_cta_label
            text_grid_cta {
                ...LinkFragment
            }
            text_grid_full_image
            text_grid_full_imageSharp {
                ...FluidImageFragment
            }
            text_grid_hover_video_id
        }
    }
`;
