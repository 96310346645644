export type PageContextType = {
    id: string;
    type: string;
    lang: string;
    uid: string;
    url: string;
};

export type Edge<T> = {
    node: T;
};

export type Edges<T> = {
    edges: Edge<T>[];
};

export enum PrismicLocalesEnum {
    DeDe = 'de-de',
    EnUs = 'en-us',
    EsEs = 'es-es',
    FrFr = 'fr-fr',
    ItIt = 'it-it',
    JaJp = 'ja-jp',
    PtPt = 'pt-pt',
}

export const PrismicLocales = [
    PrismicLocalesEnum.DeDe,
    PrismicLocalesEnum.EnUs,
    PrismicLocalesEnum.EsEs,
    PrismicLocalesEnum.FrFr,
    PrismicLocalesEnum.ItIt,
    PrismicLocalesEnum.JaJp,
    PrismicLocalesEnum.PtPt,
];

export enum SupportedLanguages {
    de = 'de',
    en = 'en',
    es = 'es',
    fr = 'fr',
    it = 'it',
    ja = 'ja',
    pt = 'pt',
}

export const supportedHomePages: string[] = Object.values(SupportedLanguages).map(
    (languageAbbreviation) => `home-${languageAbbreviation}`,
);

export const languagesListCopyMapping: {
    [key: string]: string;
} = {
    en: `English`,
    fr: `Français`,
    es: `Español`,
    ja: `日本語`,
    de: `Deutsch`,
    pt: `Português`,
    it: `Italiano`,
};

export type AlternateLanguage = {
    lang: string;
    uid: string;
    type: string;
    id: string;
};

export interface Jsonp extends jest.Mock {
    _mockError: (message: string) => void;
    _mockSuccess: (data: any) => void;
}

// Many of these supported params are listed in the Registration Form repository
// Might be worth story sharing the values between the registration form and
// the guest site through the core models repo
export type SupportedURLParams = {
    availableAuthenticationMethods?: string;
    callToActionChoiceEmail?: string;
    callToActionChoiceFacebook?: string;
    callToActionEmailLogin?: string;
    callToActionEmailSubmit?: string;
    collaborationCode?: string;
    couponCode?: string;
    homePage?: string;
    isSignup?: string;
    locale?: string;
    mode?: string;
    partner?: string;
    plan?: string;
    planFrequency?: string;
    promoCode?: string;
    referralCode?: string;
    returnUrl?: string;
    showRememberMe?: string;
    switchToLoginCta?: string;
    switchToLoginText?: string;
    switchToRegisterCta?: string;
    switchToRegisterText?: string;
    tenant?: string;
    theme?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_source?: string;
    utm_term?: string;
};

export type Price = {
    amount: number;
    currencyCode: string;
    currencySymbol: string;
    yearlyDiscountPercent: number;
    yearlyDiscount: number;
};
