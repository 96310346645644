import React from 'react';
import { StyledListItem } from 'components/UniversalNavigation/styled';
import {
    NavigationDropdownItem,
    NavigationDropdownItemData,
    NavigationDropdownItemDocumentData,
    NavigationSingleItem,
    NavigationSingleItemData,
} from '../';

interface NavigationItemsProps {
    menuItems: (NavigationDropdownItemData | NavigationSingleItemData)[];
    universalNavDropdownDocumentsData: NavigationDropdownItemDocumentData[];
    shouldListItemShrink: boolean;
    onClick: () => void;
}

export const NavigationItems: React.FC<NavigationItemsProps> = ({
    menuItems,
    universalNavDropdownDocumentsData,
    shouldListItemShrink,
    onClick,
}) => {
    return (
        <>
            {menuItems.map((menuItem, index) => {
                if (menuItem.type === 'navigation_dropdown_item') {
                    return (
                        <React.Fragment key={index}>
                            <NavigationDropdownItem
                                key={index}
                                data={menuItem}
                                allDropdownDocuments={universalNavDropdownDocumentsData}
                                shouldListItemShrink={shouldListItemShrink}
                                onClick={onClick}
                            />
                        </React.Fragment>
                    );
                } else {
                    return (
                        <StyledListItem
                            shouldShrink={shouldListItemShrink}
                            key={index}
                            className="MainNavigation-item"
                            onClick={onClick}
                        >
                            <NavigationSingleItem navigationSingleItem={menuItem} />
                        </StyledListItem>
                    );
                }
            })}
        </>
    );
};
