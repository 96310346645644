import { graphql } from 'gatsby';

export const HeroSliceFragment = graphql`
    fragment PrismicHeroFragment on PRISMIC_PageBodyHero {
        type
        primary {
            hero_title
            hero_description
            hero_alignment
            hero_image
            hero_imageSharp {
                ...FluidImageFragment
            }
            hero_cta_kind
            hero_cta_label
            hero_cta_link {
                ...LinkFragment
            }
            hero_cta_kind_secondary
            hero_cta_label_secondary
            hero_cta_link_secondary {
                ...LinkFragment
            }
            hero_cta_label_authenticated
            hero_cta_link_authenticated {
                ...LinkFragment
            }
            hero_show_arrow
        }
    }
`;
