import React from 'react';
import { Container } from 'components/UI/Container/Container';
import { Grid, GridRow, GridCol } from 'components/UI/Grid';
import { PrismicHTML, PrismicExactImage } from 'components/Prismic';
import { prismicText } from 'helpers';
import { PrismicTitleFieldType, PrismicRichTextFieldType, PrismicImageField } from '../types';
import './LogoList.scss';
import { SupportedPrismicSlicesEnum } from '../enums';

type LogoListProps = {
    slice: {
        type: SupportedPrismicSlicesEnum.logoList;
        primary: {
            logo_list_title: PrismicTitleFieldType;
            logo_list_more_text: PrismicRichTextFieldType;
        };
        fields: Array<{
            logo_list_image: PrismicImageField;
            logo_list_image_alt: PrismicRichTextFieldType;
        }>;
    };
};

class LogoList extends React.Component<LogoListProps> {
    render() {
        const { primary, fields } = this.props.slice;

        const logoItems = fields.map((logo, logoIndex) => {
            return (
                <GridCol colSize={`2`} mobileColSize={`6`} key={logoIndex}>
                    <div className="LogoList-item" key={logoIndex}>
                        <PrismicExactImage image={logo.logo_list_image} alt={prismicText(logo.logo_list_image_alt)} />
                    </div>
                </GridCol>
            );
        });

        return (
            <Container className="LogoList" size="md">
                <Grid>
                    <PrismicHTML content={primary.logo_list_title} className="LogoList-title" />
                    <GridRow horizontalAlign={'h-around'} verticalAlign={'v-center'} className="LogoList-list">
                        {logoItems}
                    </GridRow>
                    <GridRow horizontalAlign={'h-around'} verticalAlign={'v-center'} className="LogoList-list">
                        {primary.logo_list_more_text && (
                            // TODO: Fix improper HTML. li must always be a direct child of a ul
                            <li className="LogoList-item">
                                <GridCol colSize={'12'}>
                                    <PrismicHTML content={primary.logo_list_more_text} className="LogoList-moreText" />
                                </GridCol>
                            </li>
                        )}
                    </GridRow>
                </Grid>
            </Container>
        );
    }
}

export { LogoList, LogoListProps };
