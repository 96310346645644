import styled from 'styled-components';
import { Box } from '@landr/maestro';
import { spacing } from '@landr/maestro-legacy';

export const StyledPricingContentBox = styled(Box)<{ isCentered?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding-right: 0%;
    padding-left: 0%;
    padding-bottom: ${({ theme }) => theme.baseUnit(24)};
    ${({ isCentered }) => isCentered && `text-align: center;`};
    ${({ theme }) => theme.breakpoint('xl')`
        padding-right: 5%;
        padding-left: 5%;
    `}
    ${({ theme }) => theme.breakpoint('md')`
        padding: ${spacing('md')};
        padding-bottom: ${theme.baseUnit(18)};
    `};
`;
