import React, { Ref, useEffect, useRef, useState } from 'react';
import { classNames } from 'react-extras';
import { Grid, GridRow, GridCol } from 'components/UI/Grid';
import { PrismicLink } from 'components/PrismicLink';
import { LrOverlay } from 'components/LANDR/LrOverlay';
import { LrLogo } from 'components/Icons/LrLogo';
import { homepageLink } from 'helpers';
import { PrismicExactImage } from 'components/Prismic';
import { PrismicImageField, PrismicLinkFieldType } from 'components/Slices/types';
import { useBreakpoint } from '@landr/maestro';
import { LogoKebab, LogoClose, GoToApp } from './components';
import './CommonNavigation.scss';

interface CommonNavigationProps {
    isMobileNavOpen: boolean;
    goToAppLabel?: string;
    mobileNav?: Ref<HTMLInputElement>;
    children?: React.ReactNode;
    headerType: string | null;
    languageCode: string;
    partnerLogo: PrismicImageField;
    partnerUrl: PrismicLinkFieldType;
    toggleMobileMenu: () => void;
}

export const CommonNavigation: React.FC<CommonNavigationProps> = ({
    isMobileNavOpen = false,
    headerType,
    languageCode,
    partnerLogo,
    partnerUrl,
    toggleMobileMenu,
    goToAppLabel = 'Go to app',
    children,
}) => {
    const navElement = useRef<HTMLDivElement>(null);
    const navMobileElement = useRef<HTMLDivElement>(null);

    const [isFixed, setIsFixed] = useState(false);
    const [isAnchored, setIsAnchored] = useState<boolean>(false);

    const isTablet = useBreakpoint('lg');

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scrollOffset =
            (navElement.current && navElement.current.clientHeight) ||
            (navMobileElement.current && navMobileElement.current.clientHeight);

        if (scrollOffset) {
            const { scrollTop } = document.documentElement;
            // NOTE: State values will not change in "handleScroll" function even thought they will be toggled
            const shouldFix = scrollTop > scrollOffset && (process.env.NODE_ENV === 'test' ? true : !isFixed);
            const shouldUnfix = scrollTop <= scrollOffset && (process.env.NODE_ENV === 'test' ? true : isFixed);
            const shouldReset = scrollTop === 0;

            if (shouldReset) {
                return resetMenu();
            }
            if (shouldFix) {
                return fixMenu();
            }
            if (shouldUnfix) {
                return unfixMenu();
            }
        }
    };

    const fixMenu = () => {
        setIsFixed(true);
        setIsAnchored(false);
    };

    const unfixMenu = () => {
        setIsFixed(true);
        setIsAnchored(true);
    };

    const resetMenu = () => {
        setIsFixed(false);
        setIsAnchored(false);
    };

    useEffect(() => {
        if (isMobileNavOpen) {
            document.documentElement.classList.add('is-mainNavigation-open');
        } else {
            document.documentElement.classList.remove('is-mainNavigation-open');
        }
    }, [isMobileNavOpen]);

    return (
        <div
            className={classNames('MainNavigation', {
                'is-opened': isMobileNavOpen,
                'is-anchored': isAnchored,
                'is-fixed': isFixed,
            })}
            ref={navElement}
        >
            <Grid className="MainNavigation-content">
                <GridRow horizontalAlign="h-between" verticalAlign="v-center" className="Header-content">
                    <GridCol colSize={headerType !== 'PartnerHeader' ? '2' : '6'} offsetSize="0">
                        <PrismicLink link={homepageLink(languageCode)} className="MainNavigation-logo">
                            <LrLogo />
                        </PrismicLink>
                        {headerType === 'PartnerHeader' && partnerLogo !== null && (
                            <>
                                <div className="MainNavigation-spacer">&times;</div>
                                {partnerUrl !== null ? (
                                    <PrismicLink link={partnerUrl} className="MainNavigation-partnerLogo">
                                        <PrismicExactImage image={partnerLogo} width="150px" />
                                    </PrismicLink>
                                ) : (
                                    <div className="MainNavigation-partnerLogo noCta">
                                        <PrismicExactImage image={partnerLogo} width="150px" />
                                    </div>
                                )}
                            </>
                        )}
                    </GridCol>
                    <button className="MainNavigation-toggle" onClick={toggleMobileMenu}>
                        <LogoClose />
                    </button>
                    <GridCol
                        colSize={headerType !== 'PartnerHeader' ? '10' : '6'}
                        mobileColSize="12"
                        tabletColSize="12"
                        offsetSize="0"
                    >
                        {headerType !== 'OnlyLogo' && children}
                    </GridCol>
                </GridRow>
            </Grid>
            {isTablet && (
                <div className="MainNavigation-mobileContent" ref={navMobileElement}>
                    <Grid>
                        <GridRow horizontalAlign="h-between" verticalAlign="v-center" className="Header-content">
                            <GridCol
                                colSize={headerType === 'PartnerHeader' ? '8' : '4'}
                                mobileColSize={headerType === 'PartnerHeader' ? '10' : '8'}
                                offsetSize="0"
                            >
                                <PrismicLink link={homepageLink(languageCode)} className="MainNavigation-logo">
                                    <LrLogo />
                                </PrismicLink>
                                {headerType === 'PartnerHeader' && partnerLogo !== null && (
                                    <>
                                        <div className="MainNavigation-spacer">&times;</div>
                                        {partnerUrl !== null ? (
                                            <PrismicLink link={partnerUrl} className="MainNavigation-partnerLogo">
                                                <PrismicExactImage image={partnerLogo} width="120px" />
                                            </PrismicLink>
                                        ) : (
                                            <div className="MainNavigation-partnerLogo noCta">
                                                <PrismicExactImage image={partnerLogo} width="120px" />
                                            </div>
                                        )}
                                    </>
                                )}
                            </GridCol>
                            {headerType !== 'OnlyLogo' && (
                                <GridCol
                                    colSize={headerType === 'PartnerHeader' ? '4' : '8'}
                                    mobileColSize={headerType === 'PartnerHeader' ? '2' : '4'}
                                    offsetSize="0"
                                >
                                    <ul className="MainNavigation-mobileContent-items">
                                        <li
                                            className={`MainNavigation-mobileContent-item ${
                                                headerType === 'PartnerHeader' ? 'SignUpPartner' : ''
                                            }`}
                                        >
                                            <GoToApp goToAppLabel={goToAppLabel} />
                                        </li>
                                        {headerType !== 'PartnerHeader' && (
                                            <li className="MainNavigation-mobileContent-item">
                                                <button onClick={toggleMobileMenu}>
                                                    <LogoKebab />
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </GridCol>
                            )}
                        </GridRow>
                    </Grid>
                </div>
            )}

            <div className="MainNavigation-mobileOverlay" onClick={toggleMobileMenu}>
                <LrOverlay />
            </div>
        </div>
    );
};
