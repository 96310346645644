import { graphql } from 'gatsby';

export const UniversalNavigationSingleItemFragment = graphql`
    fragment UniversalNavigationSingleItemFragment on PRISMIC_Universal_navigationBodyNavigation_single_item {
        primary {
            label
            link {
                ...LinkFragment
            }
        }
    }
`;
