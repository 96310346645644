import { PrismicText } from 'components/Prismic';
import { Heading } from 'components/LANDR/Heading';
import React from 'react';
import { PrismicTitleFieldType } from 'components/Slices/types';

export const BlogTitle: React.FC<{ title: PrismicTitleFieldType }> = ({ title }) => {
    return (
        <Heading className="Blog-title" size="xl">
            <PrismicText content={title} />
        </Heading>
    );
};
