import { SizeEnum } from 'components/UI/Container/Container';
import { PrismicRichTextFieldType, PrismicFluidImageField, PrismicImageField } from '../types';
import { SupportedPrismicSlicesEnum } from '../enums';

export enum ImageTypes {
    fullscreen = 'Full screen',
    boxed = 'Boxed',
    two_images = 'Two images',
}

export type VisualImages = {
    images: VisualImage[];
    paddingSize: SizeEnum | null;
    paddingBottomOverride: SizeEnum | null;
};

export type VisualImage = {
    visual_image: PrismicImageField;
    visual_imageSharp: PrismicFluidImageField;
    visual_image_atl: PrismicRichTextFieldType;
    visual_image_description: PrismicRichTextFieldType;
};

export type VisualProps = {
    slice: {
        type: SupportedPrismicSlicesEnum.visual;
        primary: {
            v_paddingtop: SizeEnum | null;
            v_paddingbottom: SizeEnum | null;
            visual_image_type: ImageTypes;
        };
        fields: VisualImage[];
    };
};
