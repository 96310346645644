import React from 'react';
import { Button, ButtonProps } from '@landr/maestro';
import { PrismicLinkTypes } from 'components/Slices/enums';
import { PrismicLinkFieldType, SliceContext } from 'components/Slices/types';
import { InternalLink } from 'components/InternalLink';
import { ExternalLink } from 'components/ExternalLink';

export interface PrismicButtonLinkProps extends ButtonProps {
    label?: string | null;
    link: PrismicLinkFieldType;
    slice?: SliceContext;
    className?: string;
}

export const PrismicButtonLink: React.FC<PrismicButtonLinkProps> = ({ children, link, ...props }) => {
    if (!link) {
        return null;
    }

    if (link._linkType === PrismicLinkTypes.internal) {
        return (
            <InternalLink link={link} useMaestro maestroComponent={Button} {...props}>
                {children}
            </InternalLink>
        );
    }

    return (
        <ExternalLink link={link} useMaestro maestroComponent={Button} {...props}>
            {children}
        </ExternalLink>
    );
};
