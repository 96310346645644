import { PrismicText } from 'components/Prismic';
import React from 'react';
import { PrismicExternalLink, PrismicInternalLink, SliceContext } from 'components/Slices/types';
import { PrismicLink } from 'components/PrismicLink';
import { Arrow } from 'components/Icons';

export const BlogLink: React.FC<{
    blogUrl: PrismicInternalLink | PrismicExternalLink;
    sliceContext: SliceContext;
    linkText: string | Record<string, unknown> | [Record<string, unknown>];
}> = ({ blogUrl, sliceContext, linkText }) => {
    return (
        <div className="Blog-linkContainer">
            <PrismicLink link={blogUrl} className="Blog-cta-link" slice={sliceContext}>
                <div className="Grid Grid--alignCenter">
                    <p>
                        <PrismicText content={linkText} />
                        <Arrow className="Blog-cta-arrow" />
                    </p>
                </div>
            </PrismicLink>
        </div>
    );
};
