import styled, { css } from 'styled-components';
import { spacing } from '@landr/maestro-legacy';

export const StyledDiv = styled.div<{ isTablet: boolean }>`
    display: flex;
    ${({ isTablet }) => {
        if (!isTablet) {
            return css`
                justify-content: flex-end;
            `;
        }
    }}
`;

StyledDiv.displayName = 'StyledDiv';

export const StyledList = styled.ul`
    width: fit-content;
    position: relative;
`;

StyledList.displayName = 'StyledList';

export const StyledListItem = styled.li<{ shouldShrink: boolean }>`
    ${({ shouldShrink }) => {
        if (shouldShrink) {
            return css`
                margin-left: ${spacing('sm')};
            `;
        }
    }}
`;

StyledListItem.displayName = 'StyledListItem';
