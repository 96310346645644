export enum SupportedPrismicSlicesEnum {
    blog = 'blog',
    cta = 'cta',
    faq = 'faq',
    feature = 'feature',
    feature_video = 'feature_video',
    cart = 'cart',
    featured_artists = 'featured_artists',
    hero = 'hero',
    hero_video = 'hero_video',
    logoList = 'logo_list',
    mastering_demos = 'mastering_demos',
    pressList = 'press_list',
    pricing = 'pricing',
    quotes = 'quotes',
    quotes_video = 'quotes_-_video',
    reset_password = 'reset_password',
    signup = 'signup',
    textBlock = 'text_block',
    text_grid = 'text_grid',
    videoplayer = 'videoplayer',
    visual = 'visual',
    linklist = 'linklist',
    chromatic_pricing = 'chromatic_pricing',
    royalty_calculator = 'royalty_calculator',
}

export enum PrismicLinkTypes {
    external = 'Link.web',
    internal = 'Link.document',
}
