import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ArrowForwardOutlined, Box, useBreakpoint } from '@landr/maestro';
import { ArrowHeroCta } from 'components/Icons/ArrowHeroCta';
import { PrismicButton, getIsSolidButtonKind } from 'components/PrismicButton';
import { GridRow, GridCol } from 'components/UI/Grid';
import { Container } from 'components/UI/Container/Container';
import { PrismicGradientTitleHTML, PrismicHTML } from 'components/Prismic';
import { useAuthentication } from 'components/Authentication';
import { BaseHeroSliceProps } from './types';
import { componentContentAnimation } from './Animations';
import { ActionButtonsWrapper, ArrowWrapper, DynamicCTAWrapper, HeroContent, HeroWrapper, TextWrapper } from './styled';

const StyledContainer = styled(Container)`
    margin-top: 0 !important;
`;

export const BaseHeroSlice: React.FC<BaseHeroSliceProps> = ({
    title,
    titleGradientColorTop,
    titleGradientColorBottom,
    description,
    alignment,
    ctaKind,
    ctaLabel,
    ctaLink,
    secondaryCtaKind,
    secondaryCtaLabel,
    secondaryCtaLink,
    authenticatedCtaLabel,
    authenticatedCtaLink,
    showArrow,
    sliceContext,
    isHeroVideo,
    children,
}) => {
    const isMobile = useBreakpoint('md');
    const { isAuthenticated } = useAuthentication();

    const heroElement = useRef<HTMLDivElement>(null);
    const heroTitle = useRef<HTMLDivElement>(null);
    const heroDescription = useRef<HTMLDivElement>(null);
    const heroActionsWrapper = useRef<HTMLDivElement>(null);
    const heroArrowWrapper = useRef<any>(null);

    const hasBothLinks = !!secondaryCtaLink && !!ctaLink;
    const primaryDefaultIcon = !getIsSolidButtonKind(ctaKind) ? ArrowHeroCta : undefined;
    const secondaryDefaultIcon = !getIsSolidButtonKind(secondaryCtaKind) ? ArrowHeroCta : undefined;

    const smoothScroll = (top: number) =>
        window.scrollTo({
            top,
            behavior: 'smooth',
        });

    const scrollPastHero = () => {
        if (heroElement && heroElement.current) {
            // The navbar has a different height on mobile than it is for desktop
            const navbarHeight = window.innerWidth <= 1024 ? 64 : 80;
            smoothScroll(heroElement.current.offsetTop + heroElement.current.offsetHeight - navbarHeight);
        }
    };

    useEffect(() => {
        componentContentAnimation(heroTitle, heroDescription, heroActionsWrapper, heroArrowWrapper);
    }, []);

    return (
        <StyledContainer className="BaseHeroSlice-container" size={'none'}>
            <HeroWrapper alignment={alignment} isHeroVideo={isHeroVideo} ref={heroElement}>
                <HeroContent>
                    <GridRow horizontalAlign={'h-start'} verticalAlign={'v-center'}>
                        <GridCol colSize={`12`} offsetSize={'0'}>
                            <TextWrapper
                                as={'span'}
                                paddingTop={'120px'}
                                alignment={alignment}
                                maxWidth={isMobile ? '96%' : '680px'}
                                ref={heroTitle}
                            >
                                {titleGradientColorTop && titleGradientColorBottom ? (
                                    <PrismicGradientTitleHTML
                                        content={title}
                                        gradientRange={[titleGradientColorTop, titleGradientColorBottom]}
                                    />
                                ) : (
                                    <PrismicHTML content={title} />
                                )}
                            </TextWrapper>
                            <TextWrapper
                                as={'span'}
                                paddingTop={'0px'}
                                alignment={alignment}
                                isDescription={true}
                                maxWidth={isMobile ? '96%' : '680px'}
                                ref={heroDescription}
                            >
                                <PrismicHTML content={description} />
                            </TextWrapper>
                        </GridCol>
                        <GridCol colSize={`12`} offsetSize={'0'}>
                            <ActionButtonsWrapper alignment={alignment} ref={heroActionsWrapper}>
                                <GridRow
                                    horizontalAlign={alignment === 'Left' ? 'h-start' : 'h-center'}
                                    verticalAlign={'v-center'}
                                    className="Row--marginNone"
                                >
                                    <GridCol mobileColSize={'12'} offsetSize={'0'} className="Col--paddingNone">
                                        {ctaLink && (
                                            <PrismicButton
                                                ctaKind={ctaKind}
                                                link={ctaLink}
                                                sliceContext={sliceContext}
                                                label={ctaLabel}
                                                size="lg"
                                                defaultIcon={primaryDefaultIcon}
                                            />
                                        )}
                                    </GridCol>
                                    <GridCol mobileColSize={'12'} offsetSize={'0'} className="paddingNone">
                                        {secondaryCtaLink && (
                                            <Box
                                                paddingLeft={hasBothLinks && !isMobile ? '20px' : '0px'}
                                                paddingTop={isMobile ? '10px' : '0px'}
                                            >
                                                <PrismicButton
                                                    ctaKind={secondaryCtaKind}
                                                    link={secondaryCtaLink}
                                                    sliceContext={sliceContext}
                                                    label={secondaryCtaLabel}
                                                    size="lg"
                                                    defaultIcon={secondaryDefaultIcon}
                                                />
                                            </Box>
                                        )}
                                    </GridCol>
                                </GridRow>
                                {/* This wrapper prevent flickering while the isAuthenticated is verified */}
                                <DynamicCTAWrapper
                                    justifyContent={alignment === 'Left' ? 'flex-start' : 'center'}
                                    alignContent={'center'}
                                    flexWrap="wrap"
                                    pt={authenticatedCtaLink ? '25px' : '0px'}
                                    height={authenticatedCtaLink ? 'auto' : '0px'}
                                    minHeight={authenticatedCtaLink ? '55px' : '0px'}
                                    opacity={isAuthenticated && authenticatedCtaLink ? '1' : '0'}
                                >
                                    {isAuthenticated && authenticatedCtaLink && (
                                        <Box paddingLeft={'0px'} paddingTop={isMobile ? '10px' : '0px'}>
                                            <PrismicButton
                                                ctaKind={'link'}
                                                link={authenticatedCtaLink}
                                                sliceContext={sliceContext}
                                                label={authenticatedCtaLabel}
                                                size="lg"
                                                defaultIcon={null}
                                            />
                                        </Box>
                                    )}
                                </DynamicCTAWrapper>
                                {showArrow === 'Yes' && (
                                    <ArrowWrapper
                                        alignment={alignment}
                                        onClick={scrollPastHero}
                                        data-testid="heroArrow"
                                        ref={heroArrowWrapper}
                                        kind="link"
                                        variant="secondary"
                                        width={40}
                                        height={40}
                                        padding={0}
                                    >
                                        <ArrowForwardOutlined size="lg" />
                                    </ArrowWrapper>
                                )}
                            </ActionButtonsWrapper>
                        </GridCol>
                    </GridRow>
                </HeroContent>
                {children}
            </HeroWrapper>
        </StyledContainer>
    );
};
