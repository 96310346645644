import React from 'react';
import './Quotes.scss';
import { PrismicHTML, PrismicFluidImage } from 'components/Prismic';
import { Container } from '../../UI/Container/Container';
import { Carousel } from '../../UI/Carousel';
import { Arrow } from '../../Icons';
import { Grid, GridRow, GridCol } from '../../UI/Grid';
import { PrismicRichTextFieldType, PrismicImageField, PrismicFluidImageField } from '../types';
import { SupportedPrismicSlicesEnum } from '../enums';

type Quote = {
    quotes_text: PrismicRichTextFieldType;
    quotes_image: PrismicImageField;
    quotes_imageSharp: PrismicFluidImageField;
    quotes_author: string | null;
    quotes_description: string | null;
};

type QuotesProps = {
    type: SupportedPrismicSlicesEnum.quotes;
    slice: {
        fields: Quote[];
    };
};

const Quote = ({ quote }: { quote: Quote }) => {
    return (
        <div className="Quotes-item">
            <PrismicHTML content={quote.quotes_text} className="Quotes-item-text" />
            <div className="Quotes-item-author">
                <PrismicFluidImage
                    image={quote.quotes_imageSharp}
                    fallback={quote.quotes_image}
                    alt={quote.quotes_author || undefined}
                    className="Quotes-item-authorImage"
                />
                <div>
                    <span className="Quotes-item-authorName">{quote.quotes_author}</span>
                    <span className="Quotes-item-authorDescription">{quote.quotes_description}</span>
                </div>
            </div>
        </div>
    );
};

const Quotes = ({ slice }: QuotesProps) => {
    const { fields } = slice;
    return (
        <Container size="md">
            <Grid className="Quotes">
                {fields.length > 1 ? (
                    <Carousel
                        theme="dark"
                        carouselConfig={{
                            autoplay: false,
                            slidesToShow: 1,
                            wrapAround: true,
                            renderCenterLeftControls: ({ previousSlide }) => (
                                <button
                                    onClick={previousSlide}
                                    className="Quotes-carouselButton Quotes-carouselButton--left"
                                    aria-label="Go to previous slide"
                                >
                                    <Arrow size="large" />
                                </button>
                            ),
                            renderCenterRightControls: ({ nextSlide }) => (
                                <button
                                    onClick={nextSlide}
                                    className="Quotes-carouselButton Quotes-carouselButton--right"
                                    aria-label="Go to next slide"
                                >
                                    <Arrow size="large" />
                                </button>
                            ),
                        }}
                    >
                        {fields.map((quote: Quote, i: number) => (
                            <Container size="lg" key={i}>
                                <GridRow horizontalAlign={'h-start'} verticalAlign={'v-stretch'}>
                                    <GridCol colSize={'12'} offsetSize={`0`} mobileColSize={'12'}>
                                        <Quote quote={quote} />
                                    </GridCol>
                                </GridRow>
                            </Container>
                        ))}
                    </Carousel>
                ) : (
                    fields.map((quote: Quote, i: number) => (
                        <Container size="lg" key={i}>
                            <GridRow horizontalAlign={'h-start'} verticalAlign={'v-stretch'}>
                                <GridCol colSize={'12'} offsetSize={`0`} mobileColSize={'12'}>
                                    <Quote quote={quote} />
                                </GridCol>
                            </GridRow>
                        </Container>
                    ))
                )}
            </Grid>
        </Container>
    );
};

export { Quotes, QuotesProps, Quote };
