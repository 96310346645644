import { PrismicLocales, SupportedLanguages } from '../types';

export const getTwoLetterLanguageCode = (
    languageCode: string,
    fallbackLanguage: SupportedLanguages = SupportedLanguages.en,
): string => {
    const twoLetterLanguageAbbreviation: string = languageCode ? languageCode.substring(0, 2) : '';
    return Object.keys(SupportedLanguages).includes(twoLetterLanguageAbbreviation)
        ? twoLetterLanguageAbbreviation
        : fallbackLanguage;
};

export const getPrismicLanguageCode = (shortLang: string | null) => {
    if (!shortLang) {
        return 'en-us';
    }
    return PrismicLocales.find((prismicLang) => prismicLang.indexOf(shortLang) === 0) || 'en-us';
};

export const getLanguageFromLocation = (location: Location) => {
    const lang = location.pathname.split('/')[1] || '';
    if (Object.values(SupportedLanguages).includes(lang as SupportedLanguages)) {
        return lang;
    }
    return 'en';
};
