import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AlternateLanguage, PageContextType } from 'src/types';
import { PageType } from 'templates/types';

export type AppContextType = {
    pageContext: PageContextType;
    alternateLanguages: AlternateLanguage[];
    options: {
        page_type: PageType;
        show_navigation_header: boolean;
        show_footer: boolean;
    };
    homePage: string | null;
    setHomePage: (value: string) => void;
    resetHomePage: () => void;
};

export const AppContext = React.createContext<AppContextType>({
    alternateLanguages: [{ id: '', type: '', lang: '', uid: '' }],
    homePage: '',
    options: { page_type: 'Default', show_navigation_header: false as boolean, show_footer: false as boolean },
    pageContext: { id: '', type: '', lang: '', uid: '', url: '' },
    setHomePage: (_homePage: string): void => undefined,
    resetHomePage: (): void => undefined,
});

export type AppContextProviderProps = Omit<AppContextType, 'setHomePage' | 'resetHomePage'>;

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
    pageContext,
    alternateLanguages,
    options,
    homePage: defaultHomePage,
    children,
}) => {
    const [homePage, setHomePage] = useState<string | null>(defaultHomePage);
    const resetHomePage = useCallback(() => {
        setHomePage(defaultHomePage);
    }, [defaultHomePage]);

    useEffect(() => {
        setHomePage(defaultHomePage);
    }, [defaultHomePage]);

    return (
        <AppContext.Provider
            value={{
                pageContext,
                alternateLanguages,
                options,
                homePage,
                setHomePage,
                resetHomePage,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export function withAppContext<T extends { context: AppContextType }>(
    Component: React.ComponentType<T>,
): React.FC<Omit<T, 'context'>> {
    return (props: any) => (
        <AppContext.Consumer>{(context) => <Component {...props} context={context} />}</AppContext.Consumer>
    );
}

export const useAppContext = () => {
    return useContext(AppContext);
};
