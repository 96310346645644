import React from 'react';
export const LogoClose = () => (
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-134.000000, -22.000000)">
                <g transform="translate(128.000000, 16.000000)">
                    <rect
                        fillOpacity="0"
                        fill="#142739"
                        fillRule="nonzero"
                        x="0"
                        y="0"
                        width="32"
                        height="32"
                    />
                    <path
                        d="M7.66666667,24.3333333 L24.3333333,7.66666667"
                        stroke="#F9FAFB"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        transform="translate(16.000000, 16.000000) scale(-1, 1) translate(-16.000000, -16.000000) "
                    />
                    <path
                        d="M7.66666667,24.3333333 L24.3333333,7.66666667"
                        stroke="#F9FAFB"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    />
                </g>
            </g>
        </g>
    </svg>
);
