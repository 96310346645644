import React from 'react';
import { classNames } from 'react-extras';
import './Grid.scss';

type horizontalAlign_t = 'h-start' | 'h-center' | 'h-end' | 'h-around' | 'h-between';

type verticalAlign_t = 'v-center' | 'v-end' | 'v-stretch';

const GridRow = ({
    children,
    horizontalAlign,
    verticalAlign,
    className,
}: {
    children: React.ReactNode;
    horizontalAlign?: horizontalAlign_t;
    verticalAlign?: verticalAlign_t;
    className?: string;
}) => {
    const rowClasses = classNames('Row', {
        [`Row--${horizontalAlign}`]: horizontalAlign,
        [`Row--${verticalAlign}`]: verticalAlign,
    });

    return <div className={`${rowClasses} ${className || ''}`}>{children}</div>;
};

export { GridRow };
