import { graphql } from 'gatsby';

export const RoyaltyCalculatorSliceFragment = graphql`
    fragment RoyaltyCalculatorFragment on PRISMIC_PageBodyRoyalty_calculator {
        type
        primary {
            royalty_calculator_title
        }
    }
`;
