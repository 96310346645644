import React from 'react';
import { SupportedPrismicSlicesEnum } from 'components/Slices/enums';
import {
    CtaKind,
    CtaVariant,
    PrismicLinkFieldType,
    PrismicRichTextFieldType,
    PrismicTitleFieldType,
    SliceContext,
    VideoApiEnum,
    VideoSizeEnum,
} from 'components/Slices/types';
import { Positions } from 'components/BaseFeatureSlice/types';
import { Container } from 'components/UI/Container/Container';
import { BaseFeatureSlice } from 'components/BaseFeatureSlice';
import styled from 'styled-components';
import { VideoReactPlayer } from 'components/UI/VideoReactPlayer/VideoReactPlayer';

const StyledContainer = styled(Container)`
    max-height: 80vh;

    .Video-wrapper {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
    }

    .Iframe-wrapper {
        position: absolute;
        inset: 0;
    }
`;

export type FeatureVideoProps = {
    slice: {
        type: SupportedPrismicSlicesEnum.feature_video;
        primary: {
            feature_video_cta_kind: CtaKind;
            feature_video_eyebrow: PrismicTitleFieldType;
            feature_video_title: PrismicTitleFieldType;
            feature_video_position: Positions;
            feature_video_description: PrismicRichTextFieldType;
            feature_video_cta_label: PrismicTitleFieldType;
            feature_video_cta_link: PrismicLinkFieldType;
            feature_video_cta_variant: CtaVariant;
            feature_video_api: VideoApiEnum;
            feature_video_id: string;
            feature_video_size: VideoSizeEnum;
        };
    };
    sliceContext: SliceContext;
};

export const FeatureVideo: React.FC<FeatureVideoProps> = ({ slice, sliceContext }) => {
    const {
        primary: {
            feature_video_eyebrow: eyebrow,
            feature_video_title: title,
            feature_video_position: position,
            feature_video_description: description,
            feature_video_cta_label: ctaLabel,
            feature_video_cta_link: ctaLink,
            feature_video_cta_kind: ctaKind,
            feature_video_cta_variant: ctaVariant,
            feature_video_api: videoApi,
            feature_video_id: videoId,
        },
    } = slice;

    return (
        <BaseFeatureSlice
            eyeBrow={eyebrow}
            title={title}
            position={position}
            description={description}
            ctaLabel={ctaLabel}
            ctaLink={ctaLink}
            ctaKind={ctaKind}
            ctaVariant={ctaVariant}
            sliceContext={sliceContext}
        >
            <StyledContainer>
                <VideoReactPlayer
                    videoUrl={
                        videoApi === VideoApiEnum.Wistia
                            ? `https://home.wistia.com/medias/${videoId}`
                            : `https://www.youtube.com/embed/${videoId}`
                    }
                    hasControls={videoApi === VideoApiEnum.Wistia ? false : true}
                    isLooping={true}
                    isMuted={true}
                    isAutoPlaying={true}
                />
            </StyledContainer>
        </BaseFeatureSlice>
    );
};
