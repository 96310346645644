import React from 'react';
import styled from 'styled-components';
import { Container, Collapse, Heading, Flex, Text, ConditionalWrapper, Accordion, useBreakpoint } from '@landr/maestro';
import { spacing } from '@landr/maestro-legacy';
import { PrismicHTML } from '../../Prismic';
import { FaqSchema } from './FaqSchema';
import { FaqProps } from './types';

const StyledCollapseWithPadding = styled(Collapse)<{ isMobile: boolean }>`
    padding: ${({ isMobile }) => (isMobile ? 0 : spacing('md'))};
    svg {
        margin-top: ${({ isMobile }) => (isMobile ? 0 : spacing('xs'))};
    }
    p {
        margin-bottom: 0;
        margin-top: 0;
    }
`;

export const Faq: React.FC<FaqProps> = ({ slice }) => {
    const {
        primary: { note, title, allowMultipleOpenItems },
    } = slice;

    const isMobile = useBreakpoint('md');

    return (
        <Container size="lg" my="mg">
            {title && (
                <Heading textAlign="center">
                    <PrismicHTML content={title} styleClassName="Heading Heading--xl" />
                </Heading>
            )}
            <ConditionalWrapper
                condition={allowMultipleOpenItems === 'No'}
                wrapper={(children: React.ReactNode): React.ReactNode => <Accordion>{children}</Accordion>}
            >
                {slice.fields.map(
                    ({ question, answer }, index) =>
                        question &&
                        answer && (
                            <StyledCollapseWithPadding key={index} isMobile={isMobile}>
                                <Collapse.Title mb={0}>
                                    <Text size={isMobile ? 'lg' : 'xl'}>
                                        <PrismicHTML content={question} styleClassName="HeadingInherit" />
                                    </Text>
                                </Collapse.Title>
                                <Collapse.Content>
                                    <Text size={isMobile ? 'lg' : 'xl'} contrast="low">
                                        <PrismicHTML content={answer} />
                                    </Text>
                                </Collapse.Content>
                            </StyledCollapseWithPadding>
                        ),
                )}
            </ConditionalWrapper>
            <Flex flexDirection="row" justifyContent="center" mt={isMobile ? 'xxl' : 'mg'} textAlign="center">
                <Text contrast="low">
                    <PrismicHTML content={note} />
                </Text>
            </Flex>
            <FaqSchema slice={slice} />
        </Container>
    );
};
