import React, { Suspense } from 'react';
import { isBrowser } from '@landr/maestro';

export const DynamicIcon: React.FC<{ iconName?: string }> = ({ iconName }) => {
    const Icon = React.lazy(() =>
        import('@landr/maestro').then((module) => {
            if (!iconName || !(iconName in module.ProductIcon)) {
                iconName = 'ProjectsBlue' as keyof typeof module.ProductIcon;
            }
            return {
                default: module.ProductIcon[iconName as keyof typeof module.ProductIcon],
            };
        }),
    );

    return isBrowser ? (
        <Suspense fallback={null}>
            <Icon size="lg" />
        </Suspense>
    ) : null;
};
