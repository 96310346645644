import React from 'react';
import { PrismicText } from 'components/Prismic';
import { Arrow } from '../../Icons';
import { PrismicLink } from '../../PrismicLink';
import { PrismicTitleFieldType, PrismicLinkFieldType, SliceContext } from '../types';
import './FeaturedArtists.scss';

export type FeaturedArtistsCTAProps = {
    ctaLabel: PrismicTitleFieldType;
    ctaLink: PrismicLinkFieldType;
    sliceContext: SliceContext;
};

export const FeaturedArtistsCTA = ({ ctaLabel, ctaLink, sliceContext }: FeaturedArtistsCTAProps) => {
    if (!ctaLink || !ctaLabel) {
        return null;
    }

    return (
        <PrismicLink link={ctaLink} className="FeaturedArtists-cta-link" slice={sliceContext}>
            <div className="Grid Grid--alignCenter">
                <p>
                    <span>
                        <PrismicText content={ctaLabel} />
                    </span>
                    <span className="FeaturedArtists-cta-arrow">
                        <Arrow size="small" />
                    </span>
                </p>
            </div>
        </PrismicLink>
    );
};
