import styled, { css, keyframes } from 'styled-components';
import { Box, Button, Flex } from '@landr/maestro';
import { spacing, fontSize } from '@landr/maestro-legacy';
import { Grid } from 'components/UI/Grid';

export const HeroWrapper = styled(Box)<{ alignment: 'Left' | 'Center'; isHeroVideo: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    min-height: 96vh;
    text-align: ${({ alignment }) => (alignment === 'Left' ? 'left' : 'center')};
    margin-top: 0 !important;
    ${({ isHeroVideo }) =>
        isHeroVideo &&
        css`
            @media (max-height: '800px') {
                min-height: 96vh !important;
            }
            @media (min-width: 1024px) and (max-width: '1400px') {
                min-height: calc((100vw - 5rem) * 9 / 16) !important;
            }
        `}
`;

export const HeroContent = styled(Grid)`
    z-index: 10;
    position: relative;
`;

export const TextWrapper = styled(Box)<{ alignment: 'Left' | 'Center' | 'Right'; isDescription?: boolean }>`
    opacity: 0;
    transform: translateY(${spacing('lg')});
    display: flex;
    ${({ alignment }) =>
        (alignment === 'Center' || alignment === 'Right') &&
        css`
            margin: 0 auto;
            justify-content: center;
        `}
    ${({ isDescription }) =>
        isDescription &&
        css`
            font-size: ${fontSize('lg')};
            p {
                margin-bottom: ${spacing('lg')};
            }
            p:last-child {
                margin-bottom: 0;
            }
        `}
`;

export const ActionButtonsWrapper = styled(Box)<{ alignment: 'Left' | 'Center' }>`
    opacity: 0;
    transform: translateY(${spacing('lg')});
    justify-content: left;
    display: flex;
    max-width: 100%;
    padding: ${spacing('xxl')} 0;
    flex-direction: column;
    ${({ alignment }) =>
        alignment === 'Center' &&
        css`
            justify-content: center;
            :last-child {
                margin-left: initial;
            }
        `}
`;

export const ArrowAnimation = keyframes`
    0% { transform: translateY(0px); }
    50% { transform: translateY(10px); }
    100% { transform: translateY(0px); }
`;

export const ArrowWrapper = styled(Button)<{ alignment: 'Left' | 'Center' }>`
    display: flex;
    margin: ${spacing('lg')} auto;
    opacity: 1;
    cursor: pointer;
    animation: ${ArrowAnimation} 1.8s ease-out infinite;
    svg {
        height: 40px;
        width: 40px;
        transform: rotate(90deg);
    }

    ${({ alignment }) =>
        alignment === 'Left' &&
        css`
            bottom: 0;
            margin: ${spacing('lg')} 0 ${spacing('lg')} auto;
        `}
`;

export const DynamicCTAWrapper = styled(Flex)`
    transition: opacity 1s ease-in;
`;

export const StyledGradientTitle = styled.h1<{ titleGradientColorTop: 'string'; titleGradientColorBottom: 'string' }>`
    ${({ titleGradientColorTop, titleGradientColorBottom }) =>
        titleGradientColorTop &&
        titleGradientColorBottom &&
        css`
            background: -webkit-linear-gradient(titleGradientColorTop, titleGradientColorBottom);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        `}
`;
