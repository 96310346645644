import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby-link';
import { Select, SelectOption } from '@landr/maestro';
import { withAppContext, AppContextType } from 'context/AppContext';
import { getTwoLetterLanguageCode } from 'helpers/getTwoLetterLanguageCode';
import { isHomePage, getLangPathWithTrailingSlash, getPageTypeWithTrailingSlash } from 'helpers';
import { PageType } from 'templates/types';
import { languagesListCopyMapping, AlternateLanguage, PrismicLocales } from 'types';

const makeUrl = (lang: string, uid: string, pageType: PageType) => {
    return `/${getLangPathWithTrailingSlash(lang)}${getPageTypeWithTrailingSlash(pageType)}${uid}`;
};
const makeAHomeUrl = (lang: string) => `/${getLangPathWithTrailingSlash(lang)}`;
const getLanguageCopy = (lang: string): string => languagesListCopyMapping[getTwoLetterLanguageCode(lang)];

const createSelectData = (
    currentUid: string,
    alternateLanguages: AlternateLanguage[],
    pageType: PageType,
): SelectOption[] =>
    alternateLanguages.map((alternateLanguage) => {
        const value = isHomePage(currentUid)
            ? makeAHomeUrl(alternateLanguage.lang)
            : makeUrl(alternateLanguage.lang, alternateLanguage.uid, pageType);
        const text = getLanguageCopy(alternateLanguage.lang);
        return { value, text, key: alternateLanguage.lang };
    });

const LanguageSelector = withAppContext(
    ({
        context: {
            alternateLanguages,
            pageContext: { uid: currentUid, lang },
            options: { page_type },
        },
    }: {
        context: AppContextType;
    }) => {
        const currentLanguageCopy = getLanguageCopy(lang);
        const [options, setOptions] = useState<SelectOption[]>();

        const handleNavigate = (event: SelectOption) => {
            const url = event.value as string;
            // Keep query params when navigating
            navigate(`${url}${window.location.search}`);
        };
        useEffect(() => {
            const data = createSelectData(currentUid, alternateLanguages, page_type);

            const unsortedOptions = [
                {
                    key: lang,
                    text: currentLanguageCopy,
                    value: lang,
                },
                ...data,
            ];

            // Sort by language based on PrismicLocales order
            const sortedOptions = unsortedOptions.sort(
                (a, b) => PrismicLocales.indexOf(a.key) - PrismicLocales.indexOf(b.key),
            );
            setOptions(sortedOptions);
        }, [currentUid, lang, alternateLanguages, currentLanguageCopy]);

        if (!options) {
            return null;
        }
        return (
            <Select
                aria-label="language selector"
                defaultValue={lang}
                value={lang}
                onChange={handleNavigate}
                options={options}
            />
        );
    },
);

export { LanguageSelector };
