import React from 'react';
import Img from 'gatsby-image';
import { PrismicImageField, PrismicFluidImageField } from 'components/Slices/types';
import { PrismicExactImage } from './PrismicExactImage';
import './PrismicFluidImage.scss';

interface PrismicFluidImageProps {
    alt?: string;
    className?: string;
    fullscreen?: boolean;
    fallback: PrismicImageField;
    image: PrismicFluidImageField;
    isLazyLoaded?: boolean;
}

function joinClasses(...args: any[]): string {
    const classes: string[] = [];

    Array.from(args).forEach((arg) => {
        if (arg && typeof arg === 'string') {
            classes.push(arg);
        }
    });

    return classes.join(' ');
}

export const PrismicFluidImage: React.FC<PrismicFluidImageProps> = ({
    alt,
    className,
    fallback,
    fullscreen = false,
    image,
    isLazyLoaded = true,
}) => {
    if (image && image.childImageSharp && image.childImageSharp.fluid) {
        const classes = joinClasses(className, fullscreen ? 'FullScreenImage' : null);
        return (
            <Img
                loading={isLazyLoaded ? 'lazy' : 'eager'}
                alt={alt}
                className={classes}
                fluid={image.childImageSharp.fluid}
            />
        );
    }

    // When imageSharp image isn't available (e.g. during Prismic preview), directly use image URL
    if (fallback) {
        const classes = joinClasses(className, fullscreen ? 'FullScreenImage Fallback' : null);
        return <PrismicExactImage alt={alt} className={classes} image={fallback} />;
    }

    return null;
};
