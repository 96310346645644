import { graphql } from 'gatsby';

export const FeaturedArtistsSliceFragment = graphql`
    fragment PrismicFeaturedArtistsFragment on PRISMIC_PageBodyFeatured_artists {
        type
        fields {
            featured_artists_eyebrow
            featured_artists_title
            featured_artists_cta_link {
                ...LinkFragment
            }
            featured_artists_cta_label
            featured_artists_description
            featured_artists_image
            featured_artists_imageSharp {
                ...FluidImageFragment
            }
        }
    }
`;
