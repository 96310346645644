import * as React from 'react';
import './LinkList.scss';
import { Arrow } from 'components/Icons';
import { Container } from 'components/UI/Container/Container';
import { PrismicHTML } from 'components/Prismic';
import { Grid, GridRow, GridCol } from '../../UI/Grid';
import { PrismicLinkFieldType, PrismicRichTextFieldType, SliceContext } from '../types';
import { PrismicLink } from '../../PrismicLink';

type LinkList = {
    link_title: PrismicRichTextFieldType;
    link_description: PrismicRichTextFieldType;
    link_url: PrismicLinkFieldType;
    sliceContext: SliceContext;
};

type LinkListProps = {
    type: 'linklist';
    slice: {
        fields: LinkList[];
    };
};

const LinkListItem = ({ linklist }: { linklist: LinkList }) => {
    if (!linklist.link_url) {
        return null;
    }

    return (
        <PrismicLink link={linklist.link_url} className="Linklist-ctaLink" slice={linklist.sliceContext}>
            <div className="Linklist-wrapperItem">
                <GridRow horizontalAlign={'h-start'} verticalAlign={'v-center'}>
                    <GridCol colSize={'4'} offsetSize={`0`} mobileColSize={'12'}>
                        <PrismicHTML content={linklist.link_title} className="Linklist-itemTitle" />
                    </GridCol>
                    <GridCol colSize={'8'} offsetSize={`0`} mobileColSize={'12'}>
                        <PrismicHTML content={linklist.link_description} className="Linklist-itemDescription" />
                    </GridCol>
                    <div className="Linklist-ctaArrow">
                        <Arrow className="ctaArrow" color="blue" />
                    </div>
                </GridRow>
            </div>
        </PrismicLink>
    );
};

const LinkList = ({ slice }: LinkListProps) => {
    const { fields } = slice;
    return (
        <Container size="sm">
            <Grid className="Linklist">
                {fields.map((linklist: LinkList, i: number) => (
                    <div className="Linklist-wrapper" key={i}>
                        <GridRow horizontalAlign={'h-start'} verticalAlign={'v-stretch'}>
                            <GridCol colSize={'12'} offsetSize={`0`} mobileColSize={'12'}>
                                <LinkListItem linklist={linklist} />
                            </GridCol>
                        </GridRow>
                    </div>
                ))}
            </Grid>
        </Container>
    );
};

export { LinkList, LinkListProps, LinkListItem };
