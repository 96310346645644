import { graphql } from 'gatsby';

export const TextBlockSliceFragment = graphql`
    fragment PrismicTextBlockFragment on PRISMIC_PageBodyText_block {
        type
        primary {
            tb_paddingtop
            tb_paddingbottom
            text_block_content
        }
    }
`;
