import { TweenLite, Power1, Power2 } from 'gsap';

let resizeTimer: any;
let windowWidth = typeof window !== 'undefined' && window.innerWidth;

export function backgroundImagesAnimationIn(img: any) {
    TweenLite.to(img, 0.5, {
        delay: 0,
        x: 0,
        ease: Power2.easeIn,
    });
}

export function backgroundImagesAnimationOut(img: any) {
    TweenLite.to(img, 0.5, {
        delay: 0.5,
        x: windowWidth,
        ease: Power2.easeIn,
    });
}

export function moveToggle(btn: any, position: any) {
    TweenLite.to(btn, 0.5, {
        x: position,
        ease: Power2.easeIn,
    });
}

export function setProgressBgToDefault(btn: any) {
    TweenLite.to(btn, 0.2, {
        backgroundColor: '#ffffff',
        ease: Power1.easeIn,
    });
}

export function backgroundResize(
    isSinglePlayer: boolean | undefined,
    btnOriginal: any,
    btnMaster: any,
    selectedBackground: any,
) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
        windowWidth = typeof window !== 'undefined' && window.innerWidth;
        const backgroundImages = Array.from(document.querySelectorAll('.Wrapper-style-image')) as any;
        if (backgroundImages) {
            backgroundImages.forEach((backgroundImage: any) => {
                const { classList } = backgroundImage;
                if (classList && !classList.contains('Active')) {
                    TweenLite.to(backgroundImage, 0, {
                        x: windowWidth,
                    });
                }
            });
        }

        if (!isSinglePlayer && btnOriginal.current && selectedBackground.current) {
            if (btnOriginal.current.classList.contains('is-selected')) {
                selectedBackground.current.style.width = `${btnOriginal.current.offsetWidth}px`;
            } else {
                selectedBackground.current.style.width = `${btnMaster.current.offsetWidth}px`;
                moveToggle(selectedBackground.current, btnOriginal.current.offsetWidth);
            }
        }
    }, 250);
}

const componentContentAnimation = () => {
    const sliceTitles = document.querySelectorAll('.MasteringDemos-title');
    const allStyles = document.querySelectorAll('.MasteringDemos-style');
    const playButtons = document.querySelectorAll('.PlayButton');
    const blockVersions = document.querySelectorAll('.Block-version');
    const progessBackground = document.querySelector('.Progress-bg');

    if (sliceTitles) {
        sliceTitles.forEach((sliceTitle) => {
            TweenLite.to(sliceTitle, 1, {
                opacity: 1,
                ease: Power2.easeIn,
            });
        });
    }

    if (allStyles) {
        for (let i = 0; i < allStyles.length; i++) {
            TweenLite.to(allStyles[i], 1, {
                delay: 0.5 + i * 0.3,
                opacity: 1,
                ease: Power2.easeIn,
            });
            const styleTitle = allStyles[i].querySelector('.MasteringDemos-style-title');

            if (styleTitle) {
                TweenLite.to(styleTitle, 1, {
                    delay: 1 + i * 0.3,
                    left: '0px',
                    ease: Power2.easeIn,
                });
            }
        }
    }

    if (playButtons) {
        playButtons.forEach((playButton) => {
            TweenLite.to(playButton, 1, {
                delay: 1,
                opacity: 1,
                ease: Power2.easeIn,
            });

            const button = playButton.querySelector('.Button');

            if (button) {
                button.addEventListener('mouseover', function () {
                    TweenLite.to(progessBackground, 0.2, {
                        backgroundColor: '#0C1B2B',
                        ease: Power1.easeIn,
                    });
                });

                button.addEventListener('mouseout', function () {
                    TweenLite.to(progessBackground, 0.2, {
                        backgroundColor: '#ffffff',
                        ease: Power1.easeIn,
                    });
                });
            }
        });
    }

    if (blockVersions) {
        blockVersions.forEach((blockVersion) => {
            TweenLite.to(blockVersion, 1, {
                delay: 1.5,
                opacity: 1,
                ease: Power2.easeIn,
            });
        });
    }
};
export { componentContentAnimation };
