import { PageDataType, PageNotFoundDataType } from './types';

/**
 * Needed for unpublished documents, which contain no data at build time.
 *
 * We check the location to use the dynamic `lang` query param for unpublished pages.
 * This makes the main navigation localized
 */
export const getUnpublishedPageData = (location: Location): PageDataType => {
    const searchParams = new URLSearchParams(location.search);

    return {
        _meta: {
            id: 'id',
            uid: 'unpublished',
            lang: searchParams.get('lang') || 'en-us',
            type: 'page',
            firstPublicationDate: null,
            lastPublicationDate: null,
            alternateLanguages: [],
        },
        title: 'Unpublished Page',
        meta_canonical: null,
        meta_image: null,
        meta_twitter_image: null,
        body: [
            {
                type: 'hero',
                primary: {
                    hero_title: [
                        {
                            type: 'heading4',
                            text: 'Unpublished Page',
                            spans: [],
                        },
                    ],
                    hero_description: null,
                    hero_alignment: 'Center',
                    hero_image: null,
                    hero_cta_label: null,
                    hero_cta_link: null,
                    hero_cta_label_secondary: null,
                    hero_cta_link_secondary: null,
                    hero_show_arrow: 'No',
                },
            },
        ],
        home_page: null,
        page_type: 'Default',
        show_navigation_header: 'yes',
        header_type: 'MainNavigation',
        partner_logo: null,
        partner_url: null,
        show_footer: 'yes',
        theme: 'Dark',
    };
};

// Needed for unpublished documents, which contain no data at build time.
export const UnpublishedPageNotFoundData: PageNotFoundDataType = {
    edges: [
        {
            node: {
                _meta: {
                    id: '6948e2bb-38ce-4d46-93bc-02fcd483c0e5',
                    uid: 'unpublished',
                    lang: 'en-us',
                    type: 'not_found_page',
                    firstPublicationDate: 'never',
                    lastPublicationDate: 'never',
                    alternateLanguages: [],
                },
                not_found_small_text: 'Unpublished 404 Page',
                not_found_large_text: null,
                not_found_button_text: null,
                not_found_button_link: null,
            },
        },
    ],
};
