import { PrismicMeta } from 'components/Slices/types';

/**
 * Returns the Prismic UID for the english version of the page.
 * Falls back on the current page's uid (if present)
 * or the Prismic document type (e.g. 404_page)
 * @param page The page's full data
 */
export const getEnglishUID = (pageMeta: PrismicMeta): string => {
    if (!pageMeta) {
        return '';
    }

    const en = 'en-us';
    if (pageMeta.lang !== en && pageMeta.alternateLanguages) {
        const enPage = pageMeta.alternateLanguages.find((p: any) => p.lang === en);
        if (enPage && enPage.uid) {
            return enPage.uid;
        }
    }
    return pageMeta.uid || pageMeta.type;
};
