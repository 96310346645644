import { SegmentService } from './segment-generated';
import { AnalyticsV2Service } from './AnalyticsV2Service.generated';
export * from './AnalyticsV2Service.generated';
export * from './analytics.helpers';

const Analytics = new SegmentService();

export { Analytics as SegmentService };

export const analyticsV2 = new AnalyticsV2Service();

analyticsV2.load(process.env.GATSBY_SEGMENT_WRITE_KEY as string);
