import { graphql } from 'gatsby';

export const QuotesSliceFragment = graphql`
    fragment PrismicQuotesFragment on PRISMIC_PageBodyQuotes {
        type
        fields {
            quotes_text
            quotes_image
            quotes_imageSharp {
                ...FluidImageFragment
            }
            quotes_author
            quotes_description
        }
    }
`;
