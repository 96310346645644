import { graphql } from 'gatsby';

export const UniversalNavigationDropdownItemFragment = graphql`
    fragment UniversalNavigationDropdownItemFragment on PRISMIC_Universal_navigationBodyNavigation_dropdown_item {
        type
        primary {
            navigation_dropdown_document {
                ... on PRISMIC_Universal_navigation_item_dropdown {
                    ...MetaFragment
                }
            }
        }
    }
`;

export const PrismicUniversalNavigationDropdownItemDocumentsFragment = graphql`
    fragment PrismicUniversalNavigationDropdownItemDocumentsFragment on PRISMIC_Universal_navigation_item_dropdown {
        ...MetaFragment
        menu_label
        body {
            ...UniversalNavigationDropdownItemGroupedFragment
            ...UniversalNavigationDropdownItemUngroupedFragment
        }
    }

    fragment UniversalNavigationDropdownItemGroupedFragment on PRISMIC_Universal_navigation_item_dropdownBodyGrouped {
        type
        label
        primary {
            group_label
        }
        fields {
            item_label
            item_icon
            item_badge
            item_description
            item_link {
                ...LinkFragment
            }
        }
    }

    fragment UniversalNavigationDropdownItemUngroupedFragment on PRISMIC_Universal_navigation_item_dropdownBodyUngrouped {
        __typename
        type
        label
        fields {
            __typename
            item_label
            item_icon
            item_badge
            item_description
            item_link {
                ...LinkFragment
            }
        }
    }
`;
