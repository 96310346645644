import React from 'react';
import { PrismicFluidImage } from 'components/Prismic';
import { PrismicLink } from 'components/PrismicLink';
import { BaseFeatureSlice } from 'components/BaseFeatureSlice';
import { FeatureProps } from './types';

export const Feature: React.FC<FeatureProps> = ({ slice, sliceContext }) => {
    const {
        primary: {
            feature_eyebrow: eyeBrow,
            feature_title: title,
            feature_position: position,
            feature_description: description,
            feature_cta_label: ctaLabel,
            feature_cta_link: ctaLink,
            feature_cta_kind: ctaKind,
            feature_cta_variant: ctaVariant,
            feature_image: image,
            feature_imageSharp: imageSharp,
            feature_image_cta_link: ctaImageLink,
        },
    } = slice;

    return (
        <BaseFeatureSlice
            eyeBrow={eyeBrow}
            title={title}
            position={position}
            description={description}
            ctaLabel={ctaLabel}
            ctaLink={ctaLink}
            ctaKind={ctaKind}
            ctaVariant={ctaVariant}
            sliceContext={sliceContext}
        >
            {ctaImageLink ? (
                <PrismicLink link={ctaImageLink} className="Feature-image-cta-link">
                    <PrismicFluidImage image={imageSharp} fallback={image} />
                </PrismicLink>
            ) : (
                <PrismicFluidImage image={imageSharp} fallback={image} />
            )}
        </BaseFeatureSlice>
    );
};
