import { graphql } from 'gatsby';

export const LogoListSliceFragment = graphql`
    fragment PrismicLogoListFragment on PRISMIC_PageBodyLogo_list {
        type
        primary {
            logo_list_title
            logo_list_more_text
        }
        fields {
            logo_list_image
            logo_list_image_alt
        }
    }
`;
