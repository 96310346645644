import React from 'react';
import { prismicText } from 'helpers';

interface PrismicTextProps {
    className?: string;
    component?: React.ElementType;
    content: any;
}

export const PrismicText: React.FC<PrismicTextProps> = ({ className, component: Component, content }) => {
    const text = prismicText(content);

    if (!text) {
        return null;
    }

    return Component ? <Component className={className}>{text}</Component> : <>{text}</>;
};
