import React, { useState } from 'react';
import NukaCarousel, { CarouselProps as NukaCarouselProps } from 'nuka-carousel';
import { CarouselControls } from '../CarouselControls';

const carouselConfig: NukaCarouselProps = {
    cellAlign: 'center',
    swiping: true,
    dragging: true,
    initialSlideHeight: 700,
    slidesToShow: 1.2,
    speed: 300,
    renderCenterLeftControls: undefined,
    renderCenterRightControls: undefined,
    edgeEasing: 'easeCubicOut',
    cellSpacing: 12,
};

type CarouselProps = {
    className?: string;
    theme?: 'light' | 'dark';
    carouselConfig?: NukaCarouselProps;
    children: React.ReactNode;
};

const Carousel = (props: CarouselProps) => {
    const [currentPostIndex, setCurrentPostIndex] = useState(0);

    const handleClick = (slideIndex: number) => () => {
        setCurrentPostIndex(slideIndex);
    };

    return (
        <NukaCarousel
            className={props.className}
            renderBottomCenterControls={slide => (
                <CarouselControls
                    onClick={handleClick}
                    slide={slide}
                    theme={props.theme || 'dark'}
                />
            )}
            slideIndex={currentPostIndex}
            {...carouselConfig}
            {...props.carouselConfig}
        >
            {props.children}
        </NukaCarousel>
    );
};

export { Carousel, CarouselProps };
