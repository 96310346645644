import React, { useState, useEffect, useContext } from 'react';
import { AuthService, BrowserPlatformProvider, FusionAuthSPAAuthProvider } from '@landr/auth';
import { Environment } from '@landr/core';
import { analyticsV2 } from 'analytics';
import { getTwoLetterLanguageCode } from 'helpers';
import { useAppContext } from 'context/AppContext';
import { isLandrAppHostname } from 'helpers/isLandrAppHostname';
import { EventId } from 'enum/eventId';
import { UTMCookieSettings } from '../../constants';
import { log } from '../../log';

const logger = log.getInstance('AuthenticationProvider');

const makeCollaborationReturnPath = (id: string, type: string, code: string): string => {
    if (type === 'Audio') {
        return `/library/tracks/${id}?collaborationCode=${code}&referrer=authsite`;
    } else if (type === 'Project') {
        return `/library/projects/${id}?collaborationCode=${code}&referrer=authsite`;
    } else {
        return '';
    }
};

const fetchCollaborationReturnPath = (code: string): Promise<string> => {
    return fetch(`${process.env.GATSBY_API_ENDPOINT}/library/api/v1/collaborators/link/${code}`)
        .then((response) => response.json())
        .then((item) => (item ? makeCollaborationReturnPath(item.itemId, item.itemType, code) : ''))
        .catch(() => {
            return '';
        });
};

/**
 *
 * @param redirectUrl
 * @param currentPageParams
 * @param pageLang - Current guest site language
 * @param homePage - The homePage asignation on signup, set for each prismic page
 */
const augmentQueryParams = (
    redirectUrl: URL,
    currentPageParams: URLSearchParams,
    pageLang: string,
    homePage: string | null,
) => {
    const redirectUrlParams = new URLSearchParams(redirectUrl.search);

    if (pageLang) {
        redirectUrlParams.set('locale', getTwoLetterLanguageCode(pageLang));
    }

    if (homePage) {
        redirectUrlParams.set('homePage', homePage);
    }

    // Merge currentPageParams and redirectUrlParams, only if redirectUrlParams doesn't already contain the param
    for (const [key, value] of currentPageParams.entries()) {
        if (!redirectUrlParams.has(key) && key !== 'returnUrl') {
            redirectUrlParams.append(key, value);
        }
    }
    return redirectUrlParams;
};

type MakeRedirectUrlResult = { redirectUrl: URL; redirectUrlParams: URLSearchParams };

const makeRedirectUrl = async (
    currentPageParams: URLSearchParams,
    pageLang: string,
    homePage: string | null,
): Promise<MakeRedirectUrlResult> => {
    const appEndpoint = process.env.GATSBY_APP_ENDPOINT as string;
    const plan = currentPageParams.get('plan');
    const planFrequency = currentPageParams.get('planFrequency');
    const collaborationCode = currentPageParams.get('collaborationCode');
    const returnUrl = currentPageParams.get('returnUrl');
    let redirectUrl: URL | null = null;

    // Determine where to redirect the user after registering & logging in
    if (plan) {
        // User has chosen a billing plan
        redirectUrl = new URL(`${appEndpoint}/payment/subscription/checkout/${plan}/${planFrequency || 'yearly'}`);
    } else if (collaborationCode) {
        // Invitation to collaborate on an Asset/Project
        redirectUrl = new URL(appEndpoint + (await fetchCollaborationReturnPath(collaborationCode)));
    } else if (returnUrl) {
        // Use a try/catch in case we are unable to parse the URLs
        try {
            // User is supposed to be redirected to a URL specified in the returnUrl query param
            const returnUrlObject = new URL(returnUrl);
            // Only allow redirections to LANDR apps to prevent malicious redirections
            if (isLandrAppHostname(returnUrlObject.hostname)) {
                redirectUrl = returnUrlObject;
            } else {
                // Log a warning
                logger.warn(
                    'An unsupported returnUrl was used as a returnUrl query param',
                    EventId.UnsupportedReturnUrlWarning,
                );
            }
        } catch (e) {
            // This error will be caught if we are unable to parse the URL using new URL(...)
            logger.warn(e.message, EventId.CatchedReturnUrlWarning);
        }
    }

    if (!redirectUrl) {
        // Send the user to the web app home page
        redirectUrl = new URL(appEndpoint);
    }

    return {
        redirectUrl,
        redirectUrlParams: augmentQueryParams(redirectUrl, currentPageParams, pageLang, homePage),
    };
};

export type AuthenticationContextType = {
    isAuthenticated: boolean;
    loginUrl: string;
    signupUrl: string;
    logout: () => void;
    authorizationToken?: string;
    authService: AuthService | null;
};

const AuthenticationContext = React.createContext({
    isAuthenticated: false,
    loginUrl: '',
    signupUrl: '',
    logout: () => undefined,
    authorizationToken: undefined,
    authService: null,
} as AuthenticationContextType);

const useAuthentication = (): AuthenticationContextType => useContext(AuthenticationContext);

interface AuthenticationProviderProps {
    children: React.ReactNode;
}

const AuthenticationProvider: React.FC = ({ children }: AuthenticationProviderProps) => {
    const href = typeof window !== 'undefined' && window.location.href;
    const searchParams = typeof window !== 'undefined' && window.location.search;
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authorizationToken, setAuthorizationToken] = useState<string>();
    const [loginUrl, setLoginUrl] = useState('');
    const [signupUrl, setSignupUrl] = useState('');
    const appContext = useAppContext();

    // Start authentication service if we are not in SSR mode
    const [authService, setAuthService] = useState<AuthService | null>(null);

    useEffect(() => {
        const execute = async () => {
            // AuthService should always be defined except for Gatsby SSR.
            if (typeof window !== 'undefined') {
                const environment = process.env.GATSBY_ENV as Environment;

                const platformProvider = new BrowserPlatformProvider({
                    environment,
                    analytics: analyticsV2,
                    cookieSettings: UTMCookieSettings,
                });
                const authProvider = new FusionAuthSPAAuthProvider({
                    domain: process.env.GATSBY_FUSION_AUTH_DOMAIN as string,
                    clientId: process.env.GATSBY_FUSION_AUTH_CLIENT_ID as string,
                    platformProvider,
                    environment,
                    log: log.getInstance('FusionAuthSPAAuthProvider'),
                });

                const internalAuthService = new AuthService({
                    authProvider,
                    platformProvider,
                    environment,
                    homepageForAnalyticsFallback: undefined, // No default homepage, will be done by `makeRedirectUrl`
                    log: log.getInstance('AuthService'),
                });

                setAuthService(internalAuthService);

                // Then wait for silent authentication to complete
                let isAuthenticated = false;
                let token;
                try {
                    isAuthenticated = await internalAuthService.checkOrWaitForOngoingAuthentication();
                    token = await internalAuthService.getAccessToken();
                } catch (error) {
                    setIsAuthenticated(false);
                    setAuthorizationToken(undefined);
                }

                setAuthorizationToken(token ?? undefined);
                setIsAuthenticated(isAuthenticated);
            }
        };

        typeof window !== 'undefined' && execute();
    }, []);

    useEffect(() => {
        const makeUrl = async () => {
            if (typeof window !== 'undefined') {
                const currentPageParams = new URLSearchParams(window.location.search);

                const { redirectUrl, redirectUrlParams } = await makeRedirectUrl(
                    currentPageParams,
                    appContext.pageContext.lang,
                    appContext.homePage,
                );

                let url = `${redirectUrl.origin}${redirectUrl.pathname}`;

                if (redirectUrlParams && !!redirectUrlParams.toString()) {
                    url += `?${redirectUrlParams.toString()}`;
                }

                setLoginUrl(url);
                setSignupUrl(`${url}${url.includes('?') ? '&' : '?'}isSignup=true`);
            }
        };

        makeUrl();
    }, [appContext.pageContext.lang, appContext.homePage, searchParams, href]);

    const logout = async () => {
        return authService && authService.logout();
    };

    return (
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                loginUrl,
                logout,
                signupUrl,
                authorizationToken,
                authService,
            }}
        >
            <>{children}</>
        </AuthenticationContext.Provider>
    );
};

export { AuthenticationProvider, useAuthentication };
