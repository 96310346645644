import { Property } from 'csstype';

class AudioPlayersProgress {
    activeSource: any;
    canvas: any;
    context: CanvasRenderingContext2D;
    ratio: number;
    posX: number;
    posY: number;
    progressInterval: number;
    progressValue = 0;
    inProgress: boolean;
    raf: any;
    backgroundColor: Property.BackgroundColor = '#ffffff';

    constructor() {
        this.setProgressBar();
    }

    public startProgress() {
        this.inProgress = true;
    }

    public updateSource(activeSource: any) {
        this.activeSource = activeSource;
    }

    public destroy() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    setProgressBar() {
        this.canvas = document.getElementById('Progress-bar');
        this.context = this.canvas.getContext('2d');
        this.ratio = window.devicePixelRatio || 1;
        if (1 !== this.ratio) {
            this.canvas.style.width = `${this.canvas.width}px`;
            this.canvas.style.height = `${this.canvas.height}px`;
            this.canvas.width *= 2;
            this.canvas.height *= 2;
            this.context.scale(2, 2);

            this.posX = this.canvas.width / 4;
            this.posY = this.canvas.height / 4;
        } else {
            this.posX = this.canvas.width / 2;
            this.posY = this.canvas.height / 2;
        }
    }

    public update(currentTime: number, totalTime: number) {
        if (this.inProgress) {
            this.progressValue = (currentTime * 360) / totalTime;
        } else {
            this.progressValue = 0;
        }

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.context.beginPath();
        this.context.strokeStyle = '#32cbcb';
        this.context.lineWidth = 5;
        this.context.arc(
            this.posX,
            this.posY,
            30,
            (Math.PI / 180) * 270,
            (Math.PI / 180) * (270 + Math.abs(this.progressValue)),
        );
        this.context.stroke();
    }
}

export { AudioPlayersProgress };
