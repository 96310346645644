import React from 'react';
import './QuotesVideo.scss';
import { Container } from 'components/UI/Container/Container';
import { Grid, GridRow, GridCol } from 'components/UI/Grid';
import { PlayButton } from 'components/UI/PlayButton';
import { VideoReactPlayer } from 'components/UI/VideoReactPlayer/VideoReactPlayer';
import FocusTrap from 'focus-trap-react';
import { prismicText } from 'helpers';
import { PrismicFluidImage } from 'components/Prismic';
import { PrismicRichTextFieldType, PrismicFluidImageField, PrismicImageField, VideoApiEnum } from '../types';
import { SupportedPrismicSlicesEnum } from '../enums';

type QuotesVideoProps = {
    slice: {
        type: SupportedPrismicSlicesEnum.quotes_video;
        primary: {
            quotes_video_background_image: PrismicImageField;
            quotes_video_background_imageSharp: PrismicFluidImageField;
            quotes_video_quote_text: PrismicRichTextFieldType;
            quotes_video_author_name: PrismicRichTextFieldType;
            quotes_video_author_role: PrismicRichTextFieldType;
            video_api: VideoApiEnum | null;
            quotes_video_wistia_video_id: PrismicRichTextFieldType;
            quotes_video_play_button_text: PrismicRichTextFieldType;
        };
    };
};

type QuotesVideoState = {
    displayVideo: boolean;
};

class QuotesVideo extends React.Component<QuotesVideoProps, QuotesVideoState> {
    videoContainer: HTMLElement | null;
    wistiaVideoNode: HTMLElement | null;
    playButton: HTMLElement | null;

    state = {
        displayVideo: false,
    };

    componentDidMount() {
        if (typeof document !== 'undefined') {
            document.addEventListener('mousedown', this.handleClick, false);
        }
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('mousedown', this.handleClick, false);
        }
    }

    handleClick = (e: Event) => {
        if (this.state.displayVideo) {
            if (this.wistiaVideoNode && this.wistiaVideoNode.contains(e.target as HTMLElement)) {
                return;
            }
            this.handleCloseModal();
        }
    };

    handleCloseModal = () => {
        this.setState({ displayVideo: false });
        // Set the focus back to the play button when the modal closes
        // TODO: Figure out how to set the focus to a functional component (i.e. the play button)
        return this.playButton && this.playButton.focus();
    };

    handlePlayButtonPress = () => {
        this.setState({ displayVideo: true });
        // Set the focus inside of the modal when the modal opens
        return this.videoContainer && this.videoContainer.focus();
    };

    render() {
        let {
            quotes_video_quote_text: quoteText,
            quotes_video_author_name: authorName,
            quotes_video_author_role: authorRole,
            quotes_video_play_button_text: playButtonText,
            quotes_video_wistia_video_id: wistiaVideoId,
        } = this.props.slice.primary;
        const {
            quotes_video_background_image: fallbackImage,
            quotes_video_background_imageSharp: backgroundImage,
            video_api: videoApi,
        } = this.props.slice.primary;

        // TODO: MG-38000 Replace incorrectly chosen RichText fields with KeyText
        quoteText = prismicText(quoteText);
        authorName = prismicText(authorName);
        authorRole = prismicText(authorRole);
        playButtonText = prismicText(playButtonText);
        wistiaVideoId = prismicText(wistiaVideoId);

        return (
            quoteText &&
            (backgroundImage || fallbackImage) &&
            authorName &&
            authorRole &&
            playButtonText &&
            videoApi &&
            wistiaVideoId && (
                <div className="QuotesVideo">
                    <Container className="QuotesVideo-content" size="md">
                        <Grid>
                            <GridRow horizontalAlign={'h-center'} verticalAlign={'v-center'}>
                                <GridCol colSize={`10`} offsetSize={`0`} mobileColSize={'12'}>
                                    <p className="QuotesVideo-quoteText">{quoteText}</p>
                                    <p className="QuotesVideo-author">
                                        <span className="QuotesVideo-authorName">{authorName}</span>
                                        <span className="QuotesVideo-authorRole">{authorRole}</span>
                                    </p>
                                    <div className="QuotesVideo-play">
                                        <PlayButton
                                            className="QuotesVideo-playButton"
                                            isPlaying={false}
                                            audioAction={this.handlePlayButtonPress}
                                            ref={(ref) => (this.playButton = ref)}
                                        />
                                        <p className="QuotesVideo-playText">{playButtonText}</p>
                                    </div>
                                </GridCol>
                            </GridRow>
                        </Grid>
                    </Container>
                    <PrismicFluidImage image={backgroundImage} fallback={fallbackImage} fullscreen={true} />
                    {this.state.displayVideo && (
                        <FocusTrap>
                            <div className={'QuotesVideo-videoContainer'} ref={(ref) => (this.videoContainer = ref)}>
                                <button
                                    className={'QuotesVideo-closeButton'}
                                    aria-label="Close"
                                    onClick={this.handleCloseModal}
                                />
                                <div className={'QuotesVideo-video'} ref={(ref) => (this.wistiaVideoNode = ref)}>
                                    <VideoReactPlayer
                                        videoUrl={
                                            videoApi === VideoApiEnum.Wistia
                                                ? `https://home.wistia.com/medias/${wistiaVideoId}`
                                                : `https://www.youtube.com/embed/${wistiaVideoId}`
                                        }
                                        hasControls={true}
                                        isLooping={true}
                                        isMuted={false}
                                        isAutoPlaying={true}
                                    />
                                </div>
                            </div>
                        </FocusTrap>
                    )}
                </div>
            )
        );
    }
}

export { QuotesVideo, QuotesVideoProps };
