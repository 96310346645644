import { Flex, Heading } from '@landr/maestro';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { RoyaltyCalculator } from '@landr/royalty-calculator';
import React from 'react';
import { PageContextType } from 'types';
import { SupportedPrismicSlicesEnum } from '../enums';

export type RoyaltyCalculatorSliceProps = {
    pageContext: PageContextType;
    location: Location;
    slice: {
        type: SupportedPrismicSlicesEnum.royalty_calculator;
        primary: {
            /* eslint-disable @typescript-eslint/naming-convention */
            royalty_calculator_title: any;
        };
    };
};

export const RoyaltyCalculatorSlice: React.FC<RoyaltyCalculatorSliceProps> = ({ slice }) => {
    const { royalty_calculator_title } = slice.primary;

    return (
        <Flex alignContent={'center'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} p="xxl">
            {royalty_calculator_title && (
                <Heading as="h1" size="jb" maxWidth="1200px" pb={'xl'} mt={'xl'}>
                    {royalty_calculator_title[0].text}
                </Heading>
            )}
            <Flex flexDirection={'row'} width="100%" alignItems={'center'} justifyContent={'center'}>
                <RoyaltyCalculator />
            </Flex>
        </Flex>
    );
};
