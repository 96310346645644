import { graphql } from 'gatsby';

export const HeroVideoSliceFragment = graphql`
    fragment PrismicHeroVideoFragment on PRISMIC_PageBodyHero_video {
        type
        primary {
            hero_video_title
            hero_video_description
            hero_video_alignment
            hero_video_cta_kind
            hero_video_cta_label
            hero_video_cta_link {
                ...LinkFragment
            }
            hero_video_cta_kind_secondary
            hero_video_cta_label_secondary
            hero_video_cta_link_secondary {
                ...LinkFragment
            }
            hero_video_cta_label_authenticated
            hero_video_cta_link_authenticated {
                ...LinkFragment
            }
            hero_video_show_arrow
            hero_video_wistia_id
            hero_video_image_mobile
            hero_video_image_mobileSharp {
                ...FluidImageFragment
            }
        }
    }
`;
