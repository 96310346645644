import { graphql } from 'gatsby';

export const MainFooterFragment = graphql`
    fragment PrismicMainFooterFragment on PRISMIC_Main_footer {
        ...MetaFragment
        copyright_info
        privacy_policy_label
        privacy_policy_link {
            ...LinkFragment
        }
        tos_label
        tos_link {
            ...LinkFragment
        }
        social_items {
            label
            link {
                ...LinkFragment
            }
        }
        products_title
        products_items {
            label
            link {
                ...LinkFragment
            }
        }
        landr_title
        landr_items {
            label
            link {
                ...LinkFragment
            }
        }
        resources_title
        resources_items {
            label
            link {
                ...LinkFragment
            }
        }
        newsletter_title
        newsletter_description
        newsletter_email_placeholder
        newsletter_cta
        redeem_title
        redeem_cta_title
        redeem_cta_link {
            ...LinkFragment
        }
    }
`;
