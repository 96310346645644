import React from 'react';
import { Container } from '@landr/maestro';
import { AlternateLanguage } from 'types';
import { MainFooterData } from './components/types';
import { MainFooterBottom } from './components/MainFooterBottom/MainFooterBottom';
import { MainFooterColumns } from './components/MainFooterColumns';

export type MainFooterProps = {
    lang: string;
    alternateLanguages: AlternateLanguage[];
    mainFooterData: MainFooterData;
};

export const MainFooter = (props: MainFooterProps) => {
    const data = props.mainFooterData;
    return (
        <Container
            size="xl"
            py="mg"
            px="xl"
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <MainFooterColumns data={data} />
            <MainFooterBottom lang={props.lang} data={data} />
        </Container>
    );
};

export { MainFooterData };
