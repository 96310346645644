import { graphql } from 'gatsby';
import { UniversalNavigationReferralsBarFragment } from 'components/ReferralsBanners';
import {
    UniversalNavigationSingleItemFragment,
    PrismicUniversalNavigationDropdownItemDocumentsFragment,
    UniversalNavigationDropdownItemFragment,
} from './components';

export const PrismicUniversalNavigationFragment = graphql`
    fragment PrismicUniversalNavigationFragment on PRISMIC_Universal_navigation {
        __typename
        ...MetaFragment
        go_to_app_label
        ...UniversalNavigationReferralsBarFragment
        body {
            __typename
            ...UniversalNavigationDropdownItemFragment
            ...UniversalNavigationSingleItemFragment
        }
    }
`;

export const PrismicUniversalNavigationFragments = [
    PrismicUniversalNavigationFragment,
    UniversalNavigationSingleItemFragment,
    UniversalNavigationDropdownItemFragment,
    PrismicUniversalNavigationDropdownItemDocumentsFragment,
    UniversalNavigationReferralsBarFragment,
];
