import React from 'react';
import { PrismicFluidImage } from 'components/Prismic';
import { Item } from './types';
import { backgroundImagesAnimationIn, backgroundImagesAnimationOut } from './Animations';

export type StyleImageProps = {
    style: Item;
    isSelectedStyle: boolean;
};

class StyleImage extends React.Component<StyleImageProps> {
    bgImg: React.RefObject<any>;
    constructor(props: StyleImageProps) {
        super(props);
        this.bgImg = React.createRef();
    }

    componentDidMount() {
        if (this.bgImg.current.getAttribute('class').includes('Active') === true) {
            this.bgImg.current.style.transform = 'translateX(0)';
        }
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.isSelectedStyle !== this.props.isSelectedStyle) {
            const { isSelectedStyle } = this.props;

            isSelectedStyle
                ? backgroundImagesAnimationIn(this.bgImg.current)
                : backgroundImagesAnimationOut(this.bgImg.current);
        }
    }

    render() {
        const { style, isSelectedStyle } = this.props;
        const { mastering_demos_image: fallback, mastering_demos_imageSharp: image } = style;
        return (
            <div ref={this.bgImg} className={`Wrapper-style-image ${isSelectedStyle ? 'Active' : ''}`}>
                <div className="MasteringDemos-style-image">
                    <PrismicFluidImage image={image} fallback={fallback} fullscreen={true} />
                </div>
            </div>
        );
    }
}

export { StyleImage };
