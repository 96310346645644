import {
    PrismicTitleFieldType,
    PrismicMeta,
    PrismicRichTextFieldType,
    PrismicLinkFieldType,
    PrismicImageField,
} from 'components/Slices/types';
import { Edges } from 'types';

export type PageType = 'Default' | 'Plugin';

export const PageTypeToPath: {
    [V in PageType]: string;
} = {
    Default: '',
    Plugin: 'plugins',
};

export type PageDataType = {
    _meta: PrismicMeta;
    title: PrismicTitleFieldType;
    meta_title?: PrismicRichTextFieldType;
    meta_description?: PrismicRichTextFieldType;
    meta_canonical: PrismicLinkFieldType;
    meta_image: PrismicImageField;
    meta_twitter_image: PrismicImageField;
    body: any;
    home_page: string | null;
    page_type: PageType;
    show_navigation_header: 'yes' | 'no';
    header_type: 'MainNavigation' | 'LogoOnly' | 'PartnerHeader';
    partner_logo: PrismicImageField;
    partner_url: PrismicLinkFieldType;
    show_footer: 'yes' | 'no';
    theme: 'Dark' | 'Light';
};

export type PageNotFoundDataType = Edges<{
    _meta: PrismicMeta;
    not_found_small_text: string | null;
    not_found_large_text: string | null;
    not_found_button_text: string | null;
    not_found_button_link: PrismicLinkFieldType;
}>;
