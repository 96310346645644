import { useEffect, useState } from 'react';
import { AbstractSegmentServiceV2, ExperimentType } from '@landr/core';

export type UseConvertExperimentProps = {
    analytics: AbstractSegmentServiceV2;
    experimentId: string;
};

// Copy-paste from `@landr/mfe` because the lib is NOT usable on GuestSite....
// See https://github.com/LandrAudio/LANDR.Web.Libs/pull/834/files#diff-542e0bdb7db10e68423c29a6e7f6b15b879cd3a20f20e3e33a7f4a416090b09bR1
// for the original code that should be kept in sync :(
export const useConvertExperiment = ({ analytics, experimentId }: UseConvertExperimentProps) => {
    const [convertExperiment, setConvertExperiment] = useState<ExperimentType | null>(null);

    useEffect(() => {
        const registrationId = analytics.convertExperiment.registerToExperimentUpdate(experimentId, (experiment) => {
            setConvertExperiment(experiment);
        });
        return () => analytics.convertExperiment.unregisterToExperiment(registrationId);
    }, [analytics, experimentId]);

    return convertExperiment;
};
