import {
    IStructuredEvent,
    LogglyService,
    LogService,
    LogLevel,
    IUnstructuredEvent,
    ILogglyConfig,
    ModuleConfig,
} from '@landr/core';

export class Log extends LogService {
    loggly: LogglyService;

    constructor() {
        super(
            /* eslint-disable no-console */
            {
                log: console.log,
                debug: console.log, // Console.debug not standard
                info: console.log, // Console.info not standard
                warn: console.warn,
                error: console.error,
            },
            /* eslint-enable no-console */
            {
                enabled: process.env.GATSBY_LOGGLY_ENABLED === 'true',
                console: {
                    enabled: process.env.GATSBY_LOGGLY_CONSOLE_ENABLED === 'true',
                    minLevel: process.env.GATSBY_LOGGLY_CONSOLE_MINLEVEL as LogLevel,
                },
                separator: process.env.GATSBY_LOGGLY_SEPARATOR,
            },
        );
        if (typeof document !== 'undefined') {
            // Make sure this is only called at runtime
            this.loggly = this.createLogglyService();
        }
        this.callback = this.logCallback.bind(this);
    }

    protected logCallback(event: IStructuredEvent | IUnstructuredEvent): void {
        const extraParams = {
            url: (window && window.top && window.top.location.href) as string,
        };

        this.loggly.send(event, extraParams);
    }

    private createLogglyService(): LogglyService {
        const config: ILogglyConfig = {
            apiKey: process.env.GATSBY_LOGGLY_API_KEY as string,
            enabled: process.env.GATSBY_LOGGLY_ENABLED === 'true',
            minLevel: process.env.GATSBY_LOGGLY_MINLEVEL as LogLevel,
            tags: process.env.GATSBY_LOGGLY_TAGS as string,
            maxBundleSize: Number.parseInt(process.env.GATSBY_LOGGLY_MAX_BUNDLE_SIZE as string, 10) || 100,
            forcedModule: {} as ModuleConfig,
        };
        // Get the application version from the HTML's meta tag
        // The exclamation mark is a TypeScript way of saying that the value is neither
        // null or undefined: https://stackoverflow.com/a/43960039
        const appVersion =
            (document.querySelector('meta[name=version]') &&
                document.querySelector('meta[name=version]')!.getAttribute('content')) ||
            'Unknown';
        const properties = {
            serviceKey: process.env.GATSBY_LOGGLY_SERVICE_KEY as string,
            deploymentKind: process.env.GATSBY_LOGGLY_DEPLOYMENT_KIND as string,
            version: appVersion,
            application: process.env.GATSBY_LOGGLY_APPLICATION_NAME as string,
            consecutiveOccurrences:
                Number.parseInt(process.env.GATSBY_LOGGLY_CONSECUTIVE_OCCURRENCES as string, 10) || 0,
        };
        return new LogglyService(config, properties, window);
    }
}

export const log = new Log();
