import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
    Box,
    Progress,
    Flex,
    useHover,
    VolumeMuteFilled,
    VolumeFilled,
    useBreakpoint,
    useDebounce,
} from '@landr/maestro';
import { PrismicFluidImage } from 'components/Prismic';
import { VideoReactPlayer } from 'components/UI/VideoReactPlayer/VideoReactPlayer';
import './TextGrid.scss';
import { PrismicLink } from 'components/PrismicLink';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { TextGridItemSliceEnum } from '.';

const WrapperVideoCard = styled(Box)<{ isHovered?: boolean }>`
    position: relative;
    ${({ isHovered }) =>
        isHovered &&
        css`
            z-index: 1000;
        `}
`;

const WrapperVideo = styled(Box)<{ isHovered?: boolean; isSmallDesktop: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    ${({ isHovered, isSmallDesktop }) =>
        isHovered &&
        !isSmallDesktop &&
        css`
            z-index: 2;
            opacity: 1;
            transform: scale(1.2);
        `}
    ${({ isHovered, isSmallDesktop }) =>
        isHovered &&
        isSmallDesktop &&
        css`
            z-index: 2;
            height: 100%;
            opacity: 1;
            top: 0%;
        `}
`;

const WrapperImage = styled(Box)<{ isHovered?: boolean; isSmallDesktop: boolean }>`
    transition: all 0.2s ease-in-out;
    ${({ isHovered, isSmallDesktop }) =>
        isHovered &&
        !isSmallDesktop &&
        css`
            transform: scale(1.1);
        `}
    ${({ isHovered, isSmallDesktop }) =>
        isHovered &&
        isSmallDesktop &&
        css`
            height: 100%;
        `}
`;

const WrapperAudioControl = styled(Flex)`
    cursor: pointer;
    position: absolute;
    top: 6%;
    left: 2%;
    width: 40px;
    height: 40px;
    z-index: 1200;
`;

export type VideoCardProps = {
    videoUrl: string;
    isHovered: boolean;
    videoCanStartBuffering: boolean | undefined;
    isHoverVideoMuted: boolean;
    setShowVolumeControl: (e: boolean) => void;
};

const VideoCard: React.FC<VideoCardProps> = ({
    videoUrl,
    isHovered,
    videoCanStartBuffering,
    isHoverVideoMuted,
    setShowVolumeControl,
}) => {
    const [isPlayerReady, setIsPlayerReady] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    // Do not start buffering video until the video card is in the viewport or it's been hovered.
    const [startBufferingVideo, setStartBufferingVideo] = useState(false);

    useEffect(() => {
        isHovered ? setIsPlaying(true) : setIsPlaying(false);
        (isHovered || videoCanStartBuffering) && setStartBufferingVideo(true);
    }, [isHovered, videoCanStartBuffering]);

    const handleOnReady = useCallback(() => {
        setShowVolumeControl(true);
    }, [setShowVolumeControl]);

    const handleOnStart = useCallback(() => {
        setIsPlayerReady(true);
    }, [setIsPlayerReady]);

    return (
        <Box position="relative" top="5%" width="100%" height="84%" left="0">
            {startBufferingVideo && (
                <VideoReactPlayer
                    videoUrl={`https://home.wistia.com/medias/${videoUrl}`}
                    hasControls={false}
                    isLooping={true}
                    isMuted={isHoverVideoMuted}
                    isAutoPlaying={isPlaying}
                    handleOnReady={handleOnReady}
                    handleOnStart={handleOnStart}
                />
            )}
            {!isPlayerReady && (
                <Flex
                    position="absolute"
                    top="0%"
                    width="100%"
                    height="100%"
                    left="0"
                    backgroundColor="#000"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Progress />
                </Flex>
            )}
            <Flex
                position="absolute"
                top="0%"
                width="100%"
                height="100%"
                left="0"
                backgroundColor="rgba(0,0,0,0)"
                zIndex={500}
                onClick={() => {
                    isPlaying ? setIsPlaying(false) : setIsPlaying(true);
                }}
            />
        </Box>
    );
};

export type TextGridItemVideoProps = {
    item: TextGridItemSliceEnum;
    videoUrl: string;
    isHoverVideoMuted: boolean;
    setVideoAudio: () => void;
};

export const TextGridItemVideo: React.FC<TextGridItemVideoProps> = ({
    item,
    videoUrl,
    isHoverVideoMuted,
    setVideoAudio,
}) => {
    const [ref, isHovered] = useHover(false);
    const [showVolumeControl, setShowVolumeControl] = useState(false);
    const isSmallDesktop = useBreakpoint('lg');
    const entry = useIntersectionObserver(ref, {});
    const isCardInViewport = entry && entry.isIntersecting;
    const [isCardReadyToBuffer, setIsCardReadyToBuffer] = useState(false);

    useEffect(() => {
        isCardInViewport && setIsCardReadyToBuffer(true);
    }, [isCardInViewport]);

    const videoCanStartBuffering = useDebounce(isCardReadyToBuffer, 500);

    return (
        <>
            <WrapperVideoCard ref={ref} isHovered={isHovered}>
                <WrapperVideo isHovered={isHovered} isSmallDesktop={isSmallDesktop} data-testid={`video-card`}>
                    {item.text_grid_image_cta_url ? (
                        <PrismicLink link={item.text_grid_image_cta_url} className="TextGrid-item-image-cta">
                            <VideoCard
                                videoUrl={videoUrl}
                                isHovered={isHovered}
                                videoCanStartBuffering={videoCanStartBuffering}
                                isHoverVideoMuted={isHoverVideoMuted}
                                setShowVolumeControl={setShowVolumeControl}
                            />
                        </PrismicLink>
                    ) : (
                        <VideoCard
                            videoUrl={videoUrl}
                            isHovered={isHovered}
                            videoCanStartBuffering={videoCanStartBuffering}
                            isHoverVideoMuted={isHoverVideoMuted}
                            setShowVolumeControl={setShowVolumeControl}
                        />
                    )}
                    {showVolumeControl && (
                        <WrapperAudioControl onClick={setVideoAudio}>
                            {isHoverVideoMuted ? <VolumeMuteFilled size="lg" /> : <VolumeFilled size="lg" />}
                        </WrapperAudioControl>
                    )}
                </WrapperVideo>
                <WrapperImage
                    isHovered={isHovered}
                    isSmallDesktop={isSmallDesktop}
                    position={'relative'}
                    top={0}
                    left={0}
                    zIndex={isHovered ? 1 : 2}
                    opacity={isHovered ? 0 : 1}
                >
                    <PrismicFluidImage
                        className="TextGrid-item-fullimage"
                        image={item.text_grid_full_imageSharp}
                        fallback={item.text_grid_full_image}
                    />
                </WrapperImage>
            </WrapperVideoCard>
        </>
    );
};
