import { Box, useBreakpoint } from '@landr/maestro';
import React from 'react';
import { MainFooterData } from '../types';
import { MainFooterItems } from './components/MainFooterItems';
import { NewsletterForm } from './components/NewsletterForm';

export interface MainFooterColumnsProps {
    data: MainFooterData;
}

const tabletLayout = {};
const desktopLayout = {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 3fr 4fr',
};

export const MainFooterColumns: React.FC<MainFooterColumnsProps> = ({ data }) => {
    const isTablet = useBreakpoint('lg');
    return (
        <Box sx={isTablet ? tabletLayout : desktopLayout}>
            <MainFooterItems title={data.products_title} items={data.products_items} />

            <MainFooterItems title={data.landr_title} items={data.landr_items} />

            <MainFooterItems title={data.resources_title} items={data.resources_items} />

            <Box mt={isTablet ? 'lg' : 0}>
                <MainFooterItems title={data.newsletter_title}>
                    <NewsletterForm
                        description={data.newsletter_description}
                        placeholder={data.newsletter_email_placeholder}
                        cta={data.newsletter_cta}
                    />
                </MainFooterItems>
                <Box mt={isTablet ? 'lg' : 'xxl'}>
                    <MainFooterItems
                        title={data.redeem_title}
                        items={[{ label: data.redeem_cta_title, link: data.redeem_cta_link }]}
                    ></MainFooterItems>
                </Box>
            </Box>
        </Box>
    );
};
