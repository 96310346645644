import { PricingSliceFragment } from 'components/Slices/Pricing/Pricing.gql';
import { CartSliceFragment } from 'components/Slices/Cart/Cart.gql';
import { BlogSliceFragment } from './Blog/Blog.gql';
import { CallToActionSliceFragment } from './CallToAction/CallToAction.gql';
import { FaqSliceFragment } from './Faq/Faq.gql';
import { FeatureSliceFragment } from './Feature/Feature.gql';
import { FeatureVideoSliceFragment } from './FeatureVideo/FeatureVideo.gql';
import { FeaturedArtistsSliceFragment } from './FeaturedArtists/FeaturedArtists.gql';
import { HeroSliceFragment } from './Hero/Hero.gql';
import { HeroVideoSliceFragment } from './HeroVideo/HeroVideo.gql';
import { LogoListSliceFragment } from './LogoList/LogoList.gql';
import { MasteringDemosSliceFragment } from './MasteringDemos/MasteringDemos.gql';
import { PressListSliceFragment } from './PressList/PressList.gql';
import { QuotesSliceFragment } from './Quotes/Quotes.gql';
import { LinkListSliceFragment } from './LinkList/LinkList.gql';
import { QuotesVideoSliceFragment } from './QuotesVideo/QuotesVideo.gql';
import { TextBlockSliceFragment } from './TextBlock/TextBlock.gql';
import { TextGridSliceFragment } from './TextGrid/TextGrid.gql';
import { VideoPlayerSliceFragment } from './VideoPlayer/VideoPlayer.gql';
import { VisualSliceFragment } from './Visual/Visual.gql';
import { SignupSliceFragment } from './Signup/Signup.gql';
import { RoyaltyCalculatorSliceFragment } from './RoyaltyCalculator/RoyaltyCalculator.gql';

export const SliceFragments = [
    BlogSliceFragment,
    CallToActionSliceFragment,
    FaqSliceFragment,
    FeaturedArtistsSliceFragment,
    FeatureSliceFragment,
    FeatureVideoSliceFragment,
    CartSliceFragment,
    HeroSliceFragment,
    HeroVideoSliceFragment,
    LinkListSliceFragment,
    LogoListSliceFragment,
    MasteringDemosSliceFragment,
    PressListSliceFragment,
    PricingSliceFragment,
    QuotesSliceFragment,
    QuotesVideoSliceFragment,
    SignupSliceFragment,
    TextBlockSliceFragment,
    TextGridSliceFragment,
    VideoPlayerSliceFragment,
    VisualSliceFragment,
    RoyaltyCalculatorSliceFragment,
];
