import React from 'react';
import { BaseHeroSlice } from 'components/BaseHeroSlice';
import { PrismicFluidImage } from 'components/Prismic';
import { SupportedPrismicSlicesEnum } from '../enums';
import {
    BooleanOptionType,
    CtaKind,
    PrismicFluidImageField,
    PrismicImageField,
    PrismicKeyTextFieldType,
    PrismicLinkFieldType,
    PrismicRichTextFieldType,
    PrismicTitleFieldType,
    SliceContext,
} from '../types';

export type HeroProps = {
    slice: {
        type: SupportedPrismicSlicesEnum.hero;
        primary: {
            hero_title: PrismicTitleFieldType;
            hero_title_gtc: string;
            hero_title_gbc: string;
            hero_description: PrismicRichTextFieldType;
            hero_alignment: 'Left' | 'Center';
            hero_cta_kind: CtaKind;
            hero_cta_label: PrismicKeyTextFieldType;
            hero_cta_link: PrismicLinkFieldType;
            hero_cta_kind_secondary: CtaKind;
            hero_cta_label_secondary: PrismicKeyTextFieldType;
            hero_cta_link_secondary: PrismicLinkFieldType;
            hero_cta_label_authenticated: PrismicKeyTextFieldType;
            hero_cta_link_authenticated: PrismicLinkFieldType;
            hero_show_arrow: BooleanOptionType;
            hero_image: PrismicImageField;
            hero_imageSharp: PrismicFluidImageField;
        };
    };
    sliceContext: SliceContext;
};

export const Hero: React.FC<HeroProps> = ({ slice, sliceContext }) => {
    const {
        primary: {
            hero_title: title,
            hero_title_gtc: titleGradientColorTop,
            hero_title_gbc: titleGradientColorBottom,
            hero_description: description,
            hero_alignment: alignment,
            hero_cta_kind: ctaKind,
            hero_cta_label: ctaLabel,
            hero_cta_link: ctaLink,
            hero_cta_kind_secondary: secondaryCtaKind,
            hero_cta_label_secondary: secondaryCtaLabel,
            hero_cta_link_secondary: secondaryCtaLink,
            hero_cta_label_authenticated: authenticatedCtaLabel,
            hero_cta_link_authenticated: authenticatedCtaLink,
            hero_show_arrow: showArrow,
            hero_image: heroImage,
            hero_imageSharp: heroImageSharp,
        },
    } = slice;

    return (
        <BaseHeroSlice
            title={title}
            titleGradientColorTop={titleGradientColorTop}
            titleGradientColorBottom={titleGradientColorBottom}
            description={description}
            alignment={alignment}
            ctaKind={ctaKind}
            ctaLabel={ctaLabel}
            ctaLink={ctaLink}
            secondaryCtaKind={secondaryCtaKind}
            secondaryCtaLabel={secondaryCtaLabel}
            secondaryCtaLink={secondaryCtaLink}
            authenticatedCtaLabel={authenticatedCtaLabel}
            authenticatedCtaLink={authenticatedCtaLink}
            showArrow={showArrow}
            sliceContext={sliceContext}
            isHeroVideo={false}
        >
            <PrismicFluidImage image={heroImageSharp} fallback={heroImage} fullscreen={true} isLazyLoaded={false} />
        </BaseHeroSlice>
    );
};
