import React from 'react';
import ReactDomServer from 'react-dom/server';
import { RichText, Link } from 'prismic-dom';
import parse from 'html-react-parser';
import { prismicLinkResolver, linkResolver } from 'helpers';
import { CheckIcon, ExperimentalProvider, ResponsiveProvider } from '@landr/maestro';
import { MaestroThemeProvider, defaultTheme } from '@landr/maestro-legacy';
import './PrismicHTML.scss';

const { Elements } = RichText;

interface ElementProps {
    text: string;
    label: string;
    type: typeof Elements;
    data: {
        target: string;
        label: string;
    };
}

/**
 * Used to serialize Prismic Rich Text fields. Although based on the example at https://prismic.io/docs/javascript/beyond-the-api/html-serializer,
 * ours allows us to add passed class names to the topmost wrapper element.
 */
const getHtmlSerializer = (shouldShowListIcon: boolean, styleClassName?: string) => (
    type: string,
    element: ElementProps,
    content: string | null,
    children: Array<string>,
): string => {
    const generate = (tag: string, defaultClass = '') => {
        const isWrapper = !content;

        // The topmost wrapper contains only children (no text content). We apply the wrapper classes to it.
        const classes: string = isWrapper ? [defaultClass].join(' ').trim() : defaultClass;
        const child = children.join('') || content;

        return classes ? `<${tag} class="${classes}">${child}</${tag}>` : `<${tag}>${child}</${tag}>`;
    };

    switch (type) {
        case Elements.heading1:
            return generate('h1', styleClassName || 'Heading Heading--mg');
        case Elements.heading2:
            return generate('h2', styleClassName || 'Heading Heading--jb');
        case Elements.heading3:
            return generate('h3', styleClassName || 'Heading Heading--xx');
        case Elements.heading4: {
            if (shouldShowListIcon) {
                return generate(
                    'h4',
                    styleClassName ? `${styleClassName} IconHeading` : 'Heading Heading--xl IconHeading',
                );
            }
            return generate('h4', styleClassName || 'Heading Heading--xl');
        }
        case Elements.heading5:
            return generate('h5', styleClassName || 'Heading Heading--lg');
        case Elements.heading6:
            return generate('h6', styleClassName || 'Heading Heading--md');
        case Elements.preformatted:
            return generate('pre');
        case Elements.strong:
            return generate('strong');
        case Elements.em:
            return generate('em');
        case Elements.listItem: {
            if (shouldShowListIcon) {
                const child = children.join('') || content;

                const icon = ReactDomServer.renderToStaticMarkup(
                    <MaestroThemeProvider theme={defaultTheme}>
                        <ResponsiveProvider theme={defaultTheme}>
                            <ExperimentalProvider>
                                <CheckIcon size="md" color="primary.500" className="PrismicHTML Icon" />
                            </ExperimentalProvider>
                        </ResponsiveProvider>
                    </MaestroThemeProvider>,
                );

                // language=HTML
                return `<li>${icon}${child}</li>`;
            } else {
                return generate('li');
            }
        }
        case Elements.oListItem:
            return generate('li');
        case Elements.list:
            return generate('ul');
        case Elements.oList:
            return generate('ol');
        case Elements.paragraph:
            return generate('p', element.label || '');
        case Elements.label:
            // Not <label> tags, but rather Prismic's labeled spans.
            return generate('span', (element.data && element.data.label) || '');
        case Elements.hyperlink: {
            const target = element.data.target ? `target="${element.data.target}" rel="noopener"` : '';
            const linkUrl = Link.url(element.data, prismicLinkResolver);
            return `<a ${target} href="${linkUrl}">${children.join('')}</a>`;
        }
        case Elements.embed:
            // We don't support arbitrary embeds in rich text. Use video slices or add a new slice.
            // Must return a truthy value or Prismic will use default embed serializer.
            return ' ';
        case Elements.image:
            // We don't support arbitrary images in rich text. Use an image slice.
            // Must return a truthy value or Prismic will use default image serializer.
            return ' ';
        case Elements.span:
            return content ? content.replace(/\n/g, '<br />') : '';
        default:
            return ' ';
    }
};

interface PrismicHTMLProps {
    className?: string;
    shouldShowListIcon?: boolean;
    content: any;
    styleClassName?: string;
}

export const PrismicHTML: React.FC<PrismicHTMLProps> = ({
    content,
    className,
    shouldShowListIcon = false,
    styleClassName,
}) => {
    if (content) {
        for (let i = 0; i < content.length; i++) {
            if (content[i].text.length === 0) {
                content[i].text = ' ';
            }
        }
    } else {
        return null;
    }

    const htmlSerializer = getHtmlSerializer(shouldShowListIcon, styleClassName);
    const html = RichText.asHtml(content, linkResolver, htmlSerializer);

    return <div className={className}>{parse(html)}</div>;
};

interface PrismicGrandientTitleHTMLProps {
    className?: string;
    shouldShowListIcon?: boolean;
    content: any;
    gradientRange: any;
}

export const PrismicGradientTitleHTML: React.FC<PrismicGrandientTitleHTMLProps> = ({
    content,
    gradientRange,
    className,
    shouldShowListIcon = false,
}) => {
    if (content) {
        for (let i = 0; i < content.length; i++) {
            if (content[i].text.length === 0) {
                content[i].text = ' ';
            }
        }
    } else {
        return null;
    }

    const htmlSerializer = getHtmlSerializer(shouldShowListIcon);
    const html = RichText.asHtml(content, linkResolver, htmlSerializer);

    return (
        <div
            style={{
                background: `-webkit-linear-gradient(${gradientRange[0]}, ${gradientRange[1]})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: ' transparent',
            }}
            className={className}
        >
            {parse(html)}
        </div>
    );
};
