import React from 'react';
import { PrismicLink } from 'components/PrismicLink';
import styled, { css } from 'styled-components';
import { useBreakpoint } from '@landr/maestro';
import { spacing } from '@landr/maestro-legacy';
import { NavigationSingleItemProps } from './types';

const StyledPrismicLink = styled(PrismicLink)<{ isTablet: boolean }>`
    ${({ isTablet }) => {
        if (isTablet) {
            return css`
                font-size: ${spacing('lg')} !important;
            `;
        }
    }}
`;

export const NavigationSingleItem: React.FC<NavigationSingleItemProps> = ({ navigationSingleItem }) => {
    const { label, link } = navigationSingleItem.primary;
    const isTablet = useBreakpoint('lg');

    return (
        <StyledPrismicLink isTablet={isTablet} link={link} className="MainNavigation-itemButton">
            {label}
        </StyledPrismicLink>
    );
};
