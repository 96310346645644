import { graphql } from 'gatsby';

export const MasteringDemosSliceFragment = graphql`
    fragment PrismicMasteringDemosFragment on PRISMIC_PageBodyMastering_demos {
        type
        primary {
            mastering_demos_original_button_copy
            mastering_demos_mastered_button_copy
            mastering_demos_title
        }
        fields {
            mastering_demos_original {
                ...LinkFragment
            }
            mastering_demos_mastered {
                ...LinkFragment
            }
            mastering_demos_image
            mastering_demos_imageSharp {
                ...FluidImageFragment
            }
            mastering_demos_style
            mastering_demos_track_title
        }
    }
`;
